import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getSelectedVariantName,
  isInsuredOffcialsOptionSelected,
  isInsuredRelativesOptionSelected,
} from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { CheckoutComponentWithNavigation } from './checkout.component';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';

const mapStateToProps = (state: RootState) => ({
  selectedVariantName: getSelectedVariantName(state),
  isInsuredOfficialsOptionSelected: isInsuredOffcialsOptionSelected(state),
  isInsuredRelativesOptionSelected: isInsuredRelativesOptionSelected(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.CHECKOUT)),
});

export const Checkout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutComponentWithNavigation);
