import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RootState } from '../../store/store.interface';
import { OfferActionTypes } from './offer.actions';
import { getBusinessId } from './offer.selectors';
import { backendUrl } from '../../config';
import { noCacheHeaders } from '../app/app.epic';
import { Logger } from '../../commons/logger';

export const onGetOfferEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(OfferActionTypes.GET_OFFER.start),
  mergeMap((action) => ajax.getJSON(`${backendUrl}/api/phv/offer/${action.payload.businessId}`, { ...noCacheHeaders })
    .pipe(
      map((response) => ({
        type: OfferActionTypes.GET_OFFER.success,
        context: { requestPayload: action.payload },
        payload: {
          response,
        },
      })),
      catchError((error) => of({
        type: OfferActionTypes.GET_OFFER.error,
        context: { requestPayload: action.payload },
        payload: {
          error,
        },
      })),
    )),
);

export const onPutOfferEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(OfferActionTypes.PUT_OFFER.start),
  mergeMap(({ payload }) => ajax.put(
    `${backendUrl}/api/phv/offer/${getBusinessId(state$.value)}`,
    payload,
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: OfferActionTypes.PUT_OFFER.success,
        context: { requestPayload: payload },
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: OfferActionTypes.PUT_OFFER.error,
          context: { requestPayload: payload },
          payload: {
            error,
          },
        });
      }),
    )),
);

export const onValidateOfferEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(OfferActionTypes.VALIDATE_OFFER.start),
  mergeMap(() => ajax.get(
    `${backendUrl}/api/phv/offer/${getBusinessId(state$.value)}/validate`,
    { 'Content-Type': 'application/json', ...noCacheHeaders },
  )
    .pipe(
      map(({ response }) => ({
        type: OfferActionTypes.VALIDATE_OFFER.success,
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: OfferActionTypes.VALIDATE_OFFER.error,
          payload: {
            error,
          },
        });
      }),
    )),
);

export const onSubmitOfferEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(OfferActionTypes.SUBMIT_OFFER.start),
  mergeMap(() => ajax.post(
    `${backendUrl}/api/phv/offer/${getBusinessId(state$.value)}/submit`,
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: OfferActionTypes.SUBMIT_OFFER.success,
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: OfferActionTypes.SUBMIT_OFFER.error,
          payload: {
            error,
          },
        });
      }),
    )),
);
