import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions } from '../../entities/offer/offer.actions';
import { personsActions } from '../../entities/persons/persons.actions';
import { getBirthdayDate, getInsuranceStartDate } from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { BirthdateComponentWithNavigation } from './birthdate.component';
import { getInsuranceOwner } from '../../entities/persons/persons.selectors';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';

const mapStateToProps = (state: RootState) => ({
  birthdayDate: getBirthdayDate(state),
  insuranceOwner: getInsuranceOwner(state),
  insuranceBegin: getInsuranceStartDate(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setBirthdayDate: (birthdayDate: string) => dispatch(offerActions.updateField({
    fieldName: 'birthdayDate',
    value: birthdayDate,
  })),

  sendBirthdayDate: (personId: string, birthdayDate: string) => {
    dispatch(offerActions.sendField({
      fieldName: 'birthdayDate',
      value: birthdayDate,
    }));
    dispatch(personsActions.sendPersonBirthdayDate(personId, birthdayDate));
  },

  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.BIRTHDATE)),
});

export const Birthdate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BirthdateComponentWithNavigation);
