import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getDataProtectionUrl } from '../../entities/aem/aem-properties.selectors';
import { agencyActions } from '../../entities/agency/agency.actions';
import {
  getAgencyId,
  getBusinessId,
  getInsuranceStartDate,
} from '../../entities/offer/offer.selectors';
import { personsActions } from '../../entities/persons/persons.actions';
import { Person } from '../../entities/persons/persons.interface';
import { getInsuranceOwner } from '../../entities/persons/persons.selectors';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { RootState } from '../../store/store.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { PersonalDataComponentWithNavigation } from './personal-data.component';

const mapStateToProps = (state: RootState) => ({
  personId: getInsuranceOwner(state)?.personId,
  businessId: getBusinessId(state),
  insuranceStartDate: getInsuranceStartDate(state),
  agencyId: getAgencyId(state),
  dataProtectionUrl: getDataProtectionUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePerson: (person: Person) => dispatch(personsActions.updatePerson(person)),
  createAgency: () => dispatch(agencyActions.createAgency()),
  personalDataError: (error: Error) => dispatch(personsActions.personalDataError(error)),
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.PERSONAL_DATA)),
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(
    generalClickElementType,
  )),
});

export const PersonalData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalDataComponentWithNavigation);
