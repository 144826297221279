import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions } from '../../entities/offer/offer.actions';
import { OfferStateData, VariantsNames } from '../../entities/offer/offer.interface';
import {
  getDurationOfContract,
  getOwnContribution,
  getPaymentMethod,
  getSelectedVariantName,
} from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { AgreementOptionsComponent } from './agreement-options.component';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { trackingActions } from '../../entities/tracking/tracking.actions';

const mapStateToProps = (state: RootState) => ({
  selectedVariantName: getSelectedVariantName(state) as VariantsNames,
  ownContribution: getOwnContribution(state),
  durationOfContract: getDurationOfContract(state),
  paymentMethod: getPaymentMethod(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeField: ({ target }: ChangeEvent) => (
    fieldName: keyof Pick<OfferStateData, 'ownContribution' | 'durationOfContract' | 'paymentMethod'>,
    variantName?: VariantsNames,
  ) => {
    const payload = {
      fieldName,
      value: (target as HTMLSelectElement).value,
      variantName,
    };

    dispatch(offerActions.updateField(payload));
    dispatch(offerActions.sendField(payload));
  },
  onChangeVariantField: ({ target }: ChangeEvent) => (
    fieldName: keyof Pick<OfferStateData, 'ownContribution' | 'durationOfContract' | 'paymentMethod'>,
    variantName: VariantsNames,
  ) => {
    const payload = {
      fieldName,
      value: (target as HTMLSelectElement).value,
      variantName,
    };

    dispatch(offerActions.updateVariantField(payload));
    dispatch(offerActions.sendVariantField(payload));
  },
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const AgreementOptions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgreementOptionsComponent);
