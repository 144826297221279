import Price from '@eg/elements/Price';
import moment from 'moment';
import React, { useEffect } from 'react';
import { MAX_PROFIT_AGE, PhvDateFormat } from '../../config';
import {
  IsoDate, OfferStateData, PhvPaymentMethod, VariantsNames,
} from '../../entities/offer/offer.interface';
import './price-header.component.scss';
import { Logger } from '../../commons/logger';

interface PriceHeaderProps {
  currentPriceBrutto: number | null;
  insuranceStartDate?: IsoDate;
  insuredPersonBirthdate?: string;
  selectedVariantName?: VariantsNames;
  paymentMethod: OfferStateData['paymentMethod']['value'];

  showPrice: boolean,

  onRender?: () => void;
}

const PriceHeaderComponent: React.FC<PriceHeaderProps> = ({
  currentPriceBrutto,
  insuranceStartDate,
  insuredPersonBirthdate,
  selectedVariantName,
  paymentMethod,
  showPrice,
  onRender = () => {},
}: PriceHeaderProps) => {
  useEffect(onRender);

  const isStartBonusIncluded = () => {
    try {
      const insuranceBeginDate = moment(insuranceStartDate, PhvDateFormat.ISO_DATE);
      const inputValueDate = moment(insuredPersonBirthdate, PhvDateFormat.ISO_DATE);

      return insuranceBeginDate.diff(inputValueDate, 'years') <= MAX_PROFIT_AGE;
    } catch (error) {
      Logger.debug(error);
    }
    return false;
  };

  const insuranceName = 'Private Haftpflichtversicherung';

  if (!showPrice || !currentPriceBrutto || !selectedVariantName) {
    return null;
  }

  let paymentInterval: string;

  switch (paymentMethod) {
    case PhvPaymentMethod.VIERTELJAEHRLICH:
      paymentInterval = 'viertelj.';
      break;

    case PhvPaymentMethod.HALBJAEHRLICH:
      paymentInterval = 'halbj.';
      break;

    case PhvPaymentMethod.JAEHRLICH:
      paymentInterval = 'jährl.';
      break;

    case PhvPaymentMethod.MONATLICH:
    default:
      paymentInterval = 'mtl.';
  }

  return (
    <div className="price-header">
      <div className="price-header__container">
        <div className="price-header__text">
          <div className="price-header__label">
            {insuranceName}
          </div>
          <div className="price-header__description">
            Tarif
            {' '}
            {selectedVariantName}
            {' '}
            {isStartBonusIncluded() && ('inkl. Startbonus')}
          </div>
        </div>

        <div className="price-header__price-field">
          <span className="price-header__price">
            <Price
              value={currentPriceBrutto}
              unstyled
            />
          </span>
          {' '}
          <span className="price-header__payment-interval">{paymentInterval}</span>
        </div>
      </div>
    </div>

  );
};

PriceHeaderComponent.defaultProps = {
  insuranceStartDate: undefined,
  insuredPersonBirthdate: undefined,
  selectedVariantName: undefined,
  onRender: () => {},
};

export {
  PriceHeaderComponent,
};
