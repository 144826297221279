import { AnyAction } from 'redux';
import { AppActionTypes } from './app.actions';
import { AppState } from './app.interface';

const initialState: AppState = {
  error: null,
  initialized: false,
  oeNr: null,
  channelType: null,
  previousNavigationAction: null,
  inAem: false,
  newOffer: false,
  actionNr: null,
};

export const getInitialState: () => AppState = () => initialState;

/* eslint-disable @typescript-eslint/default-param-last*/
export const appReducer = (
  state: AppState = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case AppActionTypes.INIT_WITH_BUSINESS_ID.success:
    case AppActionTypes.INIT.success:
      return {
        ...state,
        error: null,
        initialized: true,
      };
    case AppActionTypes.INIT_WITH_BUSINESS_ID.start:
    case AppActionTypes.INIT.start:
      return {
        ...state,
        error: null,
        initialized: false,
      };
    case AppActionTypes.INIT_WITH_BUSINESS_ID.error:
    case AppActionTypes.INIT.error:
    case AppActionTypes.APP_ERROR:
      return {
        ...state,
        error: action.payload.message,
        initialized: false,
      };
    case AppActionTypes.NAVIGATION_ACTION:
      return {
        ...state,
        previousNavigationAction: action.payload,
      };
    case AppActionTypes.NEW_OFFER_CREATED:
      return {
        ...state,
        newOffer: true,
      };

    default:
      return state;
  }
};
