import DateInput, { DateInputValue } from '@eg/elements/DateInput';
import Radio from '@eg/elements/Radio';
import { DateErrors } from '@eg/elements/utils/validation/date';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import './insurance-start-date.component.scss';
import {
  InsuranceStartDateOption,
  InsuranceStartDateOptionLabel,
  InsuranceStartDateProps,
} from './insurance-start-date.interface';

const zeroFill = (i?: string): string => (i === undefined ? '' : (i.length === 2 ? '' : '0') + i);

const parseDateToDateInput = (day?: string, month?: string, year?: string): DateInputValue => ({
  day,
  month,
  year,
});

const parseIsoToDateInput = (isoDateStr: string): DateInputValue => {
  const [year, month, day] = isoDateStr.split('-');
  return parseDateToDateInput(day, month, year);
};

const parseDateToIso = (date: DateInputValue): string => `${date.year}-${zeroFill(date.month)}-${zeroFill(date.day)}`;

export const InsuranceStartDateComponent = ({
  insuranceStartDate,
  setInsuranceStartDate,
  sendInsuranceStartDate,
  setNextStepCallback,
  setCanGoToNextStep,
  setPreviousStep,
  goToNextStep,
  insuranceStartOptions,
  isLoading,
  trackPageLoaded,
}: InsuranceStartDateProps) => {
  const [isCustomDateVisible, setShowCustomDateVisible] = useState(false);
  const [customDate, setCustomDate] = useState(parseIsoToDateInput(insuranceStartDate.value as string));
  const [customDateErrors, setCustomDateErrors] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isTouched, setIsTouched] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);

  const minDate = moment(insuranceStartDate.min).toDate();
  const maxDate = moment(insuranceStartDate.max).toDate();

  const DATE_OUT_OF_AVAILABLE_RANGE_ERROR = 'Der Termin kann nicht in der Vergangenheit liegen. Und höchstens 364 Tage in der Zukunft.';
  const DATE_INVALID_FORMAT_MSG = 'Bitte geben Sie das vollständige Datum ein (Format TT MM JJJJ).';

  const { saved } = insuranceStartDate;

  //validation triggered ONLY on init of a component
  function validateInsuranceStartDate(date: DateInputValue): void {
    const rangeStart = moment(insuranceStartDate.min);
    const rangeEnd = moment(insuranceStartDate.max);
    const currentValue = moment(parseDateToIso(date));
    if (
      rangeStart.isValid()
      && (currentValue.isBefore(rangeStart) || currentValue.isAfter(rangeEnd))
    ) {
      setCustomDateErrors([
        DATE_OUT_OF_AVAILABLE_RANGE_ERROR,
      ]);
      setShowErrors(true);
      setIsDateValid(false);
    } else {
      setShowErrors(false);
      setIsDateValid(true);
    }
  }

  function onChangeDateInput(value: DateInputValue, { valid, ...errors }: DateErrors) {
    setCustomDate(parseDateToDateInput(
      value.day,
      value.month,
      value.year,
    ));
    setIsDateValid(valid);

    if (valid) {
      setCustomDateErrors([]);
      const newDateIso = parseDateToIso(value);
      setInsuranceStartDate(newDateIso);
    } else if (errors.rangeUnderflow || errors.rangeOverflow) {
      setCustomDateErrors([DATE_OUT_OF_AVAILABLE_RANGE_ERROR]);

      //when date is only out of valid range - show validation immediately
      setShowErrors(true);
    } else if (errors.badInput) {
      setCustomDateErrors([errors.badInput]);

      //when date is invalid (as a date  in ex. 31.02.2020) - show validation after first onBlur
      //this prevents from displaying errors while just entering the (valid) value
      if (isTouched) {
        setShowErrors(true);
      }
    } else if (errors.valueMissing) {
      setCustomDateErrors([DATE_INVALID_FORMAT_MSG]);

      //when date is missing - show validation after first onBlur
      if (isTouched) {
        setShowErrors(true);
      }
    }
  }

  function onBlurDateInput() {
    setIsTouched(true);
    //Show errors onBlur if there are any
    if (customDateErrors.length > 0) {
      setShowErrors(true);
    }
  }

  function onRadioButtonDateSelected({ target }: ChangeEvent) {
    setShowCustomDateVisible(false);
    setIsDateValid(true);
    setInsuranceStartDate((target as HTMLInputElement).value);
  }

  //init of a component
  useEffect(() => {
    trackPageLoaded();
    setPreviousStep(RoutePath.MARITAL_STATUS);
    setNextStepCallback(() => {
      setIsSubmitted(true);
    });

    //initial validation only if there is a date already selected
    if (insuranceStartDate.value) {
      validateInsuranceStartDate(parseIsoToDateInput(insuranceStartDate.value));
    }
  }, []);

  //send insurance date when form is submitted
  useEffect(() => {
    if (isSubmitted) {
      sendInsuranceStartDate(insuranceStartDate.value);
    }
  }, [isSubmitted]);

  //redirect after successful form submission
  useEffect(() => {
    if (!isLoading && isSubmitted && saved) {
      goToNextStep(RoutePath.BIRTHDATE);
    }
  }, [isLoading, isSubmitted, saved]);

  // pre-select custom insurance start date when current does not match any of insurance start options
  useEffect(() => {
    const startDateExistInOptions = insuranceStartOptions.some((opt) => opt.value === insuranceStartDate.value);

    if (startDateExistInOptions) {
      setShowCustomDateVisible(false);
      setCustomDateErrors([]);
    } else {
      setShowCustomDateVisible(true);
    }
  }, [insuranceStartDate, insuranceStartOptions]);

  //manage Weiter button en/disability
  useEffect(() => {
    setCanGoToNextStep(isDateValid);
  }, [isDateValid]);

  //manage reseting state of datepicker and validation while toggling radio buttons
  useEffect(() => {
    //radio button date got selected
    if (!isCustomDateVisible) {
      //reset customDate state
      setIsTouched(false);
      setShowErrors(false);
    } else if (insuranceStartDate.value) {
      setShowErrors(true);
    } else {
      setIsDateValid(false);
    }
  }, [isCustomDateVisible]);

  // set focus on first input in DateInput, when customDate is empty
  useEffect(() => {
    if (isCustomDateVisible && !customDate.day) {
      document.querySelector<HTMLInputElement>('#insurance-start-date input')?.focus();
    }
  });

  return (
    <div className="insurance-start-date__container">
      <h3 className="insurance-start-date__page-title">Wann soll die Versicherung beginnen?</h3>

      <div className="insurance-start-date__option-container">
        {insuranceStartOptions.map((option: InsuranceStartDateOption, idx) => (
          <div key={option.value} className="insurance-start-date__option esc_border-radius">
            <Radio
              className="insurance-start-date__option-radio"
              data-testid={`insurance-start-date-option-${idx}`}
              name="insuranceStartDate"
              value={option.value}
              checked={insuranceStartDate.value === option.value}
              onChange={onRadioButtonDateSelected}
              label={(
                <>
                  <div className="insurance-start-date__option-label">{option.label}</div>
                  <div className="insurance-start-date__option-date">{option.longDate}</div>
                </>
              )}
            />
          </div>
        ))}

        <div className="insurance-start-date__option">
          <Radio
            className="insurance-start-date__option-radio"
            data-testid="insurance-start-date-option-other"
            name="insuranceStartDate"
            value={isCustomDateVisible}
            checked={isCustomDateVisible}
            onChange={() => {
              setShowCustomDateVisible(true);
              setInsuranceStartDate('');
              setCustomDate(parseIsoToDateInput(''));
            }}
            label={(
              <>
                <div className="insurance-start-date__option-label">{InsuranceStartDateOptionLabel.OTHER_DATE}</div>
                <div className="insurance-start-date__option-date">An Ihrem Wunschtermin</div>
              </>
            )}
          />
        </div>
      </div>

      {isCustomDateVisible && (
        <>
          <div className="insurance-start-date__input-title">
            <p>Bitte geben Sie Ihren gewünschten Versicherungsbeginn ein.</p>
          </div>
          <div className="insurance-start-date__input-container">
            <div className="insurance-start-date__input-datepicker">
              <DateInput
                id="insurance-start-date"
                data-testid="insurance-start-date-input"
                value={customDate}
                error={showErrors && customDateErrors.length > 0}
                onChange={onChangeDateInput}
                onBlur={onBlurDateInput}
                minDate={minDate}
                maxDate={maxDate}
                autoTab
              />
            </div>

            {showErrors && (
              <div className="insurance-start-date__error">{customDateErrors?.[0]}</div>
            )}

          </div>
        </>
      )}

    </div>
  );
};

export const InsuranceStartDateComponentWithNavigation = withNavigation(InsuranceStartDateComponent);
