export interface AppState {
  error: string | null;
  initialized: boolean;
  oeNr: string | null;
  channelType: string | null;
  previousNavigationAction: PreviousNavigationAction
  inAem: boolean;
  newOffer: boolean;
  actionNr: string | null;
}

export interface AppInitParams {
  oeNr?: string | null;
  channelType?: string | null;
  actionNr?: string | null;
}

export enum PreviousNavigationActionOptions {BACK = 'BACK', NEXT = 'NEXT'}

export type PreviousNavigationAction = keyof typeof PreviousNavigationActionOptions | null;
