import { generateApiActions } from '../../store/api-actions.helper';
import { AemAppConfig } from '../../aem/aem.types';

export const AemPropertiesActionTypes = {
  LOAD_PROPERTIES: generateApiActions('@aemProperties/LOAD_PROPERTIES'),
};

const load = (aemAppConfig: AemAppConfig) => ({
  type: AemPropertiesActionTypes.LOAD_PROPERTIES,
  payload: aemAppConfig,
});

export const aemPropertiesActions = {
  load,
};
