import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getInsurancePartnerPersonIfIsOfficialsOptionSelected,
  getInsuredRelativesPersons,
} from '../../../entities/offer/offer.selectors';
import { personsActions } from '../../../entities/persons/persons.actions';
import { Person } from '../../../entities/persons/persons.interface';
import { RootState } from '../../../store/store.interface';
import { AdditionalPersonsComponent } from './additional-persons.component';

const mapStateToProps = (state: RootState) => ({
  insurancePartner: getInsurancePartnerPersonIfIsOfficialsOptionSelected(state),
  insuredRelatives: getInsuredRelativesPersons(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateInsuredRelative: (
    personId: Person['personId'],
    delta: Partial<Person>,
  ) => dispatch(personsActions.updateInsuredRelative(personId, delta)),

  updateInsuredOfficial: (
    personId: Person['personId'],
    delta: Partial<Person>,
  ) => dispatch(personsActions.updateInsuredOfficial(personId, delta)),

  sendUpdateAdditionalPerson: (
    personId: Person['personId'],
    person: Partial<Person>,
  ) => dispatch(personsActions.sendUpdateAdditionalPerson(personId, person)),
});

export const AdditionalPersons = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalPersonsComponent);
