import FormFooter from '@eg/elements/FormFooter';
import Modal from '@eg/elements/Modal';
import Provider from '@eg/elements/Provider';
import '@eg/elements/styles/breakpoints';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import classNames from 'classnames';
import React from 'react';
import {
  HashRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';
import { AppInit } from '../app-init/app-init.container';
import { BadUrlComponent } from '../bad-url/bad-url.component';
import { Birthdate } from '../birthdate/birthdate.container';
import { Checkout } from '../checkout/checkout.container';
import { Consultation } from '../consultation/consultation.container';
import { Contribution } from '../contribution/contribution.container';
import { Header } from '../header/header.container';
import { InsuranceStartDate } from '../insurance-start-date/insurance-start-date.container';
import { MaklerConsultation } from '../makler-consultation/makler-consultation.container';
import { MaritalStatus } from '../marital-status/marital-status.container';
import { PaymentData } from '../payment-data/payment-data.container';
import { PersonalData } from '../personal-data/personal-data.container';
import { ScrollTopComponent } from '../scroll-top/scroll-top.component';
import { ThankYou } from '../thank-you/thank-you.container';
import './app.component.scss';
import { AppProps, RoutePath } from './app.interface';

function sendHeight() {
  const phvRoot = document.body;
  if (phvRoot) {
    const height = phvRoot.offsetHeight;
    window.parent.postMessage(height, '*');
  }
}

export function AppComponent({
  isAppInitialized, stopAppWithError, inAem, isMaklerFlow,
}: AppProps) {
  return (
    <Provider
      className="app__content-container"
      theme="ergo-one"
    >
      <Router>
        <ScrollTopComponent />
        <Header />
        <AppInit />
        <ReactResizeDetector
          handleWidth={false}
          handleHeight
          onResize={() => {
            sendHeight();
          }}
        />
        <Modal open={!!stopAppWithError}>
          {stopAppWithError}
        </Modal>
        <div className={classNames('app__main', { 'in-aem': inAem })}>
          <section className="esc_container__section">
            {isAppInitialized && !stopAppWithError
            && (
              <Switch>
                <Route exact path={[RoutePath.INIT, RoutePath.ROOT]}>
                  <Redirect to={{ pathname: RoutePath.MARITAL_STATUS }} />
                </Route>
                <Route path={RoutePath.CONTRIBUTION}>
                  <Contribution />
                </Route>
                <Route path={RoutePath.BIRTHDATE}>
                  <Birthdate />
                </Route>
                <Route path={RoutePath.INSURANCE_START}>
                  <InsuranceStartDate />
                </Route>
                <Route path={RoutePath.CHECKOUT}>
                  <Checkout />
                </Route>
                <Route path={RoutePath.MARITAL_STATUS}>
                  <MaritalStatus />
                </Route>
                <Route path={RoutePath.PERSONAL_DATA}>
                  <PersonalData />
                </Route>
                <Route path={RoutePath.PAYMENT_DATA}>
                  <PaymentData />
                </Route>
                <Route path={RoutePath.CONSULTATION}>
                  {isMaklerFlow ? (
                    <MaklerConsultation />
                  ) : (
                    <Consultation />
                  )}
                </Route>
                <Route path={RoutePath.THANK_YOU}>
                  <ThankYou />
                </Route>
                <Route>
                  <BadUrlComponent />
                </Route>
              </Switch>
            )}
          </section>

          <FormFooter />
        </div>

      </Router>

    </Provider>
  );
}
