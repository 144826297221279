import { ClickType } from './tracking-types';

export const GeneralClickedElement = {
  BACK_TO_PRODUCT_PAGE: {
    clickedElement: 'BackToProductPage',
    clickType: ClickType.OTHER,
  },
  INFOICON_LEBENSSITUATION: {
    clickedElement: 'InfoIcon_LebensSituation',
    clickType: ClickType.OTHER,
  },
  LEISTUNGSUBERSICHT_PDF: {
    clickedElement: 'LeistungsübersichtPDF',
    clickType: ClickType.DOWNLOAD,
  },
  INFOICON_SCHLUESSELVERLUST: {
    clickedElement: 'InfoIcon_Schluesselverlust',
    clickType: ClickType.OTHER,
  },
  INFOICON_NEUWERTENTSCHAEDIGUNG: {
    clickedElement: 'InfoIcon_Neuwertentschaedigung',
    clickType: ClickType.OTHER,
  },
  INFOICON_FORDERUNGSAUSFALL: {
    clickedElement: 'InfoIcon_Forderungsausfall',
    clickType: ClickType.OTHER,
  },
  INFOICON_AMTSDIENSTHAFTPFLICHT: {
    clickedElement: 'InfoIcon_AmtsDiensthaftpflicht',
    clickType: ClickType.OTHER,
  },
  INFOICON_ALLEINSTEHENDEANGEHOERIGE: {
    clickedElement: 'InfoIcon_AlleinstehendeAngehoerige',
    clickType: ClickType.OTHER,
  },
  INFOICON_SELBSTBETEILIGUNG: {
    clickedElement: 'InfoIcon_Selbstbeteiligung',
    clickType: ClickType.OTHER,
  },
  INFOICON_VERTRAGSLAUFZEIT: {
    clickedElement: 'InfoIcon_Vertragslaufzeit',
    clickType: ClickType.OTHER,
  },
  INFOICON_ZAHLUNGSWEISE: {
    clickedElement: 'InfoIcon_Zahlungsweise',
    clickType: ClickType.OTHER,
  },
  LINK_LEISTUNGENANZEIGEN: {
    clickedElement: 'Link_LeistungenAnzeigen',
    clickType: ClickType.OTHER,
  },
  RADIO_SMART: {
    clickedElement: 'Radio_Smart',
    clickType: ClickType.OTHER,
  },
  RADIO_BEST: {
    clickedElement: 'Radio_Best',
    clickType: ClickType.OTHER,
  },
  INFOICON_DATENSCHUTZERGO: {
    clickedElement: 'InfoIcon_DatenschutzErgo',
    clickType: ClickType.OTHER,
  },
  INFOICON_IBAN: {
    clickedElement: 'InfoIcon_IBAN',
    clickType: ClickType.OTHER,
  },
  INFOICON_SEPA: {
    clickedElement: 'InfoIcon_SEPA',
    clickType: ClickType.OTHER,
  },
  TARIFUNDBEITRAG: {
    clickedElement: 'TarifUndBeitrag',
    clickType: ClickType.OTHER,
  },
  PERSOENLICHEDATEN: {
    clickedElement: 'PersoenlicheDaten',
    clickType: ClickType.OTHER,
  },
  AMTS_UNDDIENSTHAFTPFLICHT: {
    clickedElement: 'Amts-UndDiensthaftpflicht',
    clickType: ClickType.OTHER,
  },
  ALLEINSTEHENDEFAMILIENANGEHOERIGE: {
    clickedElement: 'AlleinstehendeFamilienangehoerige',
    clickType: ClickType.OTHER,
  },
  BANKVERBINDUNG: {
    clickedElement: 'Bankverbindung',
    clickType: ClickType.OTHER,
  },
  CHECKBOX_BERATUNGSVERZICHT_ACTIVE: {
    clickedElement: 'CheckBox_Beratungsverzicht_active',
    clickType: ClickType.OTHER,
  },
  CHECKBOX_BERATUNGSVERZICHT_INACTIVE: {
    clickedElement: 'CheckBox_Beratungsverzicht_inactive',
    clickType: ClickType.OTHER,
  },
  BUTTON_IDDDOWNLOAD: {
    clickedElement: 'Button_IDDDownload',
    clickType: ClickType.OTHER,
  },
  LINK_EMAILKONTAKT: {
    clickedElement: 'Link_EmailKontakt',
    clickType: ClickType.OTHER,
  },
  BUTTON_FERTIG: {
    clickedElement: 'Button_Fertig',
    clickType: ClickType.OTHER,
  },
};

export interface GeneralClickElementType {
  clickedElement: string;
  clickType: ClickType;
}

export interface RequestErrorInfo {
  errorType: string;
  errorMessages: string;
  errorUrl: string;
}

export const REQUEST_ERROR_INFO_PARSING_ERROR: RequestErrorInfo = {
  errorType: '000',
  errorMessages: 'Error while parsing request error message',
  errorUrl: window.location.href,
};
