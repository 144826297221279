import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getActualGrossFee,
  getInsuranceStartDate,
  getInsuredOfficialsSelectedOption,
  getInsuredRelativesCount,
  getPaymentMethod,
  getSelectedVariantName,
  isInitialPriceBasedOptionSelected,
  isInsuredOffcialsOptionSelected,
  isInsuredRelativesOptionSelected,
  isKeyLossOptionSelected,
  isStartBonusIncluded,
  isUnpaidDebtOptionSelected,
} from '../../../entities/offer/offer.selectors';
import { RootState } from '../../../store/store.interface';
import TariffAndContributionComponent from './tariff-and-contribution.component';
import { trackingActions } from '../../../entities/tracking/tracking.actions';
import { GeneralClickElementType } from '../../../tracking/tracking-general-clicks.types';

const mapStateToProps = (state: RootState) => ({
  insuranceStartDate: getInsuranceStartDate(state).value,
  isKeyLossSelected: isKeyLossOptionSelected(state),
  isUnpaidDebtSelected: isUnpaidDebtOptionSelected(state),
  isInitialPriceBasedSelected: isInitialPriceBasedOptionSelected(state),
  isStartBonusIncluded: isStartBonusIncluded(state),

  actualGrossFee: getActualGrossFee(state),
  selectedVariantName: getSelectedVariantName(state),

  isInsuredOfficialsSelected: isInsuredOffcialsOptionSelected(state),
  insuredOfficialsDetailsLabel: getInsuredOfficialsSelectedOption(state),

  isInsuredRelativesSelected: isInsuredRelativesOptionSelected(state),
  insuredRelativesCount: getInsuredRelativesCount(state),

  paymentMethod: getPaymentMethod(state)?.value,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const TariffAndContribution = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TariffAndContributionComponent);
