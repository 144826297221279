import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getPdfUrl, getProductTableUrl } from '../../entities/aem/aem-properties.selectors';
import { offerActions } from '../../entities/offer/offer.actions';
import {
  SelectedVariantUpdatePayload,
  UpdateVariantFieldPayload,
  VariantsNames,
} from '../../entities/offer/offer.interface';
import {
  getBirthdayDate,
  getInitialPriceBased,
  getInsuranceStartDate,
  getInsuredOfficials,
  getInsuredRelatives,
  getKeyLoss,
  getSelectedMaritalStatus,
  getSelectedVariantName,
  getUnpaidDebtInsurance,
} from '../../entities/offer/offer.selectors';
import { getInsuranceOwner, getInsurancePartner, getInsuredPersonsIds } from '../../entities/persons/persons.selectors';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { RootState } from '../../store/store.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { ContributionComponentWithNavigation } from './contribution.component';

const mapStateToProps = (state: RootState) => ({
  maritalStatus: getSelectedMaritalStatus(state).value,
  insuredPersonBirthdate: getBirthdayDate(state)?.value,
  insuranceStartDate: getInsuranceStartDate(state)?.value,
  // @TODO getVariants form offer eg. getVariants()
  variants: {
    [VariantsNames.PHV_SMART]: {
      insuranceSum: 10_000_000,
    },
    [VariantsNames.PHV_BEST]: {
      insuranceSum: 50_000_000,
    },
  },
  selectedVariantName: getSelectedVariantName(state),
  keyLoss: getKeyLoss(state),
  initialPriceBased: getInitialPriceBased(state),
  unpaidDebtInsurance: getUnpaidDebtInsurance(state),
  insuredRelatives: getInsuredRelatives(state),
  insuredOfficials: getInsuredOfficials(state),
  insuranceOwner: getInsuranceOwner(state),
  insurancePartner: getInsurancePartner(state),
  insuredPersonsIds: getInsuredPersonsIds(state),
  productTableUrl: getProductTableUrl(state),
  pdfUrl: getPdfUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFieldSelectedVariant: (updateVariantValue: SelectedVariantUpdatePayload) => dispatch(
    offerActions.updateFieldSelectedVariant(updateVariantValue),
  ),
  sendFieldSelectedVariant: (updateVariantValue: SelectedVariantUpdatePayload) => dispatch(
    offerActions.sendFieldSelectedVariant(updateVariantValue),
  ),
  updateVariantField: (fieldPayload: UpdateVariantFieldPayload) => dispatch(
    offerActions.updateVariantField(fieldPayload),
  ),
  sendVariantField: (variantFieldPayload: UpdateVariantFieldPayload) => dispatch(
    offerActions.sendVariantField(variantFieldPayload),
  ),
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.CONTRIBUTION)),
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(
    generalClickElementType,
  )),
});

export const Contribution = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContributionComponentWithNavigation);
