import { generateApiActions } from '../../store/api-actions.helper';

export const AgencyActionTypes = {
  POST_AGENCY: generateApiActions('@agency/POST_AGENCY'),
};

const createAgency = () => ({
  type: AgencyActionTypes.POST_AGENCY.start,
});

export const agencyActions = {
  createAgency,
};
