import * as React from 'react';

export const MARITAL_STATUS_TOOLTIP = (
  <div className="marital-status__tooltip">
    <h3>Lebenssituationen</h3>

    <p>
      <strong>Single:</strong>
      <br />
      Versichert sind Sie als Versicherungsnehmer.
    </p>

    <p>
      <strong>Alleinerziehend:</strong>
      <br />
      Versichert sind Sie und Ihr Kind bzw. Ihre Kinder*.
    </p>

    <p>
      <strong>Paar:</strong>
      <br />
      Versichert sind Sie und Ihr (Ehe-)Partner. Statt Ihres (Ehe-)Partners können Sie auch ein anderes
      Familienmitglied mitversichern.
    </p>

    <p>
      <strong>Familie:</strong>
      <br />
      Versichert sind Sie und Ihr (Ehe-)Partner. Außerdem Ihre und seine Kinder*.
    </p>

    <div className="marital-status__tooltip__dimmed">
      * Mitversicherte Kinder sind:
      <ul>
        <li>Unverheiratete minderjährige Kinder (auch Stief-, Adoptiv-, Pflegekinder)</li>
        <li>
          Volljährige Kinder in Schule, Berufsausbildung, Studium, freiwilligem Wehrdienst,
          sozialem Jahr
        </li>
        <li>
          Volljährige Kinder während Arbeitslosigkeit nach Schule, Berufsausbildung, Studium,
          freiwilligem Wehrdienst, sozialem Jahr
        </li>
        <li>Volljährige Kinder in Wartezeiten zwischen den Ausbildungsabschnitten</li>
      </ul>
    </div>
  </div>
);
