import Details from '@eg/elements/Details';
import Price from '@eg/elements/Price';
import { breakpointM } from '@eg/elements/styles/breakpoints';
import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { PhvDateFormat } from '../../../config';
import { InsuredOfficialsOptions, OfferStateData, PhvPaymentMethod } from '../../../entities/offer/offer.interface';
import { GeneralClickedElement } from '../../../tracking/tracking-general-clicks.types';
import { onOpen } from '../../tooltip-icon/tooltip-icon';
import { LabelValue } from '../label-value/label-value.component';
import { TariffAndContributionProps } from './tariff-and-contribution.interface';

const TariffAndContributionComponent = ({
  insuranceStartDate,
  isKeyLossSelected,
  isUnpaidDebtSelected,
  isInitialPriceBasedSelected,
  actualGrossFee,
  isStartBonusIncluded,
  selectedVariantName,
  isInsuredOfficialsSelected,
  insuredOfficialsDetailsLabel,
  isInsuredRelativesSelected,
  insuredRelativesCount,
  paymentMethod,
  trackGeneralClick,
}: TariffAndContributionProps) => {
  const isDesktop = useMediaQuery({ query: `(min-width:  ${breakpointM})` });
  const insuranceBeginDateFormatted = moment(
    insuranceStartDate.toString(),
    PhvDateFormat.ISO_DATE,
  ).format(PhvDateFormat.UI_DATE);

  if (!selectedVariantName) {
    throw new Error('Variant has to be selected');
  }

  const getInsuredRelativesFullLabel = (): string => {
    switch (insuredRelativesCount) {
      case 1:
        return 'Alleinstehende Familienangehörige (1 Person)';
      case 2:
      case 3:
        return `Alleinstehende Familienangehörige (${insuredRelativesCount} Personen)`;
      default:
        return '';
    }
  };

  const mapInsuredOfficialsLabelToDescriptiveLabel = (insuredOfficialsLabel?: InsuredOfficialsOptions) => (
    insuredOfficialsLabel === InsuredOfficialsOptions.ME_AND_MY_PARTNER
      ? 'für meinen Partner und mich'
      : insuredOfficialsLabel
  );

  const getInsuredOfficialsFullLabel = (): string => `Amts- und Diensthaftpflicht (${mapInsuredOfficialsLabelToDescriptiveLabel(
    insuredOfficialsDetailsLabel,
  )})`;

  const paymentIntervalLongName = (value: OfferStateData['paymentMethod']['value']): string => {
    switch (value) {
      case PhvPaymentMethod.VIERTELJAEHRLICH:
        return 'Vierteljährlicher';

      case PhvPaymentMethod.HALBJAEHRLICH:
        return 'Halbjährlicher';

      case PhvPaymentMethod.JAEHRLICH:
        return 'Jährlicher';

      case PhvPaymentMethod.MONATLICH:
      default:
        return 'Monatlicher';
    }
  };

  const selectedServices: Array<string> = [];

  if (isKeyLossSelected) { selectedServices.push('Schlüsselverlust'); }
  if (isInitialPriceBasedSelected) { selectedServices.push('Neuwertentschädigung'); }
  if (isUnpaidDebtSelected) { selectedServices.push('Forderungsausfall'); }
  if (isInsuredOfficialsSelected) { selectedServices.push(getInsuredOfficialsFullLabel()); }
  if (isInsuredRelativesSelected) { selectedServices.push(getInsuredRelativesFullLabel()); }

  const renderSelectedServices = () => {
    if (!isDesktop) {
      return ((selectedServices.length > 0) ? selectedServices.map((item) => (
        <div
          key={item}
        >
          {item}
        </div>
      )) : '-');
    }

    return selectedServices.length > 0 ? selectedServices.join(', ') : '-';
  };

  return (
    <Details
      summary="Tarif und Beitrag"
      summaryClassName="checkout__section-header"
      isInitiallyOpen
      onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.TARIFUNDBEITRAG))}
    >
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className="esc_col esc_col-12 esc_col-m-4">
            <LabelValue label="Tarif">
              Private Haftpflichtversicherung
              <br />
              Tarif
              {' '}
              {selectedVariantName}
              {isStartBonusIncluded ? ' inkl. Startbonus' : ''}
            </LabelValue>
          </div>
          <div className="esc_col esc_col-12 esc_col-m-4">
            <LabelValue label={`${paymentIntervalLongName(paymentMethod)} Beitrag`}>
              <Price value={actualGrossFee} unstyled /> inkl. Versicherungssteuer von zurzeit 19%
            </LabelValue>
          </div>
          <div className="esc_col esc_col-12 esc_col-m-4">
            <LabelValue label="Versicherungsbeginn">
              {insuranceBeginDateFormatted}
            </LabelValue>
          </div>
          <div className="esc_col esc_col-12">
            <LabelValue label="Gewählte Leistungsbausteine">
              {renderSelectedServices()}
            </LabelValue>
          </div>
        </div>
      </div>
    </Details>
  );
};

export default TariffAndContributionComponent;
