import * as _ from 'lodash';
import { AnyAction } from 'redux';
import { AgencyActionTypes } from '../agency/agency.actions';
import { AppActionTypes } from '../app/app.actions';
import { OfferActionTypes } from './offer.actions';
import {
  initField,
  initFieldPerVariant,
  initFieldWithRange,
  initStringField,
} from './offer.helpers';
import {
  ConsultancyProtocolId,
  FieldPerVariant,
  IsoDate,
  OfferState,
  OfferStateData,
  PhvSummaryMessage,
  PhvSummaryMessageType,
  SelectedVariantUpdatePayload,
  UpdateVariantFieldPayload,
} from './offer.interface';

const initialState: OfferState = {
  businessId: '',
  loading: false,
  errors: [],
  offerSummary: {
    errors: [],
    received: false,
  },
  data: {
    agencyId: '',
    fee: null,
    sourceSystem: null,
    selectedVariant: initFieldPerVariant(),
    ownContribution: initFieldPerVariant(),
    keyLoss: initFieldPerVariant<boolean>(false),
    initialPriceBased: initFieldPerVariant<boolean>(false),
    unpaidDebtInsurance: initFieldPerVariant<boolean>(false),
    insuredRelatives: initFieldPerVariant(),
    insuredOfficials: initFieldPerVariant(),
    insuranceStartDate: initFieldWithRange<IsoDate>(''),
    selectedMaritalStatus: initField(),
    birthdayDate: initStringField(),
    paymentMethod: initField(),
    durationOfContract: initField(),
    consultancyProtocolId: initField<ConsultancyProtocolId>(null),
  },
};

const mergeObjects = (oldObj: any, newObj: any) => ({ ...oldObj, ...newObj });

const deepMergeObjects = (oldObj: any, newObj: any) =>
  _.defaultsDeep({}, newObj, oldObj);

const offerData = (state: OfferState, delta: Partial<OfferStateData>) =>
  deepMergeObjects(state.data, delta);

const getToggledSelectedVariants = (
  state: OfferState,
  selectedVariantUpdatePayload: SelectedVariantUpdatePayload,
) => {
  const { value, variantName } = selectedVariantUpdatePayload;
  return Object.entries(state.data.selectedVariant).reduce(
    (acc, [selectedVariantName, selectedVariantTmp]) => {
      const selectedVariantValue =
        selectedVariantName === variantName ? value : !value;
      return {
        ...acc,
        [selectedVariantName]: {
          ...selectedVariantTmp,
          value: selectedVariantValue,
        },
      };
    },
    {},
  );
};

export const updatedVariantFieldValue = (
  state: OfferState,
  payload: UpdateVariantFieldPayload,
) => {
  const { fieldName, variantName, value } = payload;
  const fieldNameData = state.data[
    fieldName as keyof OfferStateData
  ] as FieldPerVariant;
  const variantNameData = fieldNameData[variantName];

  return {
    [fieldName]: {
      // @ts-ignore
      ...fieldNameData,
      [payload.variantName]: {
        ...variantNameData,
        value,
      },
    },
  };
};

function updateDataField(
  state: OfferState,
  fieldName: keyof OfferStateData,
  value: any,
): OfferState {
  return {
    ...state,
    data: offerData(state, {
      [fieldName]: {
        value,
        saved: false,
      },
    }),
  };
}

export const getInitialState: () => OfferState = () =>
  JSON.parse(JSON.stringify(initialState));

const setUpdatedFieldAsSaved = (state: OfferState, action: AnyAction) => {
  const clonedResponse = _.cloneDeep(action.payload.response);
  clonedResponse.data[action.context.requestPayload.fieldName] = {
    ...clonedResponse.data[action.context.requestPayload.fieldName],
    saved: true,
  };
  return clonedResponse;
};

const setUpdatedFieldAsUnsaved = (state: OfferState, action: AnyAction) => {
  const { fieldName } = action.context.requestPayload;
  const fieldNameData = state.data[fieldName as keyof OfferStateData];

  if (fieldNameData instanceof Object) {
    return {
      ...state,
      data: offerData(state, {
        [fieldName]: {
          ...fieldNameData,
          saved: false,
        },
      }),
    };
  }

  return {
    ...state,
    data: offerData(state, {
      [fieldName]: fieldNameData,
    }),
  };
};

const offerSummaryErrors = (warnings: PhvSummaryMessage[]) =>
  warnings.filter(
    (warn: PhvSummaryMessage) => warn.type === PhvSummaryMessageType.ERROR,
  );

const offerSummary = ({ messages }: { messages: PhvSummaryMessage[] }) => ({
  received: true,
  errors: offerSummaryErrors(messages),
});

/* eslint-disable @typescript-eslint/default-param-last*/
export const offerReducer = (
  state: OfferState = initialState,
  action: AnyAction,
): OfferState => {
  switch (action.type) {
    case AppActionTypes.INIT_WITH_BUSINESS_ID.success:
    case AppActionTypes.INIT.success:
      // @TODO I THINK WE SHOULD USE INITIALSTATE HERE INSTEAD OF STATE
      return mergeObjects(initialState, {
        businessId: action.payload.offer.businessId,
        data: offerData(state, action.payload.offer.data),
        errors: [],
        loading: false,
      });

    case OfferActionTypes.GET_OFFER.start:
      return mergeObjects(state, {
        businessId: action.payload.businessId,
        errors: [],
        data: {},
        loading: true,
      });

    case OfferActionTypes.GET_OFFER.success:
      return mergeObjects(state, {
        data: offerData(state, action.payload.response),
        loading: false,
      });

    case OfferActionTypes.VALIDATE_OFFER.success:
    case OfferActionTypes.SUBMIT_OFFER.success:
      return mergeObjects(state, {
        offerSummary: offerSummary(action.payload.response),
        loading: false,
      });

    case OfferActionTypes.GET_OFFER.error:
      return mergeObjects(state, {
        errors: [...state.errors, action.payload.error.message],
        loading: false,
      });

    case OfferActionTypes.SET_MARITAL_STATUS:
      return mergeObjects(state, {
        data: offerData(state, { selectedMaritalStatus: action.payload }),
      });

    case OfferActionTypes.UPDATE_FIELD_SELECT_VARIANT: {
      return mergeObjects(state, {
        data: offerData(state, {
          /* @ts-ignore*/
          selectedVariant: getToggledSelectedVariants(state, action.payload),
        }),
      });
    }

    case OfferActionTypes.UPDATE_FIELD:
      return updateDataField(
        state,
        action.payload.fieldName,
        action.payload.value,
      );

    case OfferActionTypes.UPDATE_VARIANT_FIELD:
      return mergeObjects(state, {
        data: offerData(state, updatedVariantFieldValue(state, action.payload)),
      });

    case AgencyActionTypes.POST_AGENCY.start:
    case OfferActionTypes.PUT_OFFER.start:
    case OfferActionTypes.VALIDATE_OFFER.start:
    case OfferActionTypes.SUBMIT_OFFER.start:
      return {
        ...state,
        loading: true,
      };

    case AgencyActionTypes.POST_AGENCY.success:
      return {
        ...state,
        data: offerData(state, {
          agencyId: action.payload.response.agencyId,
        }),
        loading: false,
      };

    case OfferActionTypes.PUT_OFFER.success:
      return mergeObjects(state, {
        ...setUpdatedFieldAsSaved(state, action),
        loading: false,
      });

    case OfferActionTypes.PUT_OFFER.error:
      return mergeObjects(state, {
        ...setUpdatedFieldAsUnsaved(state, action),
        errors: [...state.errors, action.payload.error.message],
        loading: false,
      });
    case AgencyActionTypes.POST_AGENCY.error:
    case OfferActionTypes.VALIDATE_OFFER.error:
    case OfferActionTypes.SUBMIT_OFFER.error:
      return mergeObjects(state, {
        errors: [...state.errors, action.payload.error.message],
        loading: false,
      });

    default:
      return state;
  }
};
