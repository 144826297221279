import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { RoutePath } from '../app/app.interface';
import { BundleParcelElement } from '../bundle-loader/bundle-loader.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import TooltipIcon, { onOpen } from '../tooltip-icon/tooltip-icon';
import { AdditionalPersons } from './additional-persons/additional-persons.container';
import { PersonalDataMicrofrontComponent } from './personal-data-microfront/personal-data-microfront.component';
import './personal-data.component.scss';
import { PersonalDataProps, PersonalDataState } from './personal-data.interface';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';
import { loadExternalJs } from '../../entities/aem/aem-properties.helper';
import { Logger } from '../../commons/logger';
import { noCacheHeaders } from '../../entities/app/app.epic';

export class PersonalDataComponent extends React.PureComponent<PersonalDataProps, PersonalDataState> {
  ref: React.RefObject<BundleParcelElement>;

  constructor(props: PersonalDataProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      submitCallback() {
      },
      isPersonalDataMFrontLoaded: false,
      isPersonalDataFormValid: false,
      isAdditionalPersonsFormValid: false,
      dataProtectionHtml: '',
    };
  }

  componentDidMount() {
    const {
      setPreviousStep, setNextStepCallback, setCanGoToNextStep, setIsLoading, trackPageLoaded,
    } = this.props;
    trackPageLoaded();
    this.getDataProtectionHtml();
    setIsLoading(true);

    setPreviousStep(RoutePath.CONTRIBUTION);

    setNextStepCallback(() => {
      const { submitCallback } = this.state;

      setCanGoToNextStep(false);
      setIsLoading(true);
      submitCallback();
    });

    this.checkCanGoToNextStep();
  }

  private onAdditionalPersonsChange(isValid: boolean) {
    this.setState({
      isAdditionalPersonsFormValid: isValid,
    }, this.checkCanGoToNextStep);
  }

  private getDataProtectionHtml = async () => {
    const { dataProtectionUrl } = this.props;

    try {
      const { data }: AxiosResponse<string> = await axios.get(dataProtectionUrl, { headers: noCacheHeaders });
      this.setState({ dataProtectionHtml: loadExternalJs(data) });
    } catch (e) {
      Logger.error('Unable to load Experience Fragment');
      Logger.error(e);
      this.setState({ dataProtectionHtml: '<div><strong>Fehler aufgetreten</strong></div>' });
    }
  };

  private tryToGoToNextStep = () => {
    const { goToNextStep } = this.props;

    this.checkCanGoToNextStep();

    if (this.areAllPersonsValid()) {
      goToNextStep(RoutePath.PAYMENT_DATA);
    }
  };

  private areAllPersonsValid(): boolean {
    const { isPersonalDataFormValid, isAdditionalPersonsFormValid } = this.state;
    return isPersonalDataFormValid && isAdditionalPersonsFormValid;
  }

  private checkCanGoToNextStep() {
    const { setCanGoToNextStep } = this.props;
    setCanGoToNextStep(this.areAllPersonsValid());
  }

  render() {
    const { isPersonalDataMFrontLoaded, dataProtectionHtml } = this.state;

    const {
      businessId, personId, insuranceStartDate, trackGeneralClick,
    } = this.props;
    return (
      <div className="personal-data__main-container">
        <div className="personal-data__page-title">Personalisieren Sie Ihren Vertrag:</div>

        <div className="personal-data__page-description">
          Das geht ganz einfach mit wenigen Angaben. Vor dem Abschluss können Sie Ihre persönlichen Daten noch einmal
          prüfen.
        </div>

        <div className="personal-data__data-protection">
          Datenschutz bei ERGO
          <TooltipIcon
            onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_DATENSCHUTZERGO))}
          >
            <div dangerouslySetInnerHTML={{ __html: dataProtectionHtml }} />
          </TooltipIcon>
        </div>

        <h3>Ihre persönlichen Daten als Versicherungsnehmer</h3>

        <PersonalDataMicrofrontComponent
          businessId={businessId}
          personId={personId}
          insuranceStartDateISO={insuranceStartDate.value}

          onInit={(submitCallback) => {
            this.setState({
              isPersonalDataMFrontLoaded: true,
              submitCallback,
            });
          }}

          onValidChange={(isValid: boolean) => {
            this.setState({
              isPersonalDataFormValid: isValid,
            }, this.checkCanGoToNextStep);
          }}

          onLoadingChange={(isLoading: boolean) => {
            const { setIsLoading } = this.props;
            setIsLoading(isLoading);
          }}

          onError={() => {
          }}

          onSave={(person, validationErrors) => {
            if (validationErrors.length === 0) {
              const { updatePerson, createAgency } = this.props;

              updatePerson(person);
              createAgency();

              this.tryToGoToNextStep();
            } else {
              Logger.log(`Validation errors from personal-data-microfront: ${validationErrors.join(', ')}`);
            }
          }}
        />

        { isPersonalDataMFrontLoaded && (
          <AdditionalPersons
            onChange={(isValid: boolean) => {
              this.onAdditionalPersonsChange(isValid);
            }}
          />
        ) }

        <p>
          <strong>*</strong>
          &nbsp;Pflichtfeld
        </p>
      </div>
    );
  }
}

export const PersonalDataComponentWithNavigation = withNavigation(PersonalDataComponent);
