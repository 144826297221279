import { AnyAction } from 'redux';
import { AemPropertiesState } from './aem-properties.interface';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { AemPropertiesActionTypes } from './aem-properties.actions';
import { getAemPropertiesStateByAemConfig } from './aem-properties.helper';

const initial: AemPropertiesState = {
  pdfUrl: '',
  productPageUrl: '',

  productTableUrl: '',
  dataProtectionUrl: '',
  welcomeInErgoUrl: '',

  tracking: {
    productName: '',
    productPrimaryCategory: '',
    pageNames: {
      [AdobeAnalyticsPages.MARITAL_STATUS]: '',
      [AdobeAnalyticsPages.INSURANCE_DATE]: '',
      [AdobeAnalyticsPages.BIRTHDATE]: '',
      [AdobeAnalyticsPages.CONTRIBUTION]: '',
      [AdobeAnalyticsPages.PERSONAL_DATA]: '',
      [AdobeAnalyticsPages.PAYMENT_DATA]: '',
      [AdobeAnalyticsPages.CHECKOUT]: '',
      [AdobeAnalyticsPages.CONSULTATION]: '',
      [AdobeAnalyticsPages.THANK_YOU]: '',
    },
  },
};

/* eslint-disable @typescript-eslint/default-param-last*/
export const aemPropertiesReducer = (
  state: AemPropertiesState = initial,
  action: AnyAction,
): AemPropertiesState => {
  switch (action.type) {
    case AemPropertiesActionTypes.LOAD_PROPERTIES:
      return {
        ...getAemPropertiesStateByAemConfig(action.payload),
      };

    default:
      return state;
  }
};
