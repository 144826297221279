import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from '../../store/store.interface';
import { isLoading as isLoadingOffer } from '../../entities/offer/offer.selectors';
import { isLoading as isLoadingPersons } from '../../entities/persons/persons.selectors';
import { isLoading as isLoadingPayment } from '../../entities/payment-data/payment-data.selectors';
import { withNavigationButtons } from './navigation-buttons.hoc';
import { appActions } from '../../entities/app/app.actions';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';

export function withNavigation<T>(WrappedComponent: React.ComponentType<T>) {
  const mapStateToProps = (state: RootState) => ({
    isLoading: isLoadingOffer(state) || isLoadingPersons(state) || isLoadingPayment(state),
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    nextButtonClicked: () => dispatch(appActions.nextButtonClicked()),
    backButtonClicked: () => dispatch(appActions.backButtonClicked()),
    backLinkClicked: () => dispatch(trackingActions.trackGeneralClick(GeneralClickedElement.BACK_TO_PRODUCT_PAGE)),
  });

  return connect(
    mapStateToProps, mapDispatchToProps,
  )(withRouter(withNavigationButtons<T>(WrappedComponent)));
}
