import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Logger } from '../../commons/logger';
import { backendUrl } from '../../config';
import { RootState } from '../../store/store.interface';
import { getBusinessId } from '../offer/offer.selectors';
import { getInsuranceOwnerPersonId } from '../persons/persons.selectors';
import { AgencyActionTypes } from './agency.actions';

export const onCreateAgencyEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(AgencyActionTypes.POST_AGENCY.start),
  mergeMap(() => ajax.post(
    `${backendUrl}/api/phv/agency/${getBusinessId(state$.value)}`,
    { personId: getInsuranceOwnerPersonId(state$.value) },
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: AgencyActionTypes.POST_AGENCY.success,
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: AgencyActionTypes.POST_AGENCY.error,
          payload: {
            error,
          },
        });
      }),
    )),
);
