/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import React, { FunctionComponent } from 'react';
import TooltipIcon, { TooltipIconProps } from '@eg/elements/TooltipIcon';
import './tooltip-icon.css';

export const onOpen = (callback: () => void) => (isOpen: boolean) => {
  if (isOpen) {
    callback?.();
  }
};

export const EnhancedTooltipIcon: FunctionComponent<TooltipIconProps> = (props: React.PropsWithChildren<TooltipIconProps>) => (
  <TooltipIcon {...props}><div className="enhanced-tooltip-content">{props.children}</div></TooltipIcon>
);

export default EnhancedTooltipIcon;
