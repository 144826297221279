import React, { ChangeEvent, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import Radio from '@eg/elements/Radio';
import { InsuredOfficialsProps } from './insured-officials.interface';
import { InsuredOfficialsRadioValues } from '../contribution.interface';
import { PhvMaritalStatus } from '../../marital-status/marital-status.interface';

export const InsuredOfficialsComponent = ({
  maritalStatus,
  insuranceOwner,
  insurancePartner,
  insuredOfficialsIds,
  selectedVariantName,
  assignPersonsToOffer,
}: InsuredOfficialsProps) => {
  const [hasInsuredOfficials, setHasInsuredOfficials] = useState(!!insuredOfficialsIds.length);

  const isOptionSelected = (value: InsuredOfficialsRadioValues) => {
    const hasInsuranceOwnerId = insuredOfficialsIds.includes(insuranceOwner.personId);
    const hasInsuranceParnerId = insuredOfficialsIds.includes(insurancePartner.personId);

    switch (value) {
      case InsuredOfficialsRadioValues.ME_AND_MY_PARTNER:
        return hasInsuranceOwnerId && hasInsuranceParnerId;
      case InsuredOfficialsRadioValues.ME:
        return hasInsuranceOwnerId && !hasInsuranceParnerId;
      default:
        return false;
    }
  };

  const isMultipleOptionsAvailable = (status: PhvMaritalStatus): boolean => [PhvMaritalStatus.PAIR, PhvMaritalStatus.FAMILY].includes(status);
  const showMultipleOptions = hasInsuredOfficials && isMultipleOptionsAvailable(maritalStatus);

  const addOrRemoveInsuranceOwnerFromOfficialInsurance = (checked: boolean): void => {
    const payload = checked ? [insuranceOwner.personId] : [];
    assignPersonsToOffer('insuredOfficials', selectedVariantName, payload);
  };

  const removeInsuredOfficialsFromOffer = (checked: boolean) => {
    if (!checked && insuredOfficialsIds.length) {
      assignPersonsToOffer('insuredOfficials', selectedVariantName, []);
    }
  };

  const handleOnChange = (event: ChangeEvent): void => {
    const { checked } = event.target as HTMLInputElement;
    setHasInsuredOfficials(checked);

    return isMultipleOptionsAvailable(maritalStatus)
      ? removeInsuredOfficialsFromOffer(checked)
      : addOrRemoveInsuranceOwnerFromOfficialInsurance(checked);
  };

  return (
    <div className="contribution__variant-option">
      <Checkbox
        label="Amts- und Diensthaftpflicht"
        checked={hasInsuredOfficials}
        onChange={handleOnChange}
        data-testid="insured-officials-checkbox"
      />

      { showMultipleOptions && (
        <div className="contribution__radio-options" data-testid="insured-officials-multiple-option">
          <Radio
            name="insuredOfficials"
            value={InsuredOfficialsRadioValues.ME}
            checked={isOptionSelected(InsuredOfficialsRadioValues.ME)}
            label="Für mich"
            data-testid="me"
            onChange={() => assignPersonsToOffer(
              'insuredOfficials',
              selectedVariantName,
              [insuranceOwner.personId],
            )}
          />
          <br />

          <Radio
            name="insuredOfficials"
            value={InsuredOfficialsRadioValues.ME_AND_MY_PARTNER}
            checked={isOptionSelected(InsuredOfficialsRadioValues.ME_AND_MY_PARTNER)}
            label="Für meinen Partner und mich"
            data-testid="me-and-my-partner"
            onChange={() => assignPersonsToOffer(
              'insuredOfficials',
              selectedVariantName,
              [insuranceOwner.personId, insurancePartner.personId],
            )}
          />
        </div>
      )}
    </div>
  );
};
