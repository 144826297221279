import { Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { map } from 'rxjs/operators';
import { RootState } from '../../store/store.interface';
import { TrackingActionTypes } from './tracking.actions';
import { AdobeAnalytics } from '../../tracking/adobe-analytics';
import { getApp, getPreviousNavigationAction } from '../app/app.selectors';
import {
  AdobeAnalyticsSysEnv,
  isAdobeAnalyticsSysEnv,
} from '../../tracking/tracking-types';
import { ChannelType } from '../../components/app/app.interface';
import { getTrackingProperties } from '../aem/aem-properties.selectors';
import { Logger } from '../../commons/logger';
import { trackingSysEnv } from '../../config';

const AdobeAnalyticsInstance = AdobeAnalytics.getInstance();
const trackSysEnv = isAdobeAnalyticsSysEnv(trackingSysEnv) ? trackingSysEnv : AdobeAnalyticsSysEnv.DEVELOP;

export const onPageLoadedEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(TrackingActionTypes.PAGE_LOADED),
  map((action) => {
    AdobeAnalyticsInstance.pageLoad(action.payload, state$.value, getPreviousNavigationAction(state$.value));
    window.parent.postMessage('scrolltotop', '*');
    return { type: 'PAGE_LOADED_TRACKING_SUCCESS' };
  }),
);

export const onElementClickedEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(TrackingActionTypes.ELEMENT_CLICKED),
  map((action) => {
    AdobeAnalyticsInstance.generalClick(action.payload, getApp(state$.value).oeNr);
    return { type: TrackingActionTypes.ELEMENT_CLICKED_SUCCESS };
  }),
);

export const onTrackingInitializedEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(TrackingActionTypes.INITIALIZATION),
  map(() => {
    const { channelType } = getApp(state$.value);
    const aemTrackingProperties = getTrackingProperties(state$.value);
    const sysEnv = trackSysEnv;

    AdobeAnalyticsInstance.start(window.appEventData, aemTrackingProperties, channelType === ChannelType.MAKLER, sysEnv, true, Logger.log);
    return { type: 'INITIALIZATION_TRACKING_SUCCESS' };
  }),
);
