import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import React, { useState } from 'react';
import { PersonSalutation } from '../../../entities/persons/persons.interface';

interface PersonCoInsuredProps {
  id: string;
  salutation: PersonSalutation;
  firstName: string;
  lastName: string;
  errors?: string[];

  setTitle: (newTitle: PersonSalutation) => void;
  setFirstName: (newName: string) => void;
  setLastName: (newLastName: string) => void;
}

const PersonCoinsuredEditComponent = ({
  id,
  salutation,
  firstName,
  lastName,
  setTitle,
  setFirstName,
  setLastName,
  errors,
}: PersonCoInsuredProps) => {
  const [firstNameState, setFirstNameState] = useState(firstName || '');
  const [lastNameState, setLastNameState] = useState(lastName || '');

  return (
    <>
      <RadioGroupRow
        label="Anrede*"
        name={`${id}_title`}
        defaultValue={salutation}
        onChange={(event) => setTitle(event.target.value as PersonSalutation)}
      >
        <Radio
          data-testid={`${id}-title-mister`}
          value={PersonSalutation.MISTER}
          label={PersonSalutation.MISTER}
        />
        <Radio
          data-testid={`${id}-title-mistress`}
          value={PersonSalutation.MISTRESS}
          label={PersonSalutation.MISTRESS}
        />
      </RadioGroupRow>
      <FormRow
        label="Name*"
        error={(
          <div>
            {errors && errors.map((err) => (
              <div key={err}>{err}</div>
            ))}
          </div>
        )}
      >
        <Input
          placeholder="Vorname"
          name={`${id}_firstName`}
          data-testid={`${id}-firstName`}
          aria-label="Vorname"
          value={firstNameState}
          onChange={(event) => setFirstNameState(event.target.value)}
          onBlur={() => setFirstName(firstNameState)}
          error={errors && errors.length > 0}
        />

        <Input
          placeholder="Nachname"
          name={`${id}_lastName`}
          data-testid={`${id}-lastName`}
          aria-label="Nachname"
          value={lastNameState}
          onChange={(event) => setLastNameState(event.target.value)}
          onBlur={() => setLastName(lastNameState)}
          error={errors && errors.length > 0}
        />
      </FormRow>
    </>
  );
};

PersonCoinsuredEditComponent.defaultProps = {
  errors: [],
};

export { PersonCoinsuredEditComponent };
