import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions } from '../../entities/offer/offer.actions';
import { getSelectedMaritalStatus } from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { MaritalStatusComponentWithNavigation } from './marital-status.component';
import { PhvMaritalStatus } from './marital-status.interface';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { getProductPageUrl } from '../../entities/aem/aem-properties.selectors';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';

const mapStateToProps = (state: RootState) => ({
  selectedMaritalStatus: getSelectedMaritalStatus(state),
  productPageUrl: getProductPageUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStatus: (newMaritalStatus: PhvMaritalStatus) => dispatch(offerActions.updateField({
    fieldName: 'selectedMaritalStatus',
    value: newMaritalStatus,
  })),

  sendMaritalStatus: (newMaritalStatus: PhvMaritalStatus) => dispatch(offerActions.sendField({
    fieldName: 'selectedMaritalStatus',
    value: newMaritalStatus,
  })),

  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.MARITAL_STATUS)),
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const MaritalStatus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaritalStatusComponentWithNavigation);
