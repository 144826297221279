import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RootState } from '../../store/store.interface';
import { PersonsActionTypes } from './persons.actions';
import { Logger } from '../../commons/logger';
import { backendUrl } from '../../config';

export const onPutPersonBirthdayDateEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.start),
  mergeMap(({ payload }) => ajax.put(
    `${backendUrl}/api/person/${payload.personId}`,
    {
      person: payload,
      validationRules: [],
    },
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.success,
        context: { requestPayload: payload },
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.error,
          context: { requestPayload: payload },
          payload: {
            error,
          },
        });
      }),
    )),
);

export const onPutPersonEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(PersonsActionTypes.PUT_PERSON.start),

  mergeMap(({ payload }) => ajax.put(
    `${backendUrl}/api/person/${payload.person.personId}`,
    {
      person: payload.person,
      validationRules: payload.validationRules,
    },
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: PersonsActionTypes.PUT_PERSON.success,
        context: { requestPayload: payload },
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: PersonsActionTypes.PUT_PERSON.error,
          context: { requestPayload: payload },
          payload: {
            error,
          },
        });
      }),
    )),
);

export const onSendAdditionalPersonEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(PersonsActionTypes.POST_ADDITIONAL_PERSON.start),
  mergeMap(({ payload }) => ajax.post(
    `${backendUrl}/api/phv/person/additional-person/${payload.personId}`,
    { ...payload },
    { 'Content-Type': 'application/json' },
  )
    .pipe(
      map(({ response }) => ({
        type: PersonsActionTypes.POST_ADDITIONAL_PERSON.success,
        context: { requestPayload: payload },
        payload: {
          response,
        },
      })),
      catchError((error) => {
        Logger.log(error);
        return of({
          type: PersonsActionTypes.POST_ADDITIONAL_PERSON.error,
          context: { requestPayload: payload },
          payload: {
            error,
          },
        });
      }),
    )),
);
