import { AemAppConfig, AemWindow } from '../../aem/aem.types';
import { AemPropertiesState } from './aem-properties.interface';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';

const getValueOrEmptyString = (value: string | undefined): string => (value || '');

const getHostnameWithProtocol = () => `${window.location.protocol}//${window.location.hostname}`;

export const isEditMode = (): boolean => {
  const aemWindow = (window as unknown) as AemWindow;
  if (aemWindow.isEditMode === undefined) {
    return false;
  }
  return aemWindow.isEditMode;
};

const getXfUrlByAemProperties = (pathToXf: string) => {
  // ! Important !
  // when we refer to published content (published AEM, not the editor) - path to experience fragment is a little bit different
  // 'content/experience-fragments' is being replaced with 'xf'

  if (isEditMode()) {
    return getHostnameWithProtocol() + pathToXf;
  }
  const newPathToFile = pathToXf.replace('content/experience-fragments', 'xf');
  return getHostnameWithProtocol() + newPathToFile;
};

export const getAemPropertiesStateByAemConfig = (aemConfig: AemAppConfig): AemPropertiesState => ({
  pdfUrl: getValueOrEmptyString(aemConfig.PDF_URL),
  productPageUrl: getValueOrEmptyString(aemConfig.Produktseite_URL),

  productTableUrl: getXfUrlByAemProperties(getValueOrEmptyString(aemConfig.alleLeistungenContent)),
  dataProtectionUrl: getXfUrlByAemProperties(getValueOrEmptyString(aemConfig.datenschutzBeiErgoContent)),
  welcomeInErgoUrl: getXfUrlByAemProperties(getValueOrEmptyString(aemConfig.wilkommenBeiErgoContent)),

  tracking: {
    productName: getValueOrEmptyString(aemConfig.trackingKuerzel),
    productPrimaryCategory: getValueOrEmptyString(aemConfig.trackingProduktgruppe),
    pageNames: {
      [AdobeAnalyticsPages.MARITAL_STATUS]: getValueOrEmptyString(aemConfig.trackingNameBasisdatenSituation),
      [AdobeAnalyticsPages.INSURANCE_DATE]: getValueOrEmptyString(aemConfig.trackingNameBasisdatenVersBeginn),
      [AdobeAnalyticsPages.BIRTHDATE]: getValueOrEmptyString(aemConfig.trackingNameBasisdaten),
      [AdobeAnalyticsPages.CONTRIBUTION]: getValueOrEmptyString(aemConfig.trackingNameBerechnungsergebnis),
      [AdobeAnalyticsPages.PERSONAL_DATA]: getValueOrEmptyString(aemConfig.trackingNamePersonendaten),
      [AdobeAnalyticsPages.PAYMENT_DATA]: getValueOrEmptyString(aemConfig.trackingNamePersonendatenBankdaten),
      [AdobeAnalyticsPages.CHECKOUT]: getValueOrEmptyString(aemConfig.trackingNamePruefenUndSenden),
      [AdobeAnalyticsPages.CONSULTATION]: getValueOrEmptyString(aemConfig.trackingNamePruefenUndSendenIDD),
      [AdobeAnalyticsPages.THANK_YOU]: getValueOrEmptyString(aemConfig.trackingNameAbschlussFeedback),
    },
  },
});

export const loadExternalJs = (content: any): string => {
  const aemWindow = (window as unknown) as AemWindow;
  if (aemWindow.loadFetchedAemJs) {
    return aemWindow.loadFetchedAemJs(content);
  }
  return content;
};
