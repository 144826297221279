import Button from '@eg/elements/Button';
import { PhoneIcon } from '@eg/elements/components/Icons';
import { breakpointM } from '@eg/elements/styles/breakpoints';
import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import { ChannelType, RoutePath } from '../app/app.interface';
import { PriceHeader } from '../price-header/price-header.container';
import ergoLogoImg from './ergo_logo.svg';
import './header.component.scss';
import { ProgressBar } from '../progress-bar/progress-bar.component';
import { HeaderProps } from './header.interface';

const PAGES_WITH_VISIBLE_PRICE: string[] = [
  RoutePath.CONTRIBUTION,
  RoutePath.PERSONAL_DATA,
  RoutePath.CHECKOUT,
  RoutePath.PAYMENT_DATA,
  RoutePath.CONSULTATION,
];

type NavigationRouteType = Partial<Record<RoutePath, number>>;
export const NavigationPathsIndexes: NavigationRouteType = {
  [RoutePath.ROOT]: 1,
  [RoutePath.MARITAL_STATUS]: 1,
  [RoutePath.INSURANCE_START]: 2,
  [RoutePath.BIRTHDATE]: 3,
  [RoutePath.CONTRIBUTION]: 4,
  [RoutePath.PERSONAL_DATA]: 5,
  [RoutePath.PAYMENT_DATA]: 6,
  [RoutePath.CHECKOUT]: 7,
  [RoutePath.CONSULTATION]: 8,
  [RoutePath.THANK_YOU]: 9,
};

const FULL_PROCESS_LENGTH = 9;
//todo add value when makler version arrives
// const MAKLER_PROCESS_LENGTH = ?

//todo return process length for proper progress bar calculation in makler variant
const getProcessLength = () => FULL_PROCESS_LENGTH;

export const Header = ({ channelType, inAem }: HeaderProps) => {
  const history = useHistory();
  const [currentStepProgressPercentage, setCurrentStepProgressPercentage] = useState<number>(1);
  const [pageWithVisiblePrice, setWhitelistedPage] = useState<boolean>(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState<boolean>(true);

  const getCurrentProcessProgressPercentage = (pathname: RoutePath): number => ((NavigationPathsIndexes[pathname] || 1) / getProcessLength()) * 100;

  const showHeader = !(channelType === ChannelType.MAKLER || channelType === ChannelType.BROKER || inAem);

  useEffect(() => {
    setWhitelistedPage(PAGES_WITH_VISIBLE_PRICE.includes(history.location.pathname));
    history.listen((location) => {
      //todo let's try to get rid of the `as RoutePath`
      setCurrentStepProgressPercentage(getCurrentProcessProgressPercentage(location.pathname as RoutePath));
      setWhitelistedPage(PAGES_WITH_VISIBLE_PRICE.includes(location.pathname));
      setIsProgressBarVisible(location.pathname !== RoutePath.THANK_YOU);
    });
  });

  useEffect(() => {
    //todo let's try to get rid of the `as RoutePath`
    setCurrentStepProgressPercentage(getCurrentProcessProgressPercentage(history.location.pathname as RoutePath));
    setWhitelistedPage(PAGES_WITH_VISIBLE_PRICE.includes(history.location.pathname));
  });

  const getAemNavHeight = (): number => document.querySelector('.page__navi')?.clientHeight ?? 0;
  const getHeaderElement = (): HTMLElement | null => document.querySelector('.header__main-header-container');

  // Move PHV price header and progress bar just below AEM header
  const adjustHeaderToAEMWrapper = () => {
    if (inAem) {
      const header = getHeaderElement();

      if (header) {
        header.style.paddingTop = `${getAemNavHeight()}px`;
      }
    }
  };

  const setBodyPadding = () => {
    const headerHeight = getHeaderElement()?.clientHeight ?? 0;

    if (headerHeight > 0) {
      document.body.style.paddingTop = `${headerHeight}px`;
    }
  };

  useEffect(setBodyPadding);
  useEffect(adjustHeaderToAEMWrapper, []);

  return (
    <div className="header__main-header-container">
      {showHeader && (
      <div className="header__container">
        <div className="header__logo">
          <Link
            to={{
              pathname: RoutePath.INIT,
            }}
            data-testid="header-logo-button"
          >
            <img className="header__logo__img" src={ergoLogoImg} alt="logo" />
          </Link>
        </div>
        <Media query={`(min-width: ${breakpointM})`}>
          {(matches) => (matches
            ? (
              <>
                <div className="header__phone">
                  <a className="header__phone__link" href="tel:0800-444-1000">
                    <Button size="normal" data-testid="header-phone-button">
                      0800 - 444 1000
                    </Button>
                  </a>
                </div>
              </>
            )
            : (
              <div className="header__phone">
                <PhoneIcon />
              </div>
            )
          )}
        </Media>
      </div>
      )}

      {isProgressBarVisible && <ProgressBar progressPercent={currentStepProgressPercentage} />}
      <PriceHeader onRender={setBodyPadding} showPrice={pageWithVisiblePrice} />
    </div>
  );
};
