import React, { FunctionComponent } from 'react';

import './progress-bar.component.scss';

interface ProgressBarProps {
  progressPercent: number; // 0-100%
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({ progressPercent }: React.PropsWithChildren<ProgressBarProps>) => (
  <div className="progress-bar">
    <div className="progress-bar__container">
      <div className="progress-bar__progress" style={{ width: `${progressPercent}%` }} />
    </div>
  </div>
);
