/* eslint max-len: 0 */ // --> OFF
import React from 'react';

const OWN_CONTRIBUTION = (
  <div className="contribution__tooltip">
    Mit einer Selbstbeteiligung im Schadensfall von <strong>150&nbsp;€</strong> erhalten Sie {' '}
    <strong>20&nbsp;% Nachlass</strong> auf Ihren Nettobeitrag.<br />
    <br />
    <strong>Achtung:</strong> Die vereinbarte Selbstbeteiligung gilt nicht für die Zusatzbausteine {' '}
    „Forderungsausfall“, „Schlüsselverlust“ und „Neuwertentschädigung“.
  </div>
);

const DURATION_OF_CONTRACT = (
  <div className="contribution__tooltip">
    <div className="contribution__tooltip-paraghraph">
      <strong>1 Jahr:</strong> Der <strong>Vertrag verlängert</strong> sich nach Ablauf der Vertragsdauer {' '}
      <strong>stillschweigend</strong> von Jahr zu Jahr. Ausnahme: Dem anderen Vertragspartner ist spätestens 3 {' '}
      Monate vor dem jeweiligen Ablauf eine Kündigung zugegangen.
    </div>

    <div className="contribution__tooltip-paraghraph">
      <strong>3 Jahre:</strong> Entscheiden Sie sich für eine 3-jährige Vertragsdauer, erhalten Sie {' '}
      <strong>10&nbsp;% Nachlass</strong> auf Ihren Nettobeitrag.
    </div>

  </div>
);

const PAYMENT_METHOD = (
  <>
    Je nach Zahlungsweise enthält Ihr Beitrag diese <strong>Ratenzahlungszuschläge:</strong>

    <ul>
      <li>Monatlich: 6&nbsp;%</li>
      <li>Vierteljährlich: 5&nbsp;%</li>
      <li>Halbjährlich: 3&nbsp;%</li>
      <li>Jährlich: Kein Zuschlag</li>
    </ul>

    <strong>Achtung:</strong> Die monatliche Zahlungsweise ist nur im Lastschriftverfahren möglich.
  </>
);

export const ContributionTooltips = {
  INTIAL_PRICE_BASED: (
    <>
      Nach dem Gesetz kann der Geschädigte nur den sogenannten <strong>Zeitwert</strong> verlangen. Der Zeitwert {' '}
      ist der übliche Marktpreis für gebrauchte Sachen. ERGO erstattet aber den <strong>Neuwert</strong> (Neupreis {' '}
      zum Zeitpunkt des Schadens) der von Ihnen beschädigten Gegenstände bis 5.000&nbsp;€.
    </>
  ),
  KEYLOSS: (
    <>
      Geht ein privater Schlüssel verloren und Schlösser müssen ausgetauscht werden, trägt ERGO die Kosten {' '}
      <strong>bis zu 50.000&nbsp;€</strong>. Das gilt auch für Schlüssel, die Sie als Arbeitnehmer erhalten. {' '}
      Unberechtigte Ansprüche wehrt ERGO ab, z. B. bei unverschuldetem Diebstahl der Schlüssel.
    </>
  ),
  UNPAID_DEBT: (
    <>
      ERGO springt ein, wenn Ihnen jemand einen Schaden zufügt, der <strong>nicht versichert</strong> ist und {' '}
      nicht dafür aufkommen kann. Das gilt auch, wenn Ihnen jemand <strong>vorsätzlich</strong> einen Schaden zufügt.
    </>
  ),
  INSURED_OFFICIALS: (
    <>
      ERGO springt ein, wenn Sie als <strong>Beamter</strong> oder {' '}
      <strong>Bediensteter des öffentlichen Dienstes</strong> bei der Ausübung Ihres Amtes einem Dritten Schaden {' '}
      zufügen. In diesem Fall kümmert sich ERGO um die <strong>Regressansprüche</strong>, mit denen Ihr Dienstherr {' '}
      unter Umständen an Sie herantritt.
    </>
  ),
  INSURED_RELATIVES: (
    <>
      Versichern Sie einen alleinstehenden Familienangehörigen einfach mit.<br />
      <br />
      <strong>Voraussetzung:</strong> Der Familienangehörige lebt mit Ihnen in einem Haushalt, in einem {' '}
      Pflegeheim oder in einer ähnlichen Einrichtung, z. B. betreutes Wohnen.
    </>
  ),
  OWN_CONTRIBUTION,
  DURATION_OF_CONTRACT,
  PAYMENT_METHOD,
};
