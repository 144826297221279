import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../store/store.interface';
import { getInsuranceOwner } from '../../../entities/persons/persons.selectors';
import BankDetailsComponent from './bank-details.component';
import { getPaymentData } from '../../../entities/payment-data/payment-data.selectors';
import { GeneralClickElementType } from '../../../tracking/tracking-general-clicks.types';
import { trackingActions } from '../../../entities/tracking/tracking.actions';

const mapStateToProps = (state: RootState) => ({
  insuranceOwner: getInsuranceOwner(state),
  paymentData: getPaymentData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const BankDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankDetailsComponent);
