import { generateApiActions } from '../../store/api-actions.helper';
import { PreviousNavigationActionOptions } from './app.interface';

export const AppActionTypes = {
  INIT: generateApiActions('@app/INIT'),
  INIT_WITH_BUSINESS_ID: generateApiActions('@app/INIT_WITH_BUSINESS_ID'),
  APP_ERROR: '@app/APP_ERROR',
  NAVIGATION_ACTION: '@app/NAVIGATION_ACTION',
  NEW_OFFER_CREATED: '@app/NEW_OFFER_CREATED',
};

const init = () => ({
  type: AppActionTypes.INIT.start,
});

const loadFromBusinessId = (businessId: string) => ({
  type: AppActionTypes.INIT_WITH_BUSINESS_ID.start,
  payload: businessId,
});

const appError = (error: any, message: string) => ({
  type: AppActionTypes.APP_ERROR,
  payload: { error, message },
});

const nextButtonClicked = () => ({
  type: AppActionTypes.NAVIGATION_ACTION,
  payload: PreviousNavigationActionOptions.NEXT,
});

const backButtonClicked = () => ({
  type: AppActionTypes.NAVIGATION_ACTION,
  payload: PreviousNavigationActionOptions.BACK,
});

const newOfferCreated = () => ({
  type: AppActionTypes.NEW_OFFER_CREATED,
});

export const appActions = {
  init,
  appError,
  loadFromBusinessId,
  nextButtonClicked,
  backButtonClicked,
  newOfferCreated,
};
