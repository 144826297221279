import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../store/store.interface';
import InsuredRelativesComponent from './insured-relatives.component';
import { getInsuredRelativesPersons } from '../../../entities/offer/offer.selectors';
import { GeneralClickElementType } from '../../../tracking/tracking-general-clicks.types';
import { trackingActions } from '../../../entities/tracking/tracking.actions';

const mapStateToProps = (state: RootState) => ({
  insuredPersons: getInsuredRelativesPersons(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const InsuredRelatives = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsuredRelativesComponent);
