import { connect } from 'react-redux';
import { Header as HeaderComponent } from './header.component';
import { RootState } from '../../store/store.interface';
import { getApp, isAemIntegrated } from '../../entities/app/app.selectors';

const mapStateToProps = (state: RootState) => ({
  channelType: getApp(state).channelType,
  inAem: isAemIntegrated(state),
});

export const Header = connect(
  mapStateToProps,
)(HeaderComponent);
