import React from 'react';
import Details from '@eg/elements/Details';
import { InsuredOfficialsProps } from './insured-officials.interface';
import { PersonDetails } from '../person-details/person-details.component';
import { InsuredOfficialsOptions } from '../../../entities/offer/offer.interface';
import { onOpen } from '../../tooltip-icon/tooltip-icon';
import { GeneralClickedElement } from '../../../tracking/tracking-general-clicks.types';

const InsuredOfficialsComponent = ({
  insuredOfficialsDetails, insuranceOwner, insurancePartner, trackGeneralClick,
}: InsuredOfficialsProps) => {
  const renderPersonSectionForInsuranceOwner = () => (
    <PersonDetails
      label="Angaben zur versicherten Person"
      person={insuranceOwner}
    />
  );

  const renderPersonSectionForPartner = () => (
    <PersonDetails
      label="Angaben zu Ihrem Partner"
      person={insurancePartner}
    />
  );

  const renderInsuredOfficialPersons = () => {
    switch (insuredOfficialsDetails) {
      case InsuredOfficialsOptions.ME:
        return renderPersonSectionForInsuranceOwner();
      case InsuredOfficialsOptions.ME_AND_MY_PARTNER:
        return (
          <>
            { renderPersonSectionForInsuranceOwner() }
            { renderPersonSectionForPartner() }
          </>
        );
      default:
        return (<></>);
    }
  };

  return (
    <Details
      summary="Amts- und Diensthaftpflicht"
      summaryClassName="checkout__section-header"
      onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.AMTS_UNDDIENSTHAFTPFLICHT))}
    >
      { renderInsuredOfficialPersons() }
    </Details>
  );
};

export default InsuredOfficialsComponent;
