export interface BirthdayDate {
  day: string;
  month: string;
  year: string;
}

export enum SourceSystem {
  EDWIN = 'EDWIN',
  INTERNET = 'ERGO_INTERNET',
  VERMITTLER_HOME_PAGE = 'ERGO_VERMITTLERHOMEPAGE',
}

export type OfferStateData = Readonly<{
  agencyId: string,
  selectedMaritalStatus: Field;
  selectedVariant: FieldPerVariant;
  fee: OfferFee | null;
  sourceSystem: SourceSystem | null;
  birthdayDate: Field<string>;
  insuranceStartDate: FieldWithRange;
  ownContribution: FieldPerVariant;
  keyLoss: FieldPerVariant<boolean>;
  initialPriceBased: FieldPerVariant<boolean>;
  unpaidDebtInsurance: FieldPerVariant<boolean>;
  insuredRelatives: FieldPerVariant;
  insuredOfficials: FieldPerVariant;
  paymentMethod: Field<string>;
  durationOfContract: Field;
  consultancyProtocolId: Field<ConsultancyProtocolId>;
}>;

export enum PhvSummaryMessageType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum PhvPaymentMethod {
  MONATLICH = 'MONATLICH',
  VIERTELJAEHRLICH = 'VIERTELJAEHRLICH',
  HALBJAEHRLICH = 'HALBJAEHRLICH',
  JAEHRLICH = 'JAEHRLICH',
}

export interface PhvSummaryMessage {
  type: PhvSummaryMessageType;
  code: string;
  text: string;
}

export interface OfferSummary {
  errors: PhvSummaryMessage[];
  received: boolean;
}

export type OfferState = Readonly<{
  businessId: string;
  loading: boolean;
  errors: string[];
  offerSummary: OfferSummary;
  data: OfferStateData;
}>;

export type OfferFee = {
  [key in VariantNameValue]: Fee | null
};

interface Fee {
  brutto?: number;
  netto?: number;
}

export type IsoDate = string;

export type FieldValueTypes = string | string[] | IsoDate | boolean | ConsultancyProtocolId;

export type FieldPerVariant<T = FieldValueTypes> = {
  [key in VariantNameValue]: Field<T>;
};

export interface Field<T = FieldValueTypes> {
  value: T;
  defaultValue: string;
  mandatory: boolean;
  options: string[];
  label?: string;
  saved: boolean;
}

export interface FieldWithRange<T = IsoDate> extends Field<T> {
  max: T;
  min: T;
}

export interface SelectedVariantUpdatePayload {
  value: string;
  variantName: string;
}

export interface UpdateVariantFieldPayload {
  fieldName: keyof OfferStateData;
  value: string | string[] | boolean;
  variantName: VariantNameValue;
}

export interface UpdateFieldPayload {
  fieldName: keyof OfferStateData;
  value: string | null;
}

// TODO: Variant names need to be dynamic
export enum VariantsNames {
  PHV_SMART = 'Smart',
  PHV_BEST = 'Best',
}

export const VariantNameValuesList = [VariantsNames.PHV_SMART, VariantsNames.PHV_BEST] as const;

export type VariantNameValue = typeof VariantNameValuesList[number];

export type ConsultancyProtocolId = ConsultancyProtocolIdValues | null;

export enum ConsultancyProtocolIdValues {
  BERATUNGSVERZICHT = 'BERATUNGSVERZICHT',
}

export enum InsuredOfficialsOptions {
  ME = 'Mich',
  ME_AND_MY_PARTNER = 'Partner und mich',
  MY_PARNTER = 'Partner'
}
