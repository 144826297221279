/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// @ts-ignore
import Parcel from 'single-spa-react/parcel';
import * as singleSpa from 'single-spa';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import {
  BundleLoaderProps, BundleLoaderState, BundleConfig, BundleParcelElement, BundleLoaderMethod,
} from './bundle-loader.interface';
import { Logger } from '../../commons/logger';

declare global {
  const SystemJS: {
    import: (url: string) => Promise<{}>;
  };
}

export class BundleLoader extends React.Component<BundleLoaderProps, BundleLoaderState> {
  ref: React.RefObject<BundleParcelElement>;

  constructor(props: BundleLoaderProps) {
    super(props);

    this.state = {
      hasError: false,
      bundleConfig: null,
    };

    this.ref = React.createRef();
  }

  async componentDidMount() {
    const { bundleUrl } = this.props;

    try {
      const bundleConfig = await this.loadBundleConfig(bundleUrl);
      this.setState({ bundleConfig });
    } catch (err) {
      Logger.log('[BundleLoader]', err);
      this.setState({ hasError: true });
    }
  }

  loadBundleConfig = async (bundleUrl: string): Promise<string> => {
    const config = await SystemJS.import(bundleUrl) as BundleConfig;
    const bundleConfig = config?.default;

    if (!bundleConfig) {
      throw new Error(`[BundleLoader] no module: ${bundleUrl}`);
    }

    Object.values(BundleLoaderMethod)
      .filter((method) => typeof bundleConfig[method] !== 'function')
      .forEach((method) => {
        throw new Error(`[BundleLoader] no method ${method}: ${bundleUrl}`);
      });

    return bundleConfig;
  };

  parcelDidMount = () => {
    const { parcelDidMount } = this.props;

    if (parcelDidMount) {
      parcelDidMount(this.ref);
    }
  };

  render() {
    const { bundleConfig, hasError } = this.state;
    const { customProps, id } = this.props;

    if (hasError) {
      return null;
    }

    if (!bundleConfig) {
      return <LoadingSpinner viewport="relative" show />;
    }

    return (
      <Parcel
        {...customProps}
        id={id}
        ref={this.ref}
        config={bundleConfig}
        mountParcel={singleSpa.mountRootParcel}
        parcelDidMount={this.parcelDidMount}
      />
    );
  }
}
