import React, { useEffect, useState } from 'react';
import { Person } from '../../../entities/persons/persons.interface';
import { PersonCoinsuredEditComponent } from '../person-coinsured/person-coinsured-edit.component';
import { AdditionalPersonsProps } from './additional-persons.interface';

export const AdditionalPersonsComponent: React.FunctionComponent<AdditionalPersonsProps> = ({
  onChange,
  insurancePartner,
  insuredRelatives,
  updateInsuredRelative,
  updateInsuredOfficial,
  sendUpdateAdditionalPerson,
}: AdditionalPersonsProps) => {
  const [personsTouched, setPersonsTouched] = useState([] as Person['personId'][]);
  const [insuredOfficialsPartner, setInsuredOfficialsPartner] = useState<Person | undefined>(undefined);

  const hasNamesAndSalutation = (person: Person): boolean => !!(person.salutation && person.lastName && person.firstName);
  const isPersonTouched = (personId: Person['personId']): boolean => personsTouched.some((touchedPersonId) => touchedPersonId === personId);

  const addPersonTouched = (personId: Person['personId']) => {
    const uniquePersonIds: Set<Person['personId']> = new Set(personsTouched);
    uniquePersonIds.add(personId);
    setPersonsTouched(Array.from(uniquePersonIds));
  };
  const sendUpdate = (updatedPerson: Person): void => {
    const {
      lastName, personId, firstName, salutation,
    } = updatedPerson;

    sendUpdateAdditionalPerson(
      personId,
      {
        lastName,
        personId,
        firstName,
        salutation,
      },
    );
  };

  const updatePerson = (type: 'insuredRelative' | 'insuredOfficialPartner', updatedPerson: Person) => {
    if (type === 'insuredOfficialPartner') {
      updateInsuredOfficial(updatedPerson.personId, updatedPerson);
    } else if (type === 'insuredRelative') {
      updateInsuredRelative(updatedPerson.personId, updatedPerson);
    }

    addPersonTouched(updatedPerson.personId);

    if (hasNamesAndSalutation(updatedPerson)) {
      sendUpdate(updatedPerson);
    }
  };

  useEffect(() => {
    const isInsuredOfficialPartnerValid = !insurancePartner
      || (!!insuredOfficialsPartner && hasNamesAndSalutation(insuredOfficialsPartner));

    const isInsuredRelativesValid = !insuredRelatives
      || (insuredRelatives.every(hasNamesAndSalutation));

    const isValid = isInsuredOfficialPartnerValid && isInsuredRelativesValid;

    onChange(isValid);
  }, [insuredOfficialsPartner, insuredRelatives]);

  useEffect(() => {
    if (insurancePartner) {
      setInsuredOfficialsPartner(insurancePartner);
    }
  }, [insurancePartner]);

  return (
    <>
      {insuredOfficialsPartner && (
        <div className="personal-data__coinsured-container">
          <h3>Amts- und Diensthaftpflicht</h3>
          <h4>Angaben zu Ihrem Partner</h4>
          <PersonCoinsuredEditComponent
            id="insuredOfficialPartner"
            key={insuredOfficialsPartner.personId}
            firstName={insuredOfficialsPartner.firstName}
            lastName={insuredOfficialsPartner.lastName}
            salutation={insuredOfficialsPartner.salutation}

            errors={(
              isPersonTouched(insuredOfficialsPartner.personId)
              && !hasNamesAndSalutation(insuredOfficialsPartner)
              && ['Bitte wählen Sie die Anrede und geben Sie den Namen an.']
            ) || []}

            setTitle={(salutation) => {
              const updatedPerson = {
                ...insuredOfficialsPartner,
                salutation,
              };
              updatePerson('insuredOfficialPartner', updatedPerson);
            }}
            setFirstName={(firstName) => {
              const updatedPerson = {
                ...insuredOfficialsPartner,
                firstName,
              };
              updatePerson('insuredOfficialPartner', updatedPerson);
            }}
            setLastName={(lastName) => {
              const updatedPerson = {
                ...insuredOfficialsPartner,
                lastName,
              };
              updatePerson('insuredOfficialPartner', updatedPerson);
            }}
          />
        </div>
      )}

      {insuredRelatives.length > 0 && (
        <div className="personal-data__coinsured-container">
          <h3>Alleinstehende Familienangehörige</h3>

          {insuredRelatives.map((insuredRelative: Person, idx: number) => (
            <div
              className="personal-data__coinsured-item"
              key={insuredRelative.personId}
            >
              <h4>{`Angaben zur ${idx + 1}. Person`}</h4>
              <PersonCoinsuredEditComponent
                id={`insuredRelative${idx}`}
                key={insuredRelative.personId}
                firstName={insuredRelative.firstName}
                lastName={insuredRelative.lastName}
                salutation={insuredRelative.salutation}

                errors={(
                  isPersonTouched(insuredRelative.personId)
                  && !hasNamesAndSalutation(insuredRelative)
                  && ['Bitte wählen Sie die Anrede und geben Sie den Namen an.']
                ) || []}

                setTitle={(salutation) => {
                  const updatedPerson = {
                    ...insuredRelative,
                    salutation,
                  };
                  updatePerson('insuredRelative', updatedPerson);
                }}
                setFirstName={(firstName) => {
                  const updatedPerson = {
                    ...insuredRelative,
                    firstName,
                  };
                  updatePerson('insuredRelative', updatedPerson);
                }}
                setLastName={(lastName) => {
                  const updatedPerson = {
                    ...insuredRelative,
                    lastName,
                  };
                  updatePerson('insuredRelative', updatedPerson);
                }}
              />
            </div>
          ))}

        </div>
      )}
    </>
  );
};
