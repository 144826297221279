import { Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of, concat } from 'rxjs';
import * as _ from 'lodash';
import { RootState } from '../../store/store.interface';
import { getApp } from './app.selectors';
import { AppActionTypes } from './app.actions';
import { AppInitParams } from './app.interface';
import { APP_ERROR_MESSAGE, backendUrl } from '../../config';

// Prevent IE11 from caching requests
export const noCacheHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: -1,
};

const prepareInitParams = (state: RootState): AppInitParams => {
  const { oeNr, channelType, actionNr } = getApp(state);
  return _.omitBy<AppInitParams>({ oeNr, channelType, actionNr }, _.isEmpty);
};

export const onAppInitEpic: Epic<AnyAction, AnyAction, RootState> = (action$, $state) => action$.pipe(
  ofType(AppActionTypes.INIT.start),
  mergeMap((action) => ajax.post(`${backendUrl}/api/phv/init`, prepareInitParams($state.value))
    .pipe(
      mergeMap(({ response: { offer, persons, paymentData } }) => (
        concat(
          of({ type: AppActionTypes.NEW_OFFER_CREATED }),
          of({
            type: AppActionTypes.INIT.success,
            payload: {
              offer,
              persons,
              paymentData,
            },
          }),
        ))),
      catchError((error) => of({
        type: AppActionTypes.INIT.error,
        context: { requestPayload: action.payload },
        payload: {
          error,
          message: APP_ERROR_MESSAGE,
        },
      })),
    )),
);

export const onAppInitLoadEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(AppActionTypes.INIT_WITH_BUSINESS_ID.start),
  mergeMap((action) => ajax.get(
    `${backendUrl}/api/phv/init/${action.payload}`,
    {
      'Content-Type': 'application/json',
      ...noCacheHeaders,
    },
  )
    .pipe(
      map(({ response: { offer, persons, paymentData } }) => ({
        type: AppActionTypes.INIT_WITH_BUSINESS_ID.success,
        payload: {
          offer,
          persons,
          paymentData,
        },
      })),
      catchError((error) => of({
        type: AppActionTypes.INIT_WITH_BUSINESS_ID.error,
        context: { requestPayload: action.payload },
        payload: {
          error,
          message: APP_ERROR_MESSAGE,
        },
      })),
    )),
);

export const onOfferNotFoundErrorEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => action$.pipe(
  ofType(AppActionTypes.INIT_WITH_BUSINESS_ID.error),
  mergeMap((action) => (
    action.payload?.error?.status === 404
      ? of({ type: AppActionTypes.INIT.start })
      : EMPTY
  )),
);
