import { AnyAction } from 'redux';
import { appActions, AppActionTypes } from './app.actions';
import { getInitialState } from './app.reducer';
import { AppState } from './app.interface';
import { APP_ERROR_MESSAGE } from '../../config';
import { AemWindow } from '../../aem/aem.types';
import { REQUEST_ERROR_INFO_PARSING_ERROR } from '../../tracking/tracking-general-clicks.types';
import { AdobeAnalytics } from '../../tracking/adobe-analytics';

const AKTIONSNUMMER_COOKIENAME: string = 'aktionNr=';
const AdobeAnalyticsInstance = AdobeAnalytics.getInstance();

// check if is error and action is not APP_ERROR (prevent infinity recursion)
const isError = (action: AnyAction): boolean => !!action.payload?.error && action.type !== AppActionTypes.APP_ERROR;

const getErrorStatus = (action: AnyAction): number => {
  const { error } = action.payload;
  return error.response?.status || error.status;
};

export const appErrorMiddleware = (store: any) => (next: (args: any) => void) => (action: AnyAction) => {
  if (isError(action)) {
    try {
      AdobeAnalyticsInstance.generalClickError(AdobeAnalyticsInstance.createRequestErrorInfo(action.payload), store.getState());
    } catch (e) {
      AdobeAnalyticsInstance.generalClickError(REQUEST_ERROR_INFO_PARSING_ERROR, store.getState());
    }
    if (getErrorStatus(action) >= 500) {
      store.dispatch(appActions.appError(action.payload.error, APP_ERROR_MESSAGE));
    }
  }
  next(action);
};

const getQueryParameter = (paramName: string) => {
  const searchString = window.location.search.substring(1);
  const params = searchString.split('&');

  for (let i = 0; i < params.length; i += 1) {
    const val = params[i].split('=');
    if (val[0] === paramName) {
      return val[1];
    }
  }
  return null;
};

export const extractAktionsNummerFromCookie = (): string => {
  const decodedCookieString = decodeURIComponent(document.cookie);
  const cookieList: string[] = decodedCookieString.split(';');
  const actionNrCookie: string | undefined = cookieList.find((element: string) => element.includes(AKTIONSNUMMER_COOKIENAME));

  if (actionNrCookie) {
    const [,actionNr]: string[] = actionNrCookie.split('=');
    return actionNr?.length < 4 ? '' : actionNr.substring(0, 4);
  }
  return '';
};

const isAemIntegrated = (): boolean => {
  const aemWindow = (window as unknown) as AemWindow;
  return !!aemWindow.phvAemAssets;
};

export const getAppInitParams = (): AppState => {
  const defaultsToMerge = getInitialState();
  const channelType = getQueryParameter('channelType');
  const oeNr = getQueryParameter('adh_oenr');
  const inAem = isAemIntegrated();
  const actionNr = extractAktionsNummerFromCookie();

  return {
    ...defaultsToMerge,
    channelType,
    oeNr,
    inAem,
    actionNr,
  };
};
