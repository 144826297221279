import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../store/store.interface';
import { getInsuranceOwner, getInsurancePartner } from '../../../entities/persons/persons.selectors';
import InsuredOfficialsComponent from './insured-officials.component';
import { getInsuredOfficialsSelectedOption } from '../../../entities/offer/offer.selectors';
import { GeneralClickElementType } from '../../../tracking/tracking-general-clicks.types';
import { trackingActions } from '../../../entities/tracking/tracking.actions';

const mapStateToProps = (state: RootState) => ({
  insuranceOwner: getInsuranceOwner(state),
  insurancePartner: getInsurancePartner(state),
  insuredOfficialsDetails: getInsuredOfficialsSelectedOption(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const InsuredOfficials = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsuredOfficialsComponent);
