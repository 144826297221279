import './thank-you.component.scss';
import React, { useEffect, useState } from 'react';
import { LoaderIcon } from '@eg/elements/components/Icons';
import axios, { AxiosResponse } from 'axios';
import { loadExternalJs } from '../../entities/aem/aem-properties.helper';
import { Person } from '../../entities/persons/persons.interface';
import { Logger } from '../../commons/logger';
import { noCacheHeaders } from '../../entities/app/app.epic';
import { ReactComponent as ErgoLogoSvg } from '../header/ergo_logo.svg';

interface ThankYouProps {
  deleteOfferId: () => void;
  trackPageLoaded: () => void;
  welcomeInErgoUrl: string;
  insuranceOwner: Person;
}

export const ThankYouComponent = ({
  deleteOfferId, trackPageLoaded, welcomeInErgoUrl, insuranceOwner,
}: ThankYouProps) => {
  const [welcomeInErgoHtml, setWelcomeInErgoHtml] = useState('');

  async function getThankYouPageHtml() {
    try {
      const { data }: AxiosResponse<string> = await axios.get(welcomeInErgoUrl, { headers: noCacheHeaders });
      setWelcomeInErgoHtml(loadExternalJs(data));
    } catch (e) {
      Logger.error('Unable to load Experience Fragment');
      Logger.error(e);
      setWelcomeInErgoHtml('<div><strong>Fehler aufgetreten</strong></div>');
    }
  }

  useEffect(() => {
    getThankYouPageHtml();
    //todo are we expecting race condition here?
    trackPageLoaded();
    deleteOfferId();
  }, []);

  function getInsuranceOwnerFullNameAndSalutation() {
    return `${insuranceOwner.salutation} ${insuranceOwner.firstName} ${insuranceOwner.lastName}`;
  }

  return (
    <div className="thank-you__container">
      <div className="thank-you__content">
        <h3 className="thank-you__welcome">Wilkommen bei</h3>
        <ErgoLogoSvg className="thank-you__logo__img" title="ERGO logo" />
        <h3 className="thank-you__customer">{getInsuranceOwnerFullNameAndSalutation()}</h3>
      </div>

      <div className="thank-you__aem-content">
        {!welcomeInErgoHtml && <LoaderIcon />}
        <div dangerouslySetInnerHTML={{ __html: welcomeInErgoHtml }} />
      </div>
    </div>
  );
};
