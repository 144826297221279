import { RootState } from '../../store/store.interface';
import { AemPropertiesState } from './aem-properties.interface';
import { AEMTrackingProperties } from '../../tracking/tracking-types';

export const getAemProperties = (state: RootState): AemPropertiesState => state.entities.aemProperties;

export const getProductPageUrl = (state: RootState): string => getAemProperties(state).productPageUrl;

export const getPdfUrl = (state: RootState): string => getAemProperties(state).pdfUrl;

// eslint-disable-next-line
export const getProductTableUrl = (state: RootState): string => {
  return getAemProperties(state).productTableUrl;
};

// eslint-disable-next-line
export const getDataProtectionUrl = (state: RootState): string => {
  return getAemProperties(state).dataProtectionUrl;
};

// eslint-disable-next-line
export const getWelcomeInErgoUrl = (state: RootState): string => {
  return getAemProperties(state).welcomeInErgoUrl;
};

// eslint-disable-next-line
export const getTrackingProperties = (state: RootState): AEMTrackingProperties => {
  return getAemProperties(state).tracking;
};
