import { Field } from '../../entities/offer/offer.interface';
import { RoutePath } from '../app/app.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';

export enum PhvMaritalStatus {
  FAMILY = 'FAMILIE',
  SINGLE = 'SINGLE',
  PAIR = 'DUO',
  SELF_PARENTING = 'ALLEINERZIEHENDER',
}

export const PhvMaritalStatusList = [
  PhvMaritalStatus.FAMILY,
  PhvMaritalStatus.SINGLE,
  PhvMaritalStatus.PAIR,
  PhvMaritalStatus.SELF_PARENTING,
] as const;

export function isMaritalStatus(value: any): value is PhvMaritalStatus {
  return PhvMaritalStatusList.includes(value) || false;
}

export interface MaritalStatusProps {
  isLoading: boolean;
  productPageUrl: string;
  selectedMaritalStatus: Field<PhvMaritalStatus>;
  setStatus: (newStatus: PhvMaritalStatus) => void;
  sendMaritalStatus: (newStatus: PhvMaritalStatus) => void;
  trackPageLoaded: () => void;
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => void;

  setBackLinkLabel: (label: string) => void;
  setCanGoToNextStep: (yesNo: boolean) => void;
  setNextStepCallback: (goToNextStepCallback: () => void) => void;
  setPreviousStep: (uri: RoutePath | string, isExternal?: boolean) => void;
  goToNextStep: (uri: RoutePath) => void;
  setNextStep: (uri: RoutePath) => void;
}

export interface MaritalStatusState {
  isSubmitted: boolean;
}
