/* eslint-disable react/jsx-props-no-spreading */
import Button from '@eg/elements/Button';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../app/app.interface';
import './navigation-buttons.hoc.scss';
import { NavigationButtonsProps, NavigationButtonsState } from './navigation-buttons.interface';

export function withNavigationButtons<T>(WrappedComponent: React.ComponentType<T>) {
  return class extends React.Component<NavigationButtonsProps, NavigationButtonsState> {
    displayName = 'withNavigation';

    constructor(props: any) {
      super(props);

      this.displayName = `withNavigation(${WrappedComponent.displayName})`;

      const state: NavigationButtonsState = {
        goToNextStepCallback: undefined,
        canGoFurther: false,
        previousUri: '',
        isPreviousUriExternal: false,
        goToNextUri: '',
        buttonLabel: 'weiter',
        showButton: true,
        backLinkLabel: 'zurück',
        isLoading: props.isLoading,
      };

      this.state = state;
    }

    componentDidUpdate(prevProps: Readonly<any>) {
      const { isLoading } = this.props;
      if (prevProps.isLoading !== isLoading) {
        this.setIsLoading(isLoading);
      }
    }

    setCanGoToNextStep = (newState: boolean) => {
      this.setState({ canGoFurther: newState });
    };

    setIsLoading = (isLoading: boolean) => {
      this.setState({ isLoading });
    };

    nextClicked = () => {
      const { goToNextUri, goToNextStepCallback } = this.state;
      const { nextButtonClicked } = this.props;
      const { history } = this.props;
      nextButtonClicked();
      if (goToNextStepCallback) {
        goToNextStepCallback.call(this);
      } else {
        history.push({
          pathname: goToNextUri,
        });
      }
    };

    setPreviousStep = (uri: RoutePath, isExternal?: boolean) => {
      this.setState({
        previousUri: uri,
        isPreviousUriExternal: !!isExternal,
      });
    };

    setNextStep = (uri: RoutePath) => {
      this.setState({
        goToNextUri: uri,
      });
    };

    setNextStepCallback = (goToNextStepCallback: () => void) => {
      this.setState({
        goToNextStepCallback,
      });
    };

    goToNextStep = (uri: string) => {
      const { history } = this.props;
      history.push({
        pathname: uri,
      });
    };

    setButtonLabel = (label: string) => {
      this.setState({
        buttonLabel: label,
      });
    };

    showButton = (showButton: boolean) => {
      this.setState({
        showButton,
      });
    };

    setBackLinkLabel = (label: string) => {
      this.setState({
        backLinkLabel: label,
      });
    };

    render() {
      const {
        canGoFurther, buttonLabel, backLinkLabel, previousUri, isPreviousUriExternal, isLoading, showButton,
      } = this.state;

      const { backButtonClicked, backLinkClicked } = this.props;
      const isNextButtonDisabled = !canGoFurther || isLoading;
      const nextButtonVariant = isNextButtonDisabled ? 'disabled' : 'primary';

      return (
        <>
          <WrappedComponent
            {...this.props as unknown as T}
            isLoading={isLoading}
            setPreviousStep={this.setPreviousStep}
            setNextStep={this.setNextStep}
            setCanGoToNextStep={this.setCanGoToNextStep}
            goToNextStep={this.goToNextStep}
            setNextStepCallback={this.setNextStepCallback}
            setButtonLabel={this.setButtonLabel}
            showButton={this.showButton}
            setBackLinkLabel={this.setBackLinkLabel}
            setIsLoading={this.setIsLoading}
          />
          <div className="navigation-buttons">
            {showButton
            && (
              <div>
                <Button
                  data-testid="navigation-submit-button"
                  className="submit-button"
                  type="button"
                  variant={nextButtonVariant}
                  size="large"
                  onClick={this.nextClicked}
                  disabled={isNextButtonDisabled}
                >
                  {buttonLabel}
                </Button>
              </div>
            )}
            <div className="navigation-buttons__back-link-wrapper">
              {(isPreviousUriExternal
                ? (
                  <a
                    data-testid="navigation-back-button"
                    className="navigation-buttons__back-link"
                    href={previousUri}
                    onClick={() => backLinkClicked()}
                  >
                    {backLinkLabel}
                  </a>
                )
                : (
                  <Link
                    data-testid="navigation-back-button"
                    className="navigation-buttons__back-link"
                    to={{
                      pathname: previousUri,
                    }}
                    onClick={() => backButtonClicked()}
                  >
                    {backLinkLabel}
                  </Link>
                )
              )}
            </div>
          </div>
          <LoadingSpinner show={isLoading} />
        </>
      );
    }
  };
}
