import { CheckIllustratedIcon } from '@eg/elements/components/Icons';
import DateInput, { DateInputValue } from '@eg/elements/DateInput';
import moment from 'moment';
import React from 'react';
import { Logger } from '../../commons/logger';
import { MAX_PROFIT_AGE, PhvDateFormat } from '../../config';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import './birthdate.component.scss';
import {
  BirthdateComponentProps,
  BirthdateComponentState,
} from './birthdate.interface';

const DATE_INVALID_FORMAT_MSG =
  'Bitte geben Sie das vollständige Datum ein (Format TT MM JJJJ).';

const zeroFill = (i?: string): string =>
  i === undefined ? '' : (i.length === 2 ? '' : '0') + i;

const parseDateToDateInput = (
  day?: string,
  month?: string,
  year?: string,
): DateInputValue => ({
  day,
  month,
  year,
});

const parseIsoToDateInput = (isoDateStr: string): DateInputValue => {
  const [year, month, day] = isoDateStr.split('-');
  return parseDateToDateInput(day, month, year);
};

const parseDateToIso = (date: DateInputValue): string =>
  `${date.year}-${zeroFill(date.month)}-${zeroFill(date.day)}`;

/* eslint-disable  @typescript-eslint/indent*/
export class BirthdateComponent extends React.Component<
  BirthdateComponentProps,
  BirthdateComponentState
> {
  constructor(props: BirthdateComponentProps) {
    super(props);

    const { setPreviousStep, setNextStepCallback } = this.props;

    const birthdayDate = parseIsoToDateInput(String(props.birthdayDate.value));

    this.state = {
      birthdayDate,
      errors: [],
      valid: this.isBirthdayDateValid(birthdayDate),
      showErrors: this.isBirthdayDateValid(birthdayDate),
      isItProfitAgeRange: false,
      isSubmitted: false,
    };

    this.canContinue();
    setPreviousStep(RoutePath.INSURANCE_START);
    setNextStepCallback(() => {
      this.setState(
        {
          isSubmitted: true,
        },
        this.saveBirthdate,
      );
    });
  }

  componentDidMount(): void {
    const {
      birthdayDate: { value },
      trackPageLoaded,
    } = this.props;
    trackPageLoaded();
    const birthdayDate = parseIsoToDateInput(value as string);

    const isItProfitAgeRange = this.isBirthdayDateValid(birthdayDate)
      ? this.isItProfitAgeRange(birthdayDate)
      : false;
    this.setState(
      {
        isItProfitAgeRange,
        errors: [...this.validateBirthdateOnInsuranceStart(birthdayDate)],
      },
      this.canContinue,
    );

    if (!birthdayDate.day) {
      document
        .querySelector<HTMLInputElement>('#birthday-date-input input')
        ?.focus();
    }
  }

  componentDidUpdate(): void {
    const {
      birthdayDate: { saved },
      goToNextStep,
      isLoading,
    } = this.props;
    const { isSubmitted } = this.state;
    if (!isLoading && isSubmitted && saved) {
      goToNextStep(RoutePath.CONTRIBUTION);
    }
  }

  private isBirthdayDateValid = (
    birthdayDate: Partial<DateInputValue>,
  ): boolean =>
    !!birthdayDate.day && !!birthdayDate.month && !!birthdayDate.year;

  private isYearFilledOut = (year?: string) => year?.length === 4;

  private canContinue = () => {
    const { valid, errors } = this.state;
    const { setCanGoToNextStep } = this.props;

    const hasErrors = Object.keys(errors).length !== 0;
    const canGoFurther = valid && !hasErrors;

    setCanGoToNextStep(canGoFurther);

    if (canGoFurther) {
      const { birthdayDate: stateBirthdayDate } = this.state;
      const { setBirthdayDate, birthdayDate: propsBirthdayDate } = this.props;
      const stateDateInIso = parseDateToIso(stateBirthdayDate);

      if (propsBirthdayDate.value !== stateDateInIso) {
        setBirthdayDate(stateDateInIso);
      }
    }
  };

  private onChange = (value: DateInputValue, errors: any) => {
    const { showErrors } = this.state;
    const { valid, valueMissing, ...otherErrors } = errors;
    const isItProfitAgeRange = valid ? this.isItProfitAgeRange(value) : false;
    const birthdayDate = parseDateToDateInput(
      value.day,
      value.month,
      value.year,
    );

    // Show errors if any exist after filling out the year field
    if (!showErrors) {
      this.setState({
        showErrors: this.isYearFilledOut(birthdayDate.year),
      });
    }

    const customDateErrors = this.validateBirthdateOnInsuranceStart(value);

    if (valueMissing) {
      customDateErrors.push(DATE_INVALID_FORMAT_MSG);
    }

    this.setState(
      {
        birthdayDate,
        errors: [...Object.values<string>(otherErrors), ...customDateErrors],
        valid,
        isItProfitAgeRange,
      },
      () => this.canContinue(),
    );
  };

  // Show errors if any exist on blur
  private onBlur = () => {
    this.setState({
      showErrors: true,
    });
  };

  private saveBirthdate = () => {
    const { valid, birthdayDate } = this.state;
    if (valid) {
      const {
        setBirthdayDate,
        sendBirthdayDate,
        insuranceOwner: { personId },
      } = this.props;
      const dateInIso = parseDateToIso(birthdayDate);

      sendBirthdayDate(personId, dateInIso);
      setBirthdayDate(dateInIso);
    }
  };

  isItProfitAgeRange = (inputValue: DateInputValue) => {
    try {
      const { insuranceBegin } = this.props;

      const insuranceBeginDate = moment(
        insuranceBegin.value as string,
        PhvDateFormat.ISO_DATE,
      );
      const inputValueDate = moment(
        `${inputValue.year}-${inputValue.month}-${inputValue.day}`,
        PhvDateFormat.ISO_DATE,
      );

      return insuranceBeginDate.diff(inputValueDate, 'years') <= MAX_PROFIT_AGE;
    } catch (error) {
      Logger.debug(error);
    }
    return false;
  };

  private validateBirthdateOnInsuranceStart(
    currentBirthdayDate: DateInputValue,
  ): string[] {
    const validationErrors: string[] = [];

    const { insuranceBegin } = this.props;
    const insuranceStartDate = parseIsoToDateInput(insuranceBegin.value);

    const adultAt = moment(
      parseDateToIso(currentBirthdayDate),
      'YYYY-MM-DD',
    ).add(18, 'years');
    const insuranceStart = moment(parseDateToIso(insuranceStartDate));

    if (insuranceStart.isBefore(adultAt)) {
      validationErrors.push(
        'Sie müssen bei Vertragsabschluss mindestens 18 Jahre alt sein.',
      );
    }

    return validationErrors;
  }

  render() {
    const { birthdayDate, valid, showErrors, errors, isItProfitAgeRange } =
      this.state;

    const isInvalid = !valid || errors.length > 0;

    return (
      <div className="birthday-date__container">
        <p className="birthday-date__title">Geben Sie Ihr Geburtsdatum ein:</p>

        <div className="birthday-date__input">
          <div className="birthday-date__input-datepicker">
            <DateInput
              id="birthday-date-input"
              data-testid="birthday-date-input"
              value={birthdayDate}
              error={showErrors && isInvalid}
              onChange={this.onChange}
              onBlur={this.onBlur}
              autoTab
            />
          </div>

          {showErrors && isInvalid && (
            <div className="birthday-date__error">
              {Object.values(errors).join('\n')}
            </div>
          )}
        </div>

        {isItProfitAgeRange ? (
          <div>
            <CheckIllustratedIcon className="birthday-date__profit-info-icon" />
            <span className="birthday-date__profit-info-text">
              Sie bekommen den Startbonus und damit 13&nbsp;% Nachlass auf den
              Gesamtbeitrag.
            </span>
          </div>
        ) : (
          <div>
            Wenn Sie bei Versicherungsbeginn jünger sind als 36 Jahre, erhalten
            Sie einen Startbonus von 13&nbsp;%.
          </div>
        )}
      </div>
    );
  }
}

export const BirthdateComponentWithNavigation =
  withNavigation(BirthdateComponent);
