export enum AdobeAnalyticsPages {
  MARITAL_STATUS = 'MARITIAL_STATUS',
  INSURANCE_DATE = 'INSURANCE_DATE',
  BIRTHDATE = 'BIRTHDATE',
  CONTRIBUTION = 'CONTRIBUTION',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PAYMENT_DATA = 'PAYMENT_DATA',
  CHECKOUT = 'CHECKOUT',
  CONSULTATION = 'CONSULTATION',
  THANK_YOU = 'THANK_YOU',
}

export enum AdobeAnalyticsSysEnv {
  PROD = 'prod',
  DEVELOP = 'develop'
}

export function isAdobeAnalyticsSysEnv(test : any): test is AdobeAnalyticsSysEnv {
  return test === AdobeAnalyticsSysEnv.DEVELOP || test === AdobeAnalyticsSysEnv.PROD;
}

export interface AdobeAnalyticsAPI {
  push: (payload: PageLoadEvent | GeneralClickEvent | GeneralClickErrorEvent) => void;
}

export enum AdobeEventTypes {
  PAGE_LOADED = 'PageLoaded',
  GENERAL_CLICK = 'GeneralClick'
}

export interface AEMTrackingProperties {
  pageNames: { [key in AdobeAnalyticsPages]: string; }
  productName: string;
  productPrimaryCategory: string;
}

export type ConversionType =
  'SaleFunnelStart'
  | 'SaleFunnelCalculation'
  | 'SaleFunnelPersonalData'
  | 'SaleFunnelBankData'
  | 'SaleFunnelFinalCheck'
  | 'Sale';

export interface PageLoadEvent {
  event: AdobeEventTypes.PAGE_LOADED;
  page: PageDataLayer;
  product: ProductDataLayer[];
  transaction: TransactionDataLayer;
  user?: UserDataLayer[];
  eventdetails?: {
    clickedElement: string
  }
}

export interface GeneralClickEvent {
  event: AdobeEventTypes.GENERAL_CLICK;
  eventdetails?: {
    clickedElement: string;
    clickType: ClickType;
  }
  miscellaneous?: {
    agentSwitch: {
      oeNumber: string;
    }
  },
  page: {
    attributes: {
      brokerModus: BrokerModus,
    },
  },
}

export interface GeneralClickErrorEvent {
  event: AdobeEventTypes.GENERAL_CLICK,
  eventdetails: {
    clickType: ClickType.OTHER,
  },
  product: [
    {
      attributes: {
        tariffOptions1: string,
      },
    },
  ],
  miscellaneous: {
    errors: {
      errorFlag: boolean,
      errorType: string,
      errorUrl: string,
      errorMessages: string,
    },
  },
  page: {
    attributes: {
      brokerModus: BrokerModus,
    },
  },
}

export enum ClickType {
  OTHER = 'other',
  DOWNLOAD = 'download',
}

export interface TransactionDataLayer {
  transactionID: string,
  attributes?: {
    conversionType?: ConversionType,
    calculatedValue?: string
  },
  total?: {
    transactionTotal: number
  }
}

export interface UserDataLayer {
  profile: {
    attributes: {
      birthday: string,
      gender?: Gender
    },
    address?: {
      city?: string;
      line1?: string;
      line2?: string;
      postalCode?: string;
      country?: string;
    }
    profileInfo?: {
      firstName?:string;
      lastName?: string;
      telephone?:string;
      telephoneE164?:string;
    }
  }
}

export interface ProductDataLayer {
  productInfo: {
    productName: string // AEM
  },
  category: {
    primaryCategory: string, // AEM
  },
  attributes: {
    tariffOptions1: string,
    insuranceStart?: string
  }
}

export interface PageDataLayer {
  pageInfo: {
    pageName: string,
    sysEnv: AdobeAnalyticsSysEnv,
    issueDate: string
  },
  attributes: {
    displayedBrand: 'ERGO',
    brokerModus: BrokerModus,
  },
  category: {
    primaryCategory: string,
    pagePurpose?: string
  },
}

export type Gender = 'Herr' | 'Frau'; // Male | Female

export type BrokerModus = 'On' | 'Off';
