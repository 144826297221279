import { AnyAction } from 'redux';
import { isPlainObject } from 'lodash';
import { AppActionTypes } from '../app/app.actions';
import { getInitialState } from './offer.reducer';
import { isUUID } from '../../commons';
import { OfferActionTypes } from './offer.actions';
import { OfferState } from './offer.interface';

const getSessionStorageKeyForBusinessId = () => {
  const isPhvInIframe = window.location !== window.parent.location;

  if (isPhvInIframe) {
    //makler or broker website
    return (new URL(document.referrer).origin);
  }
  //PHV AWS environments, AEM editor, AEM published evironments websites
  return window.origin;
};

const APP_KEY = 'phv';
const OFFER_STORAGE_KEY = getSessionStorageKeyForBusinessId();

const getCachedData = () => {
  let cachedData;
  try {
    cachedData = JSON.parse(window.sessionStorage.getItem(OFFER_STORAGE_KEY) as string);
    cachedData = isPlainObject(cachedData) ? cachedData : { [APP_KEY]: {} };
  } catch {
    cachedData = { [APP_KEY]: {} };
  }

  return cachedData;
};

export const offerCacheMiddleware = () => (next: Function) => (action: AnyAction) => {
  if (action.type === AppActionTypes.INIT.success) {
    const cachedData = getCachedData();
    const { [APP_KEY]: appCache, ...restCache } = cachedData;

    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem can throw errors
      window.sessionStorage.setItem(OFFER_STORAGE_KEY, JSON.stringify({
        ...restCache,
        [APP_KEY]: {
          businessId: action.payload.offer.businessId,
        },
      }));
    // eslint-disable-next-line no-empty
    } catch {}
  }

  if (action.type === OfferActionTypes.DELETE_OFFER_ID) {
    const cachedData = getCachedData();
    const cachedKeys = Object.keys(cachedData);
    const hasAppKey = cachedKeys.includes(APP_KEY);
    const hasOnlyAppKey = hasAppKey && cachedKeys.length === 1;
    const shouldRemoveItem = !cachedKeys.length || hasOnlyAppKey;

    if (shouldRemoveItem) {
      window.sessionStorage.removeItem(OFFER_STORAGE_KEY);
    } else {
      try {
        const { [APP_KEY]: appCache, ...restCache } = cachedData;
        // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem can throw errors
        window.sessionStorage.setItem(OFFER_STORAGE_KEY, JSON.stringify(restCache));
      } catch {
        window.sessionStorage.removeItem(OFFER_STORAGE_KEY);
      }
    }
  }

  next(action);
};

// IMPORTANT
//
// entire state has to be filled with default values, because if a part of offer state would be passed to
// redux.createStore as persisted state, it would have ONLY fields available in passed offer state partial
export const getOfferFromCache = (): OfferState => {
  const defaultsToMerge = getInitialState();

  try {
    const cachedData = getCachedData();
    const cachedAppData = cachedData[APP_KEY];
    const businessId = cachedAppData?.businessId ?? '';

    return {
      ...defaultsToMerge,
      businessId: isUUID(businessId) ? businessId : '',
    };
  } catch {
    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem can throw errors
      window.sessionStorage.setItem(OFFER_STORAGE_KEY, '{}');
    // eslint-disable-next-line no-empty
    } catch {}

    return {
      ...defaultsToMerge,
      businessId: '',
    };
  }
};
