import { RootState } from '../../store/store.interface';
import { Person, PersonRole, PersonsStateErrors } from './persons.interface';

export const getPersons = (state: RootState) => state.entities.persons;

export const getPersonsData = (state: RootState) => getPersons(state).data;

export const getPersonsByRole = (state: RootState, role: PersonRole): Person[] => getPersonsData(state).filter(
  (person: Person) => person.roles.includes(role),
);

export const getInsurancePartner = (state: RootState) => getPersonsData(state)[1];

export const getInsuredPersons = (state: RootState) => getPersonsData(state).slice(2);

export const getInsuredPersonsIds = (state: RootState) => getInsuredPersons(state).map(
  (person: Person) => person.personId,
);

export const getPersonsByIds = (state: RootState, personIds: Array<Person['personId']>): Person[] => getPersonsData(state).filter(
  (person: Person) => personIds.includes(person.personId),
);

export const getInsuranceOwner = (state: RootState) => getPersonsByRole(state, PersonRole.INSURANCE_OWNER)[0];

export const getInsuranceOwnerPersonId = (state: RootState) => getInsuranceOwner(state).personId;

export const getInsuranceOwnerFullName = (state: RootState): string => {
  const { firstName, lastName } = getInsuranceOwner(state) || {};
  return [firstName, lastName].join(' ').trim();
};

export const isLoading = (state: RootState): boolean => getPersons(state).loading;

export const getErrors = (state: RootState): PersonsStateErrors[] => getPersons(state).errors;
