import { ajax } from 'rxjs/ajax';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { PaymentDataActionTypes } from './payment-data.actions';
import { RootState } from '../../store/store.interface';
import { getUpdatePaymentData } from './payment-data.selectors';
import { backendUrl } from '../../config';

export const onSendPostPaymentData: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(PaymentDataActionTypes.UPDATE_PAYMENT_DATA.start),
  filter(() => !getUpdatePaymentData(state$.value).id),
  mergeMap(() => ajax.post(`${backendUrl}/api/phv/payment-store`, {
    ...getUpdatePaymentData(state$.value),
  }, {
    'Content-Type': 'application/json',
  }).pipe(
    map((data) => ({
      type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA.success,
      payload: {
        id: data.response.id,
        accountId: data.response.accountId,
      },
    })),
    catchError((error) => of({
      type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA.error,
      payload: {
        error,
      },
    })),
  )),
);

export const onSendPutPaymentData: Epic<AnyAction, AnyAction, RootState> = (action$, state$) => action$.pipe(
  ofType(PaymentDataActionTypes.UPDATE_PAYMENT_DATA.start),
  filter(() => !!getUpdatePaymentData(state$.value).id),
  mergeMap(() => ajax.put(`${backendUrl}/api/phv/payment-store`, {
    ...getUpdatePaymentData(state$.value),
  }, {
    'Content-Type': 'application/json',
  }).pipe(
    map((data) => ({
      type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA.success,
      payload: {
        id: data.response.id,
        accountId: data.response.accountId,
      },
    })),
    catchError((error) => of({
      type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA.error,
      payload: {
        error,
      },
    })),
  )),
);
