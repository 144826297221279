import Button from '@eg/elements/Button';
import React, { useEffect, useState } from 'react';
import {
  ConsultancyProtocolId,
  ConsultancyProtocolIdValues,
  OfferStateData,
} from '../../entities/offer/offer.interface';
import { GeneralClickedElement, GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import './makler-consultation.component.scss';

interface MaklerConsultationProps {
  isMaklerFlow: boolean;
  downloadDocumentLink: string;
  consultancyProtocolId: OfferStateData['consultancyProtocolId']['value'];

  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => void;
  validateOffer: () => void;
  showButton: (yesNo: boolean) => void;
  setPreviousStep: (uri: RoutePath, isExternal?: boolean) => void;
  deleteOfferId: () => void;
  sendConsultancyProtocolId: (value: ConsultancyProtocolId) => void;
}

const MaklerConsultationComponent: React.FC<MaklerConsultationProps> = ({
  isMaklerFlow,
  downloadDocumentLink,
  consultancyProtocolId,

  trackGeneralClick,
  validateOffer,
  showButton,
  setPreviousStep,
  deleteOfferId,
  sendConsultancyProtocolId,
}: MaklerConsultationProps) => {
  const [allowFileDownload, setAllowFileDownload] = useState<boolean>(false);

  useEffect(() => {
    showButton(false);
    setPreviousStep(RoutePath.CHECKOUT);

    // makler flow should always set consultancy protocol ID to 'BERATUNGSVERZICHT'
    if (!consultancyProtocolId) {
      sendConsultancyProtocolId(ConsultancyProtocolIdValues.BERATUNGSVERZICHT);
    }
  }, []);

  useEffect(() => {
    if (allowFileDownload) {
      setAllowFileDownload(false);
      window.open(downloadDocumentLink, '_blank', 'noreferrer');
      deleteOfferId();
    }
  }, [allowFileDownload]);

  const onFileDownload = () => {
    setAllowFileDownload(true);
    trackGeneralClick(GeneralClickedElement.BUTTON_IDDDOWNLOAD);
    validateOffer();
  };

  return (
    <div className="esc_container--l makler-conslutation__container">
      <h2 className="makler-conslutation__page-title">
        Laden Sie jetzt die Angebotsberechnung herunter.
      </h2>

      <p className="makler-conslutation__page-description">
        Die Seite 2 des PDF der Angebotsberechnung (Abschlussübersicht) kann als Deckungsnote unter {' '}
        <a href="mailto:deckungsnoten@ergo.de">deckungsnoten@ergo.de</a> {' '}
        mit der Angabe Ihrer Vermittlernummer eingereicht werden. Bitte beachten Sie Ihre {' '}
        Verpflichtungen zur sicheren Datenübermittlung.
      </p>

      <Button
        className="makler-conslutation__pdf-button"
        onClick={onFileDownload}
        disabled={!isMaklerFlow}
        variant="primary"
        size="large"
      >
        PDF Angebotsberechnung herunterladen
      </Button>

    </div>
  );
};

export const MaklerConsultationComponentWithNavigation = withNavigation(MaklerConsultationComponent);
