import { RootState } from '../../store/store.interface';
import { getInsuranceOwner, getInsuranceOwnerFullName } from '../persons/persons.selectors';
import { getBusinessId } from '../offer/offer.selectors';
import { PaymentDataDto } from './payment-data.interface';

export const isLoading = (state: RootState) => state.entities.paymentData.loading;

export const getPaymentData = (state: RootState) => state.entities.paymentData;

export const getUpdatePaymentData = (state: RootState): PaymentDataDto => {
  const {
    id, accountId, iban, bic,
  } = getPaymentData(state).data;

  return {
    bic,
    id,
    businessId: getBusinessId(state),
    iban,
    accountOwner: getInsuranceOwnerFullName(state),
    accountId,
    personId: getInsuranceOwner(state)?.personId,
  };
};
