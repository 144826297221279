import { Person, PersonValidationRules } from './persons.interface';
import { generateApiActions } from '../../store/api-actions.helper';

export const PersonsActionTypes = {
  PERSONAL_DATA_ERROR: '@persons/PERSONAL_DATA_ERROR',
  UPDATE_PERSON: '@persons/UPDATE_PERSON',
  UPDATE_INSURED_RELATIVE: '@persons/UPDATE_INSURED_RELATIVE',
  UPDATE_INSURED_OFFICIAL: '@persons/UPDATE_INSURED_OFFICIAL',
  PUT_PERSON: generateApiActions('@persons/PUT_PERSON'),
  PUT_PERSON_BIRTHDAY_DATE: generateApiActions('@persons/PUT_PERSON_BIRTHDAY_DATE'),
  POST_ADDITIONAL_PERSON: generateApiActions('@persons/POST_ADDITIONAL_PERSON'),
};

const updatePerson = (person: Partial<Person>) => ({
  type: PersonsActionTypes.UPDATE_PERSON,
  payload: {
    person,
  },
});

const sendPerson = (person: Partial<Person>, validationRules: PersonValidationRules[]) => ({
  type: PersonsActionTypes.PUT_PERSON.start,
  payload: {
    person,
    validationRules,
  },
});

const sendUpdateAdditionalPerson = (personId: Person['personId'], delta: Partial<Person>) => ({
  type: PersonsActionTypes.POST_ADDITIONAL_PERSON.start,
  payload: {
    personId,
    ...delta,
  },
});

const sendPersonBirthdayDate = (personId: string, birthDate: string) => ({
  type: PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.start,
  payload: {
    personId,
    birthDate,
  },
});

const updateInsuredRelative = (personId: string, delta: Partial<Person>) => ({
  type: PersonsActionTypes.UPDATE_INSURED_RELATIVE,
  payload: {
    personId,
    ...delta,
  },
});

const updateInsuredOfficial = (personId: string, delta: Partial<Person>) => ({
  type: PersonsActionTypes.UPDATE_INSURED_OFFICIAL,
  payload: {
    personId,
    ...delta,
  },
});

const personalDataError = (error: Error) => ({
  type: PersonsActionTypes.PERSONAL_DATA_ERROR,
  payload: {
    error,
  },
});

export const personsActions = {
  updatePerson,
  sendPerson,
  sendPersonBirthdayDate,
  sendUpdateAdditionalPerson,
  updateInsuredRelative,
  updateInsuredOfficial,
  personalDataError,
};
