import { combineEpics } from 'redux-observable';
import { onAppInitEpic, onAppInitLoadEpic, onOfferNotFoundErrorEpic } from '../entities/app/app.epic';
import {
  onGetOfferEpic, onPutOfferEpic, onSubmitOfferEpic, onValidateOfferEpic,
} from '../entities/offer/offer.epic';
import {
  onPutPersonBirthdayDateEpic,
  onPutPersonEpic,
  onSendAdditionalPersonEpic,
} from '../entities/persons/persons.epic';
import { onSendPostPaymentData, onSendPutPaymentData } from '../entities/payment-data/payment-data.epic';
import { onCreateAgencyEpic } from '../entities/agency/agency.epic';
import { onElementClickedEpic, onPageLoadedEpic, onTrackingInitializedEpic } from '../entities/tracking/tracking.epic';

export const rootEpic = combineEpics(
  onAppInitEpic,
  onAppInitLoadEpic,
  onOfferNotFoundErrorEpic,
  onGetOfferEpic,
  onPutOfferEpic,
  onValidateOfferEpic,
  onSubmitOfferEpic,
  onSendPostPaymentData,
  onSendPutPaymentData,
  onPutPersonEpic,
  onPutPersonBirthdayDateEpic,
  onSendAdditionalPersonEpic,
  onCreateAgencyEpic,
  onPageLoadedEpic,
  onElementClickedEpic,
  onTrackingInitializedEpic,
);
