import React, { useEffect, useState } from 'react';
import { PHV_ERGO_ELEMENTS_THEME, personalDataBackendUrl, personalDataBundleUrl } from '../../../config';
import { OfferStateData } from '../../../entities/offer/offer.interface';
import { Person, PersonValidationContext, PersonValidationRules } from '../../../entities/persons/persons.interface';
import { BundleLoader } from '../../bundle-loader/bundle-loader.component';
import { BundleParcelElement } from '../../bundle-loader/bundle-loader.interface';
import { Logger } from '../../../commons/logger';
import { HideableFieldName } from './personal-data-microfront.type';

interface PersonalDataMicrofrontProps {
  businessId: string;
  personId: string;
  insuranceStartDateISO: OfferStateData['insuranceStartDate']['value'];

  onInit: (submitCallback: () => void) => void;
  onError: (error: Error) => void;
  onSave: (person: Person, validationErrors: Array<string>) => void;
  onLoadingChange: (isLoading: boolean) => void;
  onValidChange: (isValid: boolean) => void;
}

enum PersonalDataEvent {
  ON_LOAD = 'onLoad',
  ON_UPDATE = 'onUpdate',
  ON_CHANGE = 'onChange',
  ON_ERROR = 'onError',
}

const validationRules: PersonValidationRules[] = [
  PersonValidationRules.NAME_COMPLETE,
  PersonValidationRules.BIRTHDATE_AVAILABLE,
  PersonValidationRules.ADDRESS_AVAILABLE,
  PersonValidationRules.ADDRESS_AUTOCORRECT,
];

const theme = PHV_ERGO_ELEMENTS_THEME;
const bundleUrl = personalDataBundleUrl;
const backendUrl = personalDataBackendUrl;

export const PersonalDataMicrofrontComponent: React.FC<PersonalDataMicrofrontProps> = ({
  businessId,
  personId,
  insuranceStartDateISO,
  onValidChange,
  onInit,
  onError,
  onSave,
  onLoadingChange,
}: PersonalDataMicrofrontProps) => {
  const [microfrontRef, setMicrofrontRef] = useState<React.RefObject<BundleParcelElement>>(React.createRef());
  const [showComponent, setShowComponent] = useState<boolean>(false);

  const onEvent = (newEvent: CustomEvent) => {
    const { detail, type } = newEvent;

    const currentIsValid = detail?.isValid ?? true;
    const currentValidationErrors = detail?.errors?.map((err: any) => err.errorMessage) ?? [];

    switch (type) {
      case PersonalDataEvent.ON_LOAD:
        onLoadingChange(false);
        onValidChange(currentIsValid);
        onInit(() => {
          microfrontRef.current?.el.dispatchEvent(new CustomEvent('submit'));
        });
        break;

      case PersonalDataEvent.ON_CHANGE:
        onValidChange(currentIsValid);
        break;

      case PersonalDataEvent.ON_UPDATE:
        onLoadingChange(false);
        onSave(detail.person, currentValidationErrors);
        break;

      case PersonalDataEvent.ON_ERROR:
        onLoadingChange(false);
        onError(detail.error);
        break;

      default:
        Logger.log(`Unexpected event type: ${type}`);
    }
  };

  const addEventsListeners = () => {
    const el = microfrontRef.current?.el;

    el?.addEventListener(PersonalDataEvent.ON_LOAD, onEvent as EventListener);
    el?.addEventListener(PersonalDataEvent.ON_UPDATE, onEvent as EventListener);
    el?.addEventListener(PersonalDataEvent.ON_CHANGE, onEvent as EventListener);
    el?.addEventListener(PersonalDataEvent.ON_ERROR, onEvent as EventListener);
  };

  const removeEventsListeners = () => {
    const el = microfrontRef.current?.el;

    el?.removeEventListener(PersonalDataEvent.ON_LOAD, onEvent as EventListener);
    el?.removeEventListener(PersonalDataEvent.ON_UPDATE, onEvent as EventListener);
    el?.removeEventListener(PersonalDataEvent.ON_CHANGE, onEvent as EventListener);
    el?.removeEventListener(PersonalDataEvent.ON_ERROR, onEvent as EventListener);
  };

  const parcelDidMount = (ref: React.RefObject<BundleParcelElement>) => {
    setMicrofrontRef(ref);
  };

  useEffect(() => {
    if (!showComponent && microfrontRef.current?.el) {
      removeEventsListeners();
    } else if (showComponent && microfrontRef.current?.el) {
      addEventsListeners();
    }
  }, [showComponent, microfrontRef]);

  useEffect(() => {
    setShowComponent(!!(backendUrl && businessId && personId));
  }, [backendUrl, businessId, personId]);

  const validationContext: PersonValidationContext = {
    insuranceStartDate: insuranceStartDateISO,
  };

  const customProps = {
    validationContext,
    validationRules,
    backendUrl,
    businessId,
    personId,
    theme,
    hiddenFields: Object.values(HideableFieldName)
  };

  return (
    <BundleLoader
      id="personal-data"
      bundleUrl={bundleUrl}
      customProps={customProps}
      parcelDidMount={parcelDidMount}
    />
  );
};
