import Select from '@eg/elements/Select';
import React, { ChangeEvent } from 'react';
import TooltipIcon, { onOpen } from '../tooltip-icon/tooltip-icon';
import { OfferStateData, PhvPaymentMethod } from '../../entities/offer/offer.interface';
import { ContributionTooltips } from '../contribution/contribution-tooltips';
import './agreement-options.component.scss';
import { AgreementOptionsProps, OWN_CONTRIBUTION_OPTIONS_MAP } from './agreement-options.interface';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';

const mapOwnContributionOptions = (options: string[]) => (
  options.map((option: string) => ({
    value: option,
    label: OWN_CONTRIBUTION_OPTIONS_MAP[option],
  }))
);

const paymentIntervalLongName = (value: OfferStateData['paymentMethod']['value']): string => {
  switch (value) {
    case PhvPaymentMethod.VIERTELJAEHRLICH:
      return 'vierteljährlich';

    case PhvPaymentMethod.HALBJAEHRLICH:
      return 'halbjährlich';

    case PhvPaymentMethod.JAEHRLICH:
      return 'jährlich';

    case PhvPaymentMethod.MONATLICH:
    default:
      return 'monatlich';
  }
};

export const AgreementOptionsComponent = ({
  selectedVariantName,
  ownContribution,
  durationOfContract,
  paymentMethod,
  onChangeField,
  onChangeVariantField,
  trackGeneralClick,
}: AgreementOptionsProps) => (
  <div className="contribution__container__agreement-options esc_container--l">
    {/* TODO: Clarify: Should we select some variant by default ? */ }
    <div className="agreement-option">
      { selectedVariantName && (
      <>
        <div className="agreement-option-label">
          Selbstbeteiligung
          <TooltipIcon
            onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_SELBSTBETEILIGUNG))}
          >{ ContributionTooltips.OWN_CONTRIBUTION }
          </TooltipIcon>
        </div>
        <Select
          data-testid="agreement-ownContribution-select"
          value={ownContribution[selectedVariantName].value as string}
          onChange={(event: ChangeEvent) => onChangeVariantField(event)('ownContribution', selectedVariantName)}
        >
          { mapOwnContributionOptions(ownContribution[selectedVariantName].options).map(
            ({ value, label }) => <option key={value} value={value}>{ label }</option>,
          ) }
        </Select>
      </>
      ) }
    </div>
    <div className="agreement-option">
      <div className="agreement-option-label">
        Vertragslaufzeit
        <TooltipIcon
          onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_VERTRAGSLAUFZEIT))}
        >{ ContributionTooltips.DURATION_OF_CONTRACT }
        </TooltipIcon>
      </div>
      <Select
        data-testid="agreement-durationOfContract-select"
        value={durationOfContract.value as string}
        onChange={(event: ChangeEvent) => onChangeField(event)('durationOfContract')}
      >
        { durationOfContract.options.map(
          (value: string) => <option key={value} value={value}>{`${value} ${(Number(value) > 1) ? 'Jahre' : 'Jahr'}`}</option>,
        ) }
      </Select>
    </div>
    <div className="agreement-option">
      <div className="agreement-option-label">
        Zahlungsweise
        <TooltipIcon
          onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_ZAHLUNGSWEISE))}
        >{ ContributionTooltips.PAYMENT_METHOD }
        </TooltipIcon>
      </div>
      <Select
        data-testid="agreement-paymentMethod-select"
        value={paymentMethod.value as string}
        onChange={(event: ChangeEvent) => onChangeField(event)('paymentMethod')}
      >
        { paymentMethod.options.map(
          (value: string) => <option key={value} value={value}>{ paymentIntervalLongName(value) }</option>,
        ) }
      </Select>
    </div>
  </div>
);
