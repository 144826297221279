import Button from '@eg/elements/Button';
import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import MessageBox from '@eg/elements/MessageBox';
import { ConsultancyProtocolIdValues } from '../../entities/offer/offer.interface';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import './consultation.component.scss';
import { ConsultationProps } from './consultation.interface';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';

const consultancyProtocolConsentWarning = 'Bitte bestätigen Sie, dass Sie für diesen Abschluss keine Beratung benötigen.';

const ConsultationComponent = ({
  consultancyProtocolId,
  sendConsultancyProtocolId,
  setPreviousStep,
  showButton,
  goToNextStep,
  summaryErrors,
  summaryReceived,
  validateOffer,
  submitOffer,
  downloadDocumentLink,
  trackPageLoaded,
  trackGeneralClick,
}: ConsultationProps) => {
  const [allowFileDownload, setAllowFileDownload] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [sendOffer, setSendOffer] = useState(false);

  useEffect(() => {
    showButton(false);
    setPreviousStep(RoutePath.CHECKOUT);
    trackPageLoaded();
  }, []);

  useEffect(() => {
    if (allowFileDownload && summaryReceived && summaryErrors.length === 0) {
      setFileDownloaded(true);
      setAllowFileDownload(false);
      window.open(downloadDocumentLink, '_blank', 'noreferrer');
    }
  }, [summaryReceived, summaryErrors]);

  useEffect(() => {
    if (sendOffer && summaryReceived && summaryErrors.length === 0) {
      goToNextStep(RoutePath.THANK_YOU);
    }
  }, [summaryReceived, summaryErrors]);

  const changeConsultancyProtocolId = ({ target }: ChangeEvent) => {
    const isConsultancyProtocolIdChecked = (target as HTMLInputElement).checked;
    sendConsultancyProtocolId(isConsultancyProtocolIdChecked ? ConsultancyProtocolIdValues.BERATUNGSVERZICHT : null);
    trackGeneralClick(isConsultancyProtocolIdChecked
      ? GeneralClickedElement.CHECKBOX_BERATUNGSVERZICHT_ACTIVE : GeneralClickedElement.CHECKBOX_BERATUNGSVERZICHT_INACTIVE);
  };

  const getConsultancyProtocolConsentWarning = () => !consultancyProtocolId.value && fileDownloaded && consultancyProtocolConsentWarning;

  const onSubmitOffer = () => {
    setSendOffer(true);
    submitOffer();
  };

  const onFileDownload = () => {
    setAllowFileDownload(true);
    trackGeneralClick(GeneralClickedElement.BUTTON_IDDDOWNLOAD);
    validateOffer();
  };

  const isSubmitButtonDisabled = !consultancyProtocolId.value || !fileDownloaded;
  // eslint-disable-next-line no-nested-ternary
  const submitButtonVariant = isSubmitButtonDisabled
    ? 'disabled'
    : !fileDownloaded ? 'tertiary' : 'primary';
  // eslint-disable-next-line no-nested-ternary
  const downloadtButtonVariant = !consultancyProtocolId.value
    ? 'disabled'
    : fileDownloaded ? 'tertiary' : 'primary';

  return (
    <div className="esc_container--l consultation__container">
      <h2 className="consultation__page-title">
        Schließen Sie Ihren Vertrag jetzt online ab.
      </h2>
      <p className="consultation__page-description">
        Mit dem Klick auf &quot;Online abschließen&quot; werden Ihre Vertragsdaten verbindlich an die
        ERGO Versicherung AG übertragen.
      </p>

      <div>
        <div className="consultation__border-box">
          <div className="consultation__border-box-with-lead">
            <p className="consultation__section-title">EU-Vorgabe: Beratungsverzicht</p>
            <p>
              Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet:
            </p>
            <p>
              Wir müssen Sie vorher beraten und dies dokumentieren. Sie können auch schnell und dafür ohne Beratung
              abschließen. Dann brauchen wir vor dem Abschluss Ihren Beratungsverzicht.
            </p>
          </div>
          <div className="consultation__border-box-with-checkbox">
            <ControlWithHint error={getConsultancyProtocolConsentWarning()}>
              <Checkbox
                checked={!!consultancyProtocolId.value}
                onChange={changeConsultancyProtocolId}
                label={(
                  <span className="consultation__checkobox-label">Ich verzichte für diesen
                    Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies
                    kann sich evtl. auf die Geltendmachung von
                    Schadensersatzansprüchen nachteilig auswirken.
                  </span>
                )}
              />
            </ControlWithHint>
          </div>
        </div>

        <div className="esc_container--l consultation__steps-section">
          <h3>Vertragsunterlagen</h3>
          <p>
            Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen und Informationen als PDF und schließen
            Sie dann online ab.
          </p>

          <Button
            onClick={onFileDownload}
            disabled={!consultancyProtocolId.value}
            variant={downloadtButtonVariant}
            size="large"
            iconRight={(fileDownloaded) ? CheckIcon : undefined}
            className={classNames({
              'consultation__step-button': !fileDownloaded,
              'consultation__step-clicked-button': fileDownloaded,
            })}
          >
            <div className="consultation__button">
              <span className="consultation__button-nr">1</span>
              Herunterladen
            </div>
          </Button>

          <h3>Zahlungspflichtiger Vertrag</h3>
          <p>Bei Vertragsabschluss fallen für Sie keine weiteren Kosten an.</p>

          <div className="consultation__submit-offer-info">
            { (summaryErrors && summaryErrors.length > 0) && (
              <MessageBox type="error">
                { summaryErrors.map((err) => (
                  <div key={err.code}>{ err.text || err.code }</div>
                )) }
              </MessageBox>
            ) }
          </div>

          <Button
            variant={submitButtonVariant}
            size="large"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmitOffer}
            className="consultation__step-button"
          >
            <div className="consultation__button">
              <span className="consultation__button-nr">2</span>
              Online abschließen
            </div>
          </Button>
        </div>

      </div>

    </div>
  );
};

export const ConsultationComponentWithNavigation = withNavigation(ConsultationComponent);
