import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { paymentDataActions } from '../../entities/payment-data/payment-data.actions';
import { PaymentDataInfo } from '../../entities/payment-data/payment-data.interface';
import { getPaymentData } from '../../entities/payment-data/payment-data.selectors';
import {
  getErrors as getInsuranceOwnerErrors,
  getInsuranceOwnerFullName,
} from '../../entities/persons/persons.selectors';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { RootState } from '../../store/store.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { PaymentDataComponentWithNavigation } from './payment-data.component';

const mapStateToProps = (state: RootState) => ({
  paymentData: getPaymentData(state),
  insuranceOwnerFullName: getInsuranceOwnerFullName(state),
  insuranceOwnerErrors: getInsuranceOwnerErrors(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateField: (paymentData: Partial<PaymentDataInfo>) => dispatch(paymentDataActions.updatePaymentDataField(paymentData)),
  sendPaymentData: () => dispatch(paymentDataActions.sendPaymentData()),
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.PAYMENT_DATA)),
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(
    generalClickElementType,
  )),
});

export const PaymentData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentDataComponentWithNavigation);
