import { combineReducers } from 'redux';
import { offerReducer } from './offer/offer.reducer';
import { appReducer } from './app/app.reducer';
import { personsReducer } from './persons/persons.reducer';
import { paymentDataReducer } from './payment-data/payment-data.reducer';
import { aemPropertiesReducer } from './aem/aem-properties.reducer';

export default combineReducers({
  app: appReducer,
  offer: offerReducer,
  persons: personsReducer,
  paymentData: paymentDataReducer,
  aemProperties: aemPropertiesReducer,
});
