import { RouteComponentProps } from 'react-router-dom';
import {
  OfferStateData,
  SelectedVariantUpdatePayload,
  UpdateVariantFieldPayload,
  VariantsNames,
} from '../../entities/offer/offer.interface';
import { Person } from '../../entities/persons/persons.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { RoutePath } from '../app/app.interface';
import { PhvMaritalStatus } from '../marital-status/marital-status.interface';

export type ContributionProps = RouteComponentProps & {
  maritalStatus: PhvMaritalStatus;
  insuredPersonBirthdate?: string
  insuranceStartDate?: string;
  // @TODO ADD TYPE HERE
  variants: any;
  productTableUrl: string;
  pdfUrl: string;
  selectedVariantName: VariantsNames;
  keyLoss: OfferStateData['keyLoss'];
  initialPriceBased: OfferStateData['initialPriceBased'];
  unpaidDebtInsurance: OfferStateData['unpaidDebtInsurance'];
  insuredRelatives: OfferStateData['insuredRelatives'];
  insuredOfficials: OfferStateData['insuredOfficials'];
  insuranceOwner: Person;
  insurancePartner: Person;
  insuredPersonsIds: Person['personId'][];
  updateFieldSelectedVariant: (updateVariantValue: SelectedVariantUpdatePayload) => void;
  showButton: (showButton: boolean) => void;
  setPreviousStep: (uri: RoutePath, isExternal?: boolean) => void;
  setNextStep: (uri: RoutePath) => void;
  setCanGoToNextStep: (yesNo: boolean) => void;
  goToNextStep: (uri: RoutePath) => void;
  sendFieldSelectedVariant: (updateVariantValue: SelectedVariantUpdatePayload) => void;
  updateVariantField: (fieldPayload: UpdateVariantFieldPayload) => void;
  sendVariantField: (variantFieldPayload: UpdateVariantFieldPayload) => void;
  trackPageLoaded: () => void;
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => void;
};

export enum InsuredOfficialsRadioValues {
  ME = 'me',
  ME_AND_MY_PARTNER = 'me-and-my-partner',
}
