import React, { FunctionComponent, ReactElement } from 'react';
import TooltipIcon from '../tooltip-icon/tooltip-icon';

interface TooltipWrapperProps {
  tooltip: string | ReactElement;
  onOpen?: Function;
}

export const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({ tooltip, children, onOpen }: React.PropsWithChildren<TooltipWrapperProps>) => (
  <div className="esc_grid">
    <div className="esc_grid__wrapper">
      <div className="esc_col esc_col-m-6 esc_col-m--offset-2 esc_col-10">
        { children }
      </div>
      <div className="esc_col esc_col-m-4  esc_col-2 i-icon">
        <TooltipIcon onToggledOpen={(isOpen: boolean) => {
          if (isOpen) {
            onOpen?.();
          }
        }}
        >{ tooltip }
        </TooltipIcon>
      </div>
    </div>
  </div>
);
