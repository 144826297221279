import { generateApiActions } from '../../store/api-actions.helper';
import { PaymentDataInfo } from './payment-data.interface';

export const PaymentDataActionTypes = {
  UPDATE_PAYMENT_DATA: generateApiActions('@offer/UPDATE_PAYMENT_DATA'),

  UPDATE_PAYMENT_DATA_FIELD: '@offer/UPDATE_PAYMENT_DATA_FIELD',
};

const sendPaymentData = () => ({
  type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA.start,
});

const updatePaymentDataField = (updatePaymentDataValues?: Partial<PaymentDataInfo>) => ({
  type: PaymentDataActionTypes.UPDATE_PAYMENT_DATA_FIELD,
  payload: updatePaymentDataValues,
});

export const paymentDataActions = {
  updatePaymentDataField,
  sendPaymentData,
};
