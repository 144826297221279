import { generateApiActions } from '../../store/api-actions.helper';
import {
  OfferStateData,
  SelectedVariantUpdatePayload,
  UpdateFieldPayload,
  UpdateVariantFieldPayload,
} from './offer.interface';

export const OfferActionTypes = {
  GET_OFFER: generateApiActions('@offer/GET_OFFER'),
  PUT_OFFER: generateApiActions('@offer/PUT_OFFER'),
  VALIDATE_OFFER: generateApiActions('@offer/VALIDATE_OFFER'),
  SUBMIT_OFFER: generateApiActions('@offer/SUBMIT_OFFER'),

  UPDATE_FIELD: '@offer/UPDATE_FIELD',
  UPDATE_VARIANT_FIELD: '@offer/UPDATE_VARIANT_FIELD',
  SET_MARITAL_STATUS: '@offer/SET_MARITAL_STATUS',
  SET_BIRTHDAY_DATE: '@offer/SET_BIRTHDAY_DATE',
  UPDATE_FIELD_SELECT_VARIANT: '@offer/UPDATE_FIELD_SELECT_VARIANT',
  DELETE_OFFER_ID: '@offer/DELETE_OFFER_ID',
};

const getByBusinessId = (businessId: string) => ({
  type: OfferActionTypes.GET_OFFER.start,
  payload: { businessId },
});

const setSelectedMaritalStatus = (newMaritalStatus: OfferStateData['selectedMaritalStatus']) => ({
  type: OfferActionTypes.SET_MARITAL_STATUS,
  payload: newMaritalStatus,
});

const updateField = (fieldPayload: UpdateFieldPayload) => ({
  type: OfferActionTypes.UPDATE_FIELD,
  payload: fieldPayload,
});

const updateVariantField = (fieldPayload: UpdateVariantFieldPayload) => ({
  type: OfferActionTypes.UPDATE_VARIANT_FIELD,
  payload: fieldPayload,
});

const updateFieldSelectedVariant = (selectedVariantUpdatePayload: SelectedVariantUpdatePayload) => ({
  type: OfferActionTypes.UPDATE_FIELD_SELECT_VARIANT,
  payload: selectedVariantUpdatePayload,
});

const sendFieldSelectedVariant = (selectedVariantUpdatePayload: SelectedVariantUpdatePayload) => ({
  type: OfferActionTypes.PUT_OFFER.start,
  payload: {
    fieldName: 'selected',
    ...selectedVariantUpdatePayload,
  },
});

const sendField = (fieldPayload: UpdateFieldPayload) => ({
  type: OfferActionTypes.PUT_OFFER.start,
  payload: {
    ...fieldPayload,
  },
});

const sendVariantField = (variantFieldPayload: UpdateVariantFieldPayload) => ({
  type: OfferActionTypes.PUT_OFFER.start,
  payload: {
    ...variantFieldPayload,
  },
});

const sendMaritalStatus = (newMaritalStatus: OfferStateData['selectedMaritalStatus']) => ({
  type: OfferActionTypes.PUT_OFFER.start,
  payload: {
    fieldName: 'versicherterPersonenkreis',
    value: newMaritalStatus,
  },
});

const validateOffer = () => ({
  type: OfferActionTypes.VALIDATE_OFFER.start,
});

const deleteOfferId = () => ({
  type: OfferActionTypes.DELETE_OFFER_ID,
});

const submitOffer = () => ({
  type: OfferActionTypes.SUBMIT_OFFER.start,
});

export const offerActions = {
  deleteOfferId,
  getByBusinessId,
  setSelectedMaritalStatus,
  updateField,
  updateFieldSelectedVariant,
  sendFieldSelectedVariant,
  sendMaritalStatus,
  updateVariantField,
  sendField,
  sendVariantField,
  validateOffer,
  submitOffer,
};
