import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions } from '../../entities/offer/offer.actions';
import { getInsuranceStartDate } from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { InsuranceStartDateComponentWithNavigation } from './insurance-start-date.component';
import { insuranceStartDateOptions } from './insurance-start-date.helper';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';

const mapStateToProps = (state: RootState) => ({
  insuranceStartDate: getInsuranceStartDate(state),
  insuranceStartOptions: insuranceStartDateOptions(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setInsuranceStartDate: (insuranceStartDate: string) => {
    dispatch(
      offerActions.updateField({
        fieldName: 'insuranceStartDate',
        value: insuranceStartDate,
      }),
    );
  },

  sendInsuranceStartDate: (insuranceStartDate: string) => {
    dispatch(
      offerActions.sendField({
        fieldName: 'insuranceStartDate',
        value: insuranceStartDate,
      }),
    );
  },

  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.INSURANCE_DATE)),
});

export const InsuranceStartDate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsuranceStartDateComponentWithNavigation);
