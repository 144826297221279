interface ApiActionsHelper {
  start: string;
  success: string;
  error: string;
}

export const generateApiActions = (actionKey: string): ApiActionsHelper => ({
  start: `${actionKey}_START`,
  success: `${actionKey}_SUCCESS`,
  error: `${actionKey}_ERROR`,
});
