import {
  AnyAction, applyMiddleware, compose, createStore,
} from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { getAppInitParams, appErrorMiddleware } from '../entities/app/app.middleware';
import { getOfferFromCache, offerCacheMiddleware } from '../entities/offer/offer.middleware';
import { rootEpic } from './root.epic';
import { rootReducer } from './root.reducer';
import { RootState } from './store.interface';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

const middlewares = [
  epicMiddleware,
  appErrorMiddleware,
  offerCacheMiddleware,
];

export const persistedState: Partial<RootState> = {
  // @ts-ignore
  entities: {
    offer: getOfferFromCache(),
    app: getAppInitParams(),
  },
};

const store = createStore(
  rootReducer(),
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

epicMiddleware.run(rootEpic);

export default store;
