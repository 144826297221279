import { connect } from 'react-redux';
import {
  getBirthdayDate,
  getCurrentPriceBrutto,
  getInsuranceStartDate,
  getPaymentMethod,
  getSelectedVariantName,
} from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { PriceHeaderComponent } from './price-header.component';

const mapStateToProps = (state: RootState) => ({
  currentPriceBrutto: getCurrentPriceBrutto(state),
  insuredPersonBirthdate: getBirthdayDate(state)?.value,
  insuranceStartDate: getInsuranceStartDate(state)?.value,
  selectedVariantName: getSelectedVariantName(state),
  paymentMethod: getPaymentMethod(state)?.value,
});

export const PriceHeader = connect(
  mapStateToProps,
)(PriceHeaderComponent);
