import moment from 'moment';
import { merge } from 'lodash';
import {
  InsuredOfficialsOptions,
  PhvPaymentMethod,
  VariantsNames,
} from '../entities/offer/offer.interface';
import { PhvMaritalStatus } from '../components/marital-status/marital-status.interface';
import { deMoment, PhvDateFormat } from '../config';
import { RootState } from '../store/store.interface';
import {
  getBirthdayDate,
  getBusinessId,
  getDurationOfContract,
  getFee,
  getInsuranceStartDate,
  getInsuredOfficialsSelectedOption, getInsuredRelativesCount,
  getOwnContribution,
  getPaymentMethod,
  getSelectedMaritalStatus,
  getSelectedVariantName,
  isInitialPriceBasedOptionSelected,
  isKeyLossOptionSelected,
  isStartBonusIncluded,
  isUnpaidDebtOptionSelected,
} from '../entities/offer/offer.selectors';
import { getInsuranceOwner } from '../entities/persons/persons.selectors';
import {
  AdobeAnalyticsPages,
  ConversionType,
  Gender,
  TransactionDataLayer,
  UserDataLayer,
} from './tracking-types';
import { Person, PersonSalutation } from '../entities/persons/persons.interface';
import { SHA256 } from 'crypto-js';
import { PHONE_NUMBER_PREFIXES, USER_REPLACEMENT_REGEX } from './tracking-helper.consts';

function getKeyValueString(key: string, value: string) {
  return `${key}=${value}`;
}

enum TariffOptionPropertyNames {
  SELECTED_VARIANT = 'Tarif',
  MARITAL_STATUS = 'Situation',
  INSURANCE_START = 'VBeg',
  START_BONUS = 'StBon',
  DURATION_OF_CONTRACT = 'Dauer',
  PAYMENT_PETHOD = 'Zahlung',
  INSURANCE_SUM = 'Summe',
  UNPAID_DEBT = 'NeuWEnts',
  INITIAL_PRICE_BASED = 'Forderungsausfall',
  INSURED_OFFICIALS = 'AmtDienHaft',
  INSURED_RELATIVES = 'AlleinAng',
  OWN_CONTRIBUTION = 'SB',
  KEY_LOSS = 'Schluessel',
}

export function getTariffOptionsMaritalStatus(rootState: RootState): string | null {
  const propertyName = TariffOptionPropertyNames.MARITAL_STATUS;
  const selectedMaritalStatus = getSelectedMaritalStatus(rootState);

  switch (selectedMaritalStatus.value) {
    case PhvMaritalStatus.SINGLE:
      return getKeyValueString(propertyName, 'Single');
    case PhvMaritalStatus.FAMILY:
      return getKeyValueString(propertyName, 'Familie');
    case PhvMaritalStatus.PAIR:
      return getKeyValueString(propertyName, 'Paar');
    case PhvMaritalStatus.SELF_PARENTING:
      return getKeyValueString(propertyName, 'Alleinerziehend');
    default:
      return null;
  }
}

function tariffOptionJaNeinComposer(propertyName: string, logicCheck: boolean, rootState: RootState) {
  const selectedVariant = getSelectedVariantName(rootState);
  if (!selectedVariant) {
    return null;
  }
  if (logicCheck) {
    return getKeyValueString(propertyName, 'J');
  }
  return getKeyValueString(propertyName, 'N');
}

export function getTariffOptionsInsuranceStart(rootState: RootState): string | null {
  const insuranceDate = getInsuranceStartDate(rootState);
  if (!insuranceDate.value) {
    return null;
  }
  const propertyName = TariffOptionPropertyNames.INSURANCE_START;

  const selectedDateFormatted = moment(insuranceDate.value).format(PhvDateFormat.ISO_DATE);
  const tomorrow = deMoment().add(1, 'd').format(PhvDateFormat.ISO_DATE);
  const beginningOfNextMonth = deMoment().add(1, 'M').date(1).format(PhvDateFormat.ISO_DATE);

  switch (selectedDateFormatted) {
    case tomorrow:
      return getKeyValueString(propertyName, 'Morgen');
    case beginningOfNextMonth:
      return getKeyValueString(propertyName, 'NaechsterMonat');
    default:
      return getKeyValueString(propertyName, 'OtherDate');
  }
}

export function getTariffOptionsStartBonus(rootState: RootState): string | null {
  return tariffOptionJaNeinComposer(TariffOptionPropertyNames.START_BONUS, isStartBonusIncluded(rootState), rootState);
}

export function getTariffOptionsSelectedVariant(rootState: RootState): string | null {
  const selectedVariantName = getSelectedVariantName(rootState);
  if (!selectedVariantName) {
    return null;
  }
  return getKeyValueString(TariffOptionPropertyNames.SELECTED_VARIANT, selectedVariantName);
}

export function getTariffOptionsOwnContribution(rootState: RootState): string | null {
  const selectedVariant = getSelectedVariantName(rootState);
  const ownContribution = getOwnContribution(rootState);
  if (!ownContribution || !selectedVariant || !ownContribution[selectedVariant] || !ownContribution[selectedVariant].value) {
    return null;
  }
  //todo where should we take this number from??
  switch (ownContribution[selectedVariant].value) {
    case 'SB_150':
      return getKeyValueString(TariffOptionPropertyNames.OWN_CONTRIBUTION, '150');
    case 'SB_150_OHNE_SELBSTBETEILIGUNG':
      return getKeyValueString(TariffOptionPropertyNames.OWN_CONTRIBUTION, 'Zero');
    default:
      return null;
  }
}

export function getTariffOptionsDurationOfContract(rootState: RootState): string | null {
  const durationOfContract = getDurationOfContract(rootState);
  if (!durationOfContract || !durationOfContract.value) {
    return null;
  }
  return getKeyValueString(TariffOptionPropertyNames.DURATION_OF_CONTRACT, (`${durationOfContract.value}J`));
}

export function getTariffOptionsPaymentMethod(rootState: RootState): string | null {
  const paymentMethod = getPaymentMethod(rootState);
  if (!paymentMethod || !paymentMethod.value) {
    return null;
  }
  const propertyName = TariffOptionPropertyNames.PAYMENT_PETHOD;
  switch (paymentMethod.value) {
    case PhvPaymentMethod.MONATLICH:
      return getKeyValueString(propertyName, '1/12');
    case PhvPaymentMethod.JAEHRLICH:
      return getKeyValueString(propertyName, '1/1');
    case PhvPaymentMethod.HALBJAEHRLICH:
      return getKeyValueString(propertyName, '1/2');
    case PhvPaymentMethod.VIERTELJAEHRLICH:
      return getKeyValueString(propertyName, '1/4');
    default:
      return null;
  }
}

export function getTariffOptionsInsuranceSum(rootState: RootState): string | null {
  const selectedVariant = getSelectedVariantName(rootState);
  if (!selectedVariant) {
    return null;
  }
  const propertyName = TariffOptionPropertyNames.INSURANCE_SUM;
  switch (selectedVariant) {
    case VariantsNames.PHV_SMART:
      return getKeyValueString(propertyName, '10Mio');
    case VariantsNames.PHV_BEST:
      return getKeyValueString(propertyName, '50Mio');
    default:
      return null;
  }
}

export function getTariffOptionsKeyLoss(rootState: RootState): string | null {
  return tariffOptionJaNeinComposer(TariffOptionPropertyNames.KEY_LOSS, isKeyLossOptionSelected(rootState), rootState);
}

export function getTariffOptionsUnpaidDebt(rootState: RootState): string | null {
  return tariffOptionJaNeinComposer(TariffOptionPropertyNames.UNPAID_DEBT, isUnpaidDebtOptionSelected(rootState), rootState);
}

export function getTariffOptionsInitialPriceBased(rootState: RootState): string | null {
  return tariffOptionJaNeinComposer(TariffOptionPropertyNames.INITIAL_PRICE_BASED, isInitialPriceBasedOptionSelected(rootState), rootState);
}

export function getTariffOptionsInsuredOfficials(rootState: RootState): string | null {
  const selectedVariant = getSelectedVariantName(rootState);
  if (!selectedVariant) {
    return null;
  }
  const isInsuredOfficial = getInsuredOfficialsSelectedOption(rootState);
  if (isInsuredOfficial) {
    if (isInsuredOfficial === InsuredOfficialsOptions.ME) {
      return getKeyValueString(TariffOptionPropertyNames.INSURED_OFFICIALS, 'mich');
    } if (isInsuredOfficial === InsuredOfficialsOptions.ME_AND_MY_PARTNER) {
      return getKeyValueString(TariffOptionPropertyNames.INSURED_OFFICIALS, 'michPart');
    }
    return null;
  }
  return getKeyValueString(TariffOptionPropertyNames.INSURED_OFFICIALS, 'N');
}

export function getTariffOptionsInsuredRelatives(rootState: RootState): string | null {
  const selectedVariant = getSelectedVariantName(rootState);
  if (!selectedVariant) {
    return null;
  }
  const insuredRelativesCount = getInsuredRelativesCount(rootState);
  if (insuredRelativesCount === 0) {
    return getKeyValueString(TariffOptionPropertyNames.INSURED_RELATIVES, 'N');
  }
  return getKeyValueString(TariffOptionPropertyNames.INSURED_RELATIVES, insuredRelativesCount.toString());
}

function getActualNetFee(rootState: RootState): number | undefined {
  const selectedVariantName = getSelectedVariantName(rootState);
  if (selectedVariantName) {
    return getFee(rootState)?.[selectedVariantName]?.netto;
  }
  return undefined;
}

export function getYearlyNetFee(rootState: RootState): number | null {
  const actualNetFee = getActualNetFee(rootState);
  const paymentMethod = getPaymentMethod(rootState);
  if (!paymentMethod || !paymentMethod.value || !actualNetFee) {
    return null;
  }

  switch (paymentMethod.value) {
    case PhvPaymentMethod.MONATLICH:
      return actualNetFee * 12;
    case PhvPaymentMethod.JAEHRLICH:
      return actualNetFee;
    case PhvPaymentMethod.HALBJAEHRLICH:
      return actualNetFee * 2;
    case PhvPaymentMethod.VIERTELJAEHRLICH:
      return actualNetFee * 4;
    default:
      return null;
  }
}

function getGenderBySalutation(person: Person): Gender | null {
  switch (person.salutation) {
    case PersonSalutation.MISTER:
      return 'Herr';
    case PersonSalutation.MISTRESS:
      return 'Frau';
    default:
      return null;
  }
}

export const preparePhoneNumberForHashing = (phoneNumber: string) =>
  phoneNumber.replace(USER_REPLACEMENT_REGEX.phone, '');

export const hashData = (dataToHash: string) => {
  const preparedDataToHash = dataToHash
    .toLowerCase()
    .replace(USER_REPLACEMENT_REGEX.hash, '');

  return SHA256(preparedDataToHash).toString();
};

export function getUserField(
  rootState: RootState
): { user: UserDataLayer[] } | null {
  let user: UserDataLayer;
  const birthDate = getBirthdayDate(rootState);
  if (birthDate.value) {
    //fill user with birthdate
    user = {
      profile: {
        attributes: {
          birthday: moment(birthDate.value).format('YYYY'),
        },
      },
    };

    //fill user with gender
    const insuranceOwner = getInsuranceOwner(rootState);
    const gender = getGenderBySalutation(insuranceOwner);
    if (gender) {
      user.profile.attributes.gender = gender;
    }

    user.profile.address = {
      ...(insuranceOwner?.address?.postCode && {
        postalCode: insuranceOwner.address.postCode,
      }),
      ...(insuranceOwner?.address?.city && {
        city: hashData(insuranceOwner.address.city),
      }),
      ...(insuranceOwner?.address?.streetName && {
        line1: hashData(
          `${insuranceOwner.address.streetName} ${insuranceOwner.address.buildingNumber}`
        ),
      }),
      ...(insuranceOwner?.address?.country && {
        country: hashData(insuranceOwner.address.country),
      }),
    };

    const fullPhoneNumber = `${insuranceOwner.phoneAreaPrefix}${insuranceOwner.phoneNumber}`;

    user.profile.profileInfo = {
      ...(insuranceOwner?.firstName && {
        firstName: hashData(insuranceOwner.firstName),
      }),
      ...(insuranceOwner?.lastName && {
        lastName: hashData(insuranceOwner.lastName),
      }),
      ...(insuranceOwner?.phoneNumber && {
        telephone: hashData(
          `${PHONE_NUMBER_PREFIXES.german}${preparePhoneNumberForHashing(
            fullPhoneNumber
          )}`
        ),
      }),
      ...(insuranceOwner?.phoneNumber && {
        telephoneE164: hashData(
          `${PHONE_NUMBER_PREFIXES.e164}${preparePhoneNumberForHashing(
            fullPhoneNumber
          )}`
        ),
      }),
    };
  } else {
    return null;
  }
  
  return { user: [user] };
}

export function decorateTransactionWithCalculatedValue(
  transaction: TransactionDataLayer,
  yearlyNetFee: number | null,
  loadedPage: AdobeAnalyticsPages,
): TransactionDataLayer {
  const PAGES_NOT_TO_TRACK_CALCULATED_VALUE = [
    AdobeAnalyticsPages.MARITAL_STATUS,
    AdobeAnalyticsPages.INSURANCE_DATE,
    AdobeAnalyticsPages.BIRTHDATE,
    AdobeAnalyticsPages.CONTRIBUTION,
  ];
  if (!yearlyNetFee || PAGES_NOT_TO_TRACK_CALCULATED_VALUE.includes(loadedPage)) {
    return transaction;
  }
  return merge(transaction,
    {
      attributes: {
        calculatedValue: yearlyNetFee.toFixed(2),
        ...transaction.attributes,
      },
    });
}

export function decorateTransactionWithConversionType(transaction: TransactionDataLayer, conversionType: ConversionType | null): TransactionDataLayer {
  if (!conversionType) {
    return transaction;
  }
  return merge(transaction,
    {
      attributes: {
        conversionType,
      },
    });
}

export function decorateTransactionWithTotal(transaction: TransactionDataLayer, rootState: RootState, page: AdobeAnalyticsPages): TransactionDataLayer {
  const total = getYearlyNetFee(rootState);
  if (page !== AdobeAnalyticsPages.THANK_YOU || !total) {
    return transaction;
  }
  return merge(transaction,
    {
      total: {
        transactionTotal: total,
      },
    });
}

export function getTransactionField(rootState: RootState,
  converstionType: ConversionType | null,
  yearlyNetFee: number | null,
  page: AdobeAnalyticsPages): TransactionDataLayer {
  const baseTransaction: TransactionDataLayer = { transactionID: getBusinessId(rootState) };
  const resultTransaction = decorateTransactionWithTotal(
    decorateTransactionWithConversionType(
      decorateTransactionWithCalculatedValue(baseTransaction, yearlyNetFee, page), converstionType,
    ), rootState, page,
  );
  return resultTransaction;
}
