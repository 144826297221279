export interface BundleLoaderProps {
  id?: string;
  bundleUrl: string;
  parcelDidMount?: (ref: React.RefObject<BundleParcelElement>) => void;
  customProps?: {
    [key: string]: any
  }
}

export interface BundleLoaderState {
  hasError: boolean;
  bundleConfig: string | null;
}

export interface BundleParcelElement {
  el: HTMLElement;
}

export interface BundleConfig {
  default: any;
}

// lifecycle methods
export enum BundleLoaderMethod {
  BOOTSTRAP = 'bootstrap',
  MOUNT = 'mount',
  UNMOUNT = 'unmount',
  UPDATE = 'update',
}
