import { defaultsDeep } from 'lodash';
import { AnyAction } from 'redux';
import { AppActionTypes } from '../app/app.actions';
import { PersonsActionTypes } from './persons.actions';
import { Person, PersonSalutation, PersonsState } from './persons.interface';

const initialState: PersonsState = {
  loading: false,
  errors: [],
  data: [],
};

const defaultPerson: Person = {
  salutation: PersonSalutation.UNKNOWN,
  firstName: '',
  lastName: '',
  personId: '',
  birthDate: '',
  roles: [],
  address: {
    streetName: '',
    buildingNumber: '',
    postCode: '',
    city: '',
    country: '',
  },
};

const getNewPerson = (personCandidiate: Person): Person =>
  defaultsDeep({}, personCandidiate, defaultPerson);

const fillPersonsWithDefaultValues = (persons: Person[]): Person[] =>
  persons.map((person: Person) => getNewPerson(person));

const updatePerson = (
  data: PersonsState['data'],
  person: Partial<Person>,
): Person[] =>
  data.map(p => {
    if (p.personId === person.personId) {
      return defaultsDeep({}, person, p);
    }
    return p;
  });

/* eslint-disable @typescript-eslint/default-param-last*/
export const personsReducer = (
  state: PersonsState = initialState,
  action: AnyAction,
): PersonsState => {
  switch (action.type) {
    case AppActionTypes.INIT_WITH_BUSINESS_ID.success:
    case AppActionTypes.INIT.success:
      return {
        ...state,
        data: fillPersonsWithDefaultValues(action.payload.persons),
      };

    case PersonsActionTypes.UPDATE_PERSON:
      return {
        ...state,
        data: updatePerson(state.data, action.payload.person),
      };

    case PersonsActionTypes.UPDATE_INSURED_OFFICIAL:
    case PersonsActionTypes.UPDATE_INSURED_RELATIVE:
      return {
        ...state,
        data: updatePerson(state.data, action.payload),
      };

    case PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.start:
      return {
        ...state,
        loading: true,
      };

    case PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.success:
      return {
        ...state,
        loading: false,
        data: updatePerson(state.data, action.payload.response.person),
      };

    case PersonsActionTypes.PUT_PERSON.error:
    case PersonsActionTypes.PUT_PERSON_BIRTHDAY_DATE.error:
      return {
        ...state,
        errors: [...state.errors, action.payload.error.message],
        loading: false,
      };

    case PersonsActionTypes.PUT_PERSON.start:
      return {
        ...state,
        loading: true,
        errors: [],
      };

    case PersonsActionTypes.PUT_PERSON.success:
      return {
        ...state,
        loading: false,
        errors: action.payload.response.errors,
        data: updatePerson(state.data, action.payload.response.person),
      };

    default:
      return state;
  }
};
