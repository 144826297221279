import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ThankYouComponent } from './thank-you.component';
import { offerActions } from '../../entities/offer/offer.actions';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { RootState } from '../../store/store.interface';
import { getWelcomeInErgoUrl } from '../../entities/aem/aem-properties.selectors';
import { getInsuranceOwner } from '../../entities/persons/persons.selectors';

const mapStateToProps = (state: RootState) => ({
  welcomeInErgoUrl: getWelcomeInErgoUrl(state),
  insuranceOwner: getInsuranceOwner(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteOfferId: () => dispatch(offerActions.deleteOfferId()),
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.THANK_YOU)),
});

export const ThankYou = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouComponent);
