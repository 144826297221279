import Details from '@eg/elements/Details';
import moment from 'moment';
import React from 'react';
import { PhvDateFormat } from '../../../config';
import { LabelValue } from '../label-value/label-value.component';
import { PersonalDataProps } from './personal-data.interface';
import { onOpen } from '../../tooltip-icon/tooltip-icon';
import { GeneralClickedElement } from '../../../tracking/tracking-general-clicks.types';

const parsePhoneNumber: (prefix?: string, phoneNr?: string) => string | null = (prefix?: string, phoneNr?: string) => {
  if (!prefix && !phoneNr) {
    return null;
  }

  let parsedNr = '';

  if (prefix?.trim()) {
    parsedNr += `${prefix} / `;
  }

  if (phoneNr?.trim()) {
    parsedNr += phoneNr;
  }
  return parsedNr;
};

const PersonalDataComponent = ({ insuranceOwner, trackGeneralClick }: PersonalDataProps) => {
  const insuranceOwnerBirthDate = moment(insuranceOwner.birthDate.toString(), PhvDateFormat.ISO_DATE).format(
    PhvDateFormat.UI_DATE,
  );

  const parsedPhoneNumber: string | null = parsePhoneNumber(insuranceOwner.phoneAreaPrefix, insuranceOwner.phoneNumber);

  return (
    <Details
      summary="Persönliche Daten"
      summaryClassName="checkout__section-header"
      onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.PERSOENLICHEDATEN))}
    >
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className="esc_col esc_col-12">
            <LabelValue label="Anrede">
              {insuranceOwner.salutation}
            </LabelValue>
          </div>

          <div className="esc_col esc_col-12 esc_col-m-6">
            <LabelValue label="Vorname">
              {insuranceOwner.firstName}
            </LabelValue>
          </div>

          <div className="esc_col esc_col-12 esc_col-m-6">
            <LabelValue label="Name">
              {insuranceOwner.lastName}
            </LabelValue>
          </div>

          <div className="esc_col esc_col-12 esc_col-m-6">
            <LabelValue label="Adresse">
              {`${insuranceOwner.address.streetName} ${insuranceOwner.address.buildingNumber}`}
              <br />
              {`${insuranceOwner.address.postCode} ${insuranceOwner.address.city}`}
              {' '}
              <br />
              {insuranceOwner.address.country}
            </LabelValue>
          </div>

          <div className="esc_col esc_col-12 esc_col-m-6">
            <LabelValue label="Geburtsdatum">
              {insuranceOwnerBirthDate}
            </LabelValue>
          </div>

          {parsedPhoneNumber && (
            <div className="esc_col esc_col-12 esc_col-m-6">
              <LabelValue label="Telefon">
                {parsedPhoneNumber}
              </LabelValue>
            </div>
          )}

        </div>
      </div>
    </Details>
  );
};

export default PersonalDataComponent;
