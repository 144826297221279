import FormSection from '@eg/elements/FormSection';
import InputRow from '@eg/elements/InputRow';
import MessageBox from '@eg/elements/MessageBox';
import { isValidBic } from '@eg/elements/utils/validation/bic';
import { isValidIban } from '@eg/elements/utils/validation/iban';
import React, {
  ChangeEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { PersonsStateErrors } from '../../entities/persons/persons.interface';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import TooltipIcon, { onOpen } from '../tooltip-icon/tooltip-icon';
import './payment-data.component.scss';
import { PaymentDataProps } from './payment-data.interface';

const isItGermanIban = (iban: string) => iban?.toLocaleLowerCase().startsWith('de');

const isPaymentDataValid = (
  iban: string,
  bic: string,
) => isValidIban(iban) && (isValidBic(bic) || isItGermanIban(iban));

const isFormValid = (errors: Array<string>) => errors.length === 0;

const PaymentDataComponent: FunctionComponent<PaymentDataProps> = ({
  goToNextStep,
  setCanGoToNextStep,
  setNextStepCallback,
  setPreviousStep,
  insuranceOwnerFullName,
  paymentData,
  updateField,
  insuranceOwnerErrors,
  isLoading,
  sendPaymentData,
  trackPageLoaded,
  trackGeneralClick,

}: PaymentDataProps) => {
  const [showBic, setShowBic] = useState(false);
  const [showIbanError, setIbanError] = useState(false);
  const [showBicError, setBicError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const mapBackendErrorsToFrontendMessages = (insuranceOwnerErrorsList: PersonsStateErrors[]) => insuranceOwnerErrorsList.map(
    (err) => err.errorMessage,
  );

  const errorsList: Array<string> = mapBackendErrorsToFrontendMessages(insuranceOwnerErrors);

  useEffect(() => {
    setPreviousStep(RoutePath.PERSONAL_DATA);
    setNextStepCallback(() => {
      setIsSubmitted(true);
    });
    trackPageLoaded();
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      sendPaymentData();
    }
  }, [isSubmitted]);

  useEffect(() => {
    const { error } = paymentData;
    if (!isLoading && isSubmitted && !error && !errorsList.length) {
      goToNextStep(RoutePath.CHECKOUT);
    }
  }, [isLoading]);

  const onChangeIban = ({ currentTarget }: ChangeEvent<any>) => {
    const { value } = currentTarget;
    const ibanInput = value.replace(/ /g, '');

    updateField({ iban: ibanInput });
    setIbanError(!isValidIban(ibanInput));
    if (isItGermanIban(ibanInput)) {
      updateField({ bic: '' });
    }
  };

  const onChangeBic = ({ currentTarget }: ChangeEvent<any>) => {
    const { value: bicInput } = currentTarget;

    updateField({ bic: bicInput });
    setBicError(!isValidBic(bicInput));
  };

  const {
    data: {
      bic = '',
      iban = '',
    },
    error,
  } = paymentData;

  const shouldShowBic = (ibanValue: string) => !!ibanValue && ibanValue.length > 1 && !isItGermanIban(ibanValue);

  useEffect(() => {
    setShowBic(shouldShowBic(iban));
    setCanGoToNextStep(isPaymentDataValid(iban, bic) && isFormValid(errorsList));
  }, [iban, bic, insuranceOwnerErrors]);

  return (
    <div className="payment-data esc_container--l">
      <p className="payment-data__page-title">Geben Sie Ihre Bankverbindung an.</p>
      <p className="payment-data__page-subtitle">
        Für einen Online-Abschluss benötigt der Versicherer ein
        SEPA-Lastschriftmandat.
      </p>
      <FormSection heading="Bankverbindung">
        <InputRow
          data-testid="payment-data-iban-input"
          className="payment-data__input-iban"
          label="IBAN"
          name="iban"
          onChange={onChangeIban}
          value={iban}
          error={showIbanError ? 'Diese IBAN ist nicht korrekt, bitte füllen Sie das Feld noch einmal aus.' : false}
          tooltip={(
            <TooltipIcon
              onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_IBAN))}
            >
              <div className="enhanced-tooltip-content">Ihre IBAN finden Sie auf der Rückseite Ihrer Bankkarte
                oder auf Ihrem Kontoauszug.
              </div>
            </TooltipIcon>
          )}
        />
        {showBic && (
          <InputRow
            data-testid="payment-data-bic-input"
            className="payment-data__input-bic"
            label="BIC"
            name="bic"
            onChange={onChangeBic}
            value={bic}
            error={showBicError ? 'Diese BIC is nicht korrekt, bitte füllen Sie das Feld noch einmal aus.' : false}
          />
        )}

        {error && (
          <MessageBox type="error">
            Die Bankdaten wurden nicht gespeichert, etwas ist schlimm gegangen.
          </MessageBox>
        )}

        {insuranceOwnerFullName && (
          <InputRow
            data-testid="payment-data-insuranceOwnerFullName-input"
            label="Kontoinhaber"
            disabled
            value={insuranceOwnerFullName}
          />
        )}
      </FormSection>
      <>
        <p className="payment-data__authorize-text">
          Ich ermächtige die ERGO Versicherung AG, ERGO-Platz 1, 40477 Düsseldorf
          (Gläubiger-ID DE05ZZZ00000012101), Zahlungen von meinem
          Konto mittels Lastschrift einzuziehen.
        </p>
        <p className="payment-data__payment__consent-text">
          Zugleich weise ich mein Kreditinstitut an, diese auf mein Konto gezogenen Lastschriften einzulösen. Der
          SEPA-Basislastschrift-Einzug wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren
          Fälligkeitstermine angekündigt.
        </p>
      </>
      <div className="payment-data__info-div">
        <div className="payment-data__info-text">Hinweise zur SEPA-Lastschrift</div>
        <TooltipIcon
          className="payment-data__info-tooltip"
          onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_SEPA))}
        >
          <p>
            Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags
            verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.
          </p>
          <p>
            Sollte bereits ein Mandat für die oben genannte Kontoverbindung bestehen, bin ich damit einverstanden, dass
            das von mir bereits erteilte SEPA-Lastschriftmandat auch für den Einzug der Beiträge für diesen
            Versicherungsvertrag genutzt wird.
          </p>
        </TooltipIcon>
      </div>
    </div>
  );
};

export const PaymentDataComponentWithNavigation = withNavigation(PaymentDataComponent);
