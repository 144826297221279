import { connect } from 'react-redux';
import {
  getAppError, isAemIntegrated, isAppInitialized, isMaklerFlow,
} from '../../entities/app/app.selectors';
import { RootState } from '../../store/store.interface';
import { AppComponent } from './app.component';

const mapStateToProps = (state: RootState) => ({
  stopAppWithError: getAppError(state),
  isAppInitialized: isAppInitialized(state),
  inAem: isAemIntegrated(state),
  isMaklerFlow: isMaklerFlow(state),
});

const mapDispatchToProps = () => ({});

export const App = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppComponent);
