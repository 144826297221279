import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';

export const TrackingActionTypes = {
  INITIALIZATION: '@tracking/INITIALIZATION',
  PAGE_LOADED: '@tracking/PAGE_LOADED',
  ELEMENT_CLICKED: '@tracking/ELEMENT_CLICKED',
  ELEMENT_CLICKED_SUCCESS: '@tracking/ELEMENT_CLICKED_SUCCESS',
};

const trackPageLoaded = (pageLoaded: AdobeAnalyticsPages) => ({
  type: TrackingActionTypes.PAGE_LOADED,
  payload: pageLoaded,
});

const trackGeneralClick = (generalClickElementType: GeneralClickElementType) => ({
  type: TrackingActionTypes.ELEMENT_CLICKED,
  payload: generalClickElementType,
});

const initialize = () => ({
  type: TrackingActionTypes.INITIALIZATION,
});

export const trackingActions = {
  trackGeneralClick,
  trackPageLoaded,
  initialize,
};
