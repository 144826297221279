import { PhvDateFormat, deMoment } from '../../config';
import {
  InsuranceStartDateOptionLabel,
  InsuranceStartDateOption,
} from './insurance-start-date.interface';

export const insuranceStartDateOptions = (): InsuranceStartDateOption[] => {
  const tomorrow = deMoment().add(1, 'd');
  const beginningOfNextMonth = deMoment().add(1, 'M').date(1);

  return [
    {
      label: InsuranceStartDateOptionLabel.TOMORROW,
      shortDate: tomorrow.format(PhvDateFormat.UI_DATE),
      longDate: tomorrow.format(PhvDateFormat.UI_DAY_NAME_WITH_DATE),
      value: tomorrow.format(PhvDateFormat.ISO_DATE),
    },
    {
      label: InsuranceStartDateOptionLabel.NEXT_MONTH,
      shortDate: beginningOfNextMonth.format(PhvDateFormat.UI_DATE),
      longDate: beginningOfNextMonth.format(
        PhvDateFormat.UI_DAY_NAME_WITH_DATE
      ),
      value: beginningOfNextMonth.format(PhvDateFormat.ISO_DATE),
    },
  ];
};
