import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { backendUrl } from '../../config';
import { isMaklerFlow } from '../../entities/app/app.selectors';
import { offerActions } from '../../entities/offer/offer.actions';
import { ConsultancyProtocolId } from '../../entities/offer/offer.interface';
import { getBusinessId, getConsultancyProtocolId } from '../../entities/offer/offer.selectors';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { RootState } from '../../store/store.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';
import { MaklerConsultationComponentWithNavigation } from './makler-consultation.component';

const mapStateToProps = (state: RootState) => ({
  isMaklerFlow: isMaklerFlow(state),
  consultancyProtocolId: getConsultancyProtocolId(state).value,
  downloadDocumentLink: `${backendUrl}/api/phv/document/${getBusinessId(state)}`,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendConsultancyProtocolId: (value: ConsultancyProtocolId) => {
    dispatch(offerActions.sendField({
      fieldName: 'consultancyProtocolId',
      value,
    }));
  },
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(
    generalClickElementType,
  )),
  validateOffer: () => dispatch(offerActions.validateOffer()),
  deleteOfferId: () => dispatch(offerActions.deleteOfferId()),
});

export const MaklerConsultation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaklerConsultationComponentWithNavigation);
