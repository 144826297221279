import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { App } from './components/app/app.container';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('phv-root'),
);
