import React, { FunctionComponent } from 'react';

interface LabelValueProps {
  label: string;
}

export const LabelValue: FunctionComponent<LabelValueProps> = ({ label, children }: React.PropsWithChildren<LabelValueProps>) => (
  <div className="checkout__label-value">
    <div className="checkout__label-value_label">
      {label}
    </div>
    {children ? <div>{children}</div> : ''}
  </div>
);
