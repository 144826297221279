import React from 'react';
import Details from '@eg/elements/Details';
import { BankDetailsProps } from './bank-details.interface';
import { LabelValue } from '../label-value/label-value.component';
import { onOpen } from '../../tooltip-icon/tooltip-icon';
import { GeneralClickedElement } from '../../../tracking/tracking-general-clicks.types';

const BankDetailsComponent = ({ insuranceOwner, paymentData, trackGeneralClick }: BankDetailsProps) => (
  <Details
    summary="Bankverbindung"
    summaryClassName="checkout__section-header"
    onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.BANKVERBINDUNG))}
  >
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-m-6">
          <LabelValue label="Zahlungsweise">
            SEPA-Lastschriftmandat
          </LabelValue>
        </div>
        <div className="esc_col esc_col-12 esc_col-m-6">
          <LabelValue label="IBAN">
            {paymentData.data.iban}
          </LabelValue>
        </div>
        <div className="esc_col esc_col-12">
          <LabelValue label="Kontoinhaber">
            {`${insuranceOwner.salutation} ${insuranceOwner.firstName} ${insuranceOwner.lastName}`}
          </LabelValue>
        </div>
      </div>
    </div>
  </Details>
);

export default BankDetailsComponent;
