/* eslint-disable */

import { isProductionBuild } from "../config";

export class Logger {

  static log(...data: any[]){
    !isProductionBuild && window.console && console.log(...data);
  }
  static error(...data: any[]){
    !isProductionBuild && window.console && console.error(...data);
  }
  static debug(...data: any[]){
    !isProductionBuild && window.console && console.debug(...data);
  }
}
