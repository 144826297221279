import moment, { Moment } from 'moment';
import { buildDate, PhvDateFormat } from '../config';
import { PreviousNavigationAction, PreviousNavigationActionOptions } from '../entities/app/app.interface';
import { getInsuranceStartDate } from '../entities/offer/offer.selectors';
import { RootState } from '../store/store.interface';
import { GeneralClickElementType, RequestErrorInfo } from './tracking-general-clicks.types';
import {
  getTariffOptionsDurationOfContract,
  getTariffOptionsInitialPriceBased,
  getTariffOptionsInsuranceStart,
  getTariffOptionsInsuranceSum,
  getTariffOptionsInsuredOfficials,
  getTariffOptionsInsuredRelatives,
  getTariffOptionsKeyLoss,
  getTariffOptionsMaritalStatus,
  getTariffOptionsOwnContribution,
  getTariffOptionsPaymentMethod,
  getTariffOptionsSelectedVariant,
  getTariffOptionsStartBonus,
  getTariffOptionsUnpaidDebt,
  getTransactionField,
  getUserField,
  getYearlyNetFee,
} from './tracking-helper';
import {
  AdobeAnalyticsAPI,
  AdobeAnalyticsPages,
  AdobeAnalyticsSysEnv,
  AdobeEventTypes,
  AEMTrackingProperties,
  ClickType,
  ConversionType,
  GeneralClickErrorEvent,
  GeneralClickEvent,
  PageLoadEvent,
} from './tracking-types';

export const PAGES_TO_TRACK_MARITAL_STATUS = [
  AdobeAnalyticsPages.INSURANCE_DATE,
  AdobeAnalyticsPages.BIRTHDATE,
  AdobeAnalyticsPages.CONTRIBUTION,
  AdobeAnalyticsPages.PERSONAL_DATA,
  AdobeAnalyticsPages.PAYMENT_DATA,
  AdobeAnalyticsPages.CHECKOUT,
  AdobeAnalyticsPages.CONSULTATION,
  AdobeAnalyticsPages.THANK_YOU];

export const PAGES_TO_TRACK_INSURANCE_START = [
  AdobeAnalyticsPages.BIRTHDATE,
  AdobeAnalyticsPages.CONTRIBUTION,
  AdobeAnalyticsPages.PERSONAL_DATA,
  AdobeAnalyticsPages.PAYMENT_DATA,
  AdobeAnalyticsPages.CHECKOUT,
  AdobeAnalyticsPages.CONSULTATION,
  AdobeAnalyticsPages.THANK_YOU];

export const PAGES_TO_TRACK_START_BONUS = [
  AdobeAnalyticsPages.CONTRIBUTION,
  AdobeAnalyticsPages.PERSONAL_DATA,
  AdobeAnalyticsPages.PAYMENT_DATA,
  AdobeAnalyticsPages.CHECKOUT,
  AdobeAnalyticsPages.CONSULTATION,
  AdobeAnalyticsPages.THANK_YOU];

export const PAGES_TO_TRACK_CONTRIBUTION_FIELDS = [
  AdobeAnalyticsPages.PERSONAL_DATA,
  AdobeAnalyticsPages.PAYMENT_DATA,
  AdobeAnalyticsPages.CHECKOUT,
  AdobeAnalyticsPages.CONSULTATION,
  AdobeAnalyticsPages.THANK_YOU];

export class AdobeAnalytics {
  private static instance: AdobeAnalytics;

  private debugFunction: (...args: any[]) => void;

  private debugMode: boolean;

  private adobeAPI: AdobeAnalyticsAPI;

  private sysEnv: AdobeAnalyticsSysEnv;

  private aemTrackingProperies: AEMTrackingProperties;

  private isMaklerMode: boolean;

  constructor() {
    this.debugFunction = () => {
    };
    this.debugMode = false;
    // eslint-disable-next-line
    this.adobeAPI = new Array();

    //I know... but I didnt know how to initialise it otherwise
    this.aemTrackingProperies = {} as AEMTrackingProperties;
    this.sysEnv = {} as AdobeAnalyticsSysEnv;

    this.isMaklerMode = false;
  }

  public static getInstance(): AdobeAnalytics {
    if (!AdobeAnalytics.instance) {
      AdobeAnalytics.instance = new AdobeAnalytics();
    }

    return AdobeAnalytics.instance;
  }

  private static getClickedElementByPreviouslyCLickedElement(clickedElement: PreviousNavigationAction): string | null {
    switch (clickedElement) {
      case PreviousNavigationActionOptions.NEXT:
        return 'Button_Weiter';
      case PreviousNavigationActionOptions.BACK:
        return 'Button_Zurueck';
      default:
        return null;
    }
  }

  private static getConversionTypeByPage(lodedPage: AdobeAnalyticsPages):
  ConversionType | null {
    switch (lodedPage) {
      case AdobeAnalyticsPages.MARITAL_STATUS:
        return 'SaleFunnelStart';
      case AdobeAnalyticsPages.CONTRIBUTION:
        return 'SaleFunnelCalculation';
      case AdobeAnalyticsPages.PERSONAL_DATA:
        return 'SaleFunnelPersonalData';
      case AdobeAnalyticsPages.PAYMENT_DATA:
        return 'SaleFunnelBankData';
      case AdobeAnalyticsPages.CHECKOUT:
        return 'SaleFunnelFinalCheck';
      case AdobeAnalyticsPages.THANK_YOU:
        return 'Sale';
      default:
        return null;
    }
  }

  private static composeTariffOptions(rootState: RootState): string {
    const basicTariffOptions = 'Rechner=phv2020';

    const tarriffOptions = [];
    tarriffOptions.push(basicTariffOptions);

    tarriffOptions.push(getTariffOptionsMaritalStatus(rootState));
    tarriffOptions.push(getTariffOptionsInsuranceStart(rootState));
    tarriffOptions.push(getTariffOptionsStartBonus(rootState));
    tarriffOptions.push(getTariffOptionsSelectedVariant(rootState));
    tarriffOptions.push(getTariffOptionsOwnContribution(rootState));
    tarriffOptions.push(getTariffOptionsDurationOfContract(rootState));
    tarriffOptions.push(getTariffOptionsPaymentMethod(rootState));
    tarriffOptions.push(getTariffOptionsInsuranceSum(rootState));
    tarriffOptions.push(getTariffOptionsKeyLoss(rootState));
    tarriffOptions.push(getTariffOptionsUnpaidDebt(rootState));
    tarriffOptions.push(getTariffOptionsInitialPriceBased(rootState));
    tarriffOptions.push(getTariffOptionsInsuredOfficials(rootState));
    tarriffOptions.push(getTariffOptionsInsuredRelatives(rootState));
    return tarriffOptions.filter((item) => (item || null)).join('|');
  }

  private static composeTariffOptionsPerPage(rootState: RootState, lodedPage: AdobeAnalyticsPages): string {
    const basicTariffOptions = 'Rechner=phv2020';

    const tarriffOptions = [];
    tarriffOptions.push(basicTariffOptions);

    if (PAGES_TO_TRACK_MARITAL_STATUS.includes(lodedPage)) {
      tarriffOptions.push(getTariffOptionsMaritalStatus(rootState));
    }
    if (PAGES_TO_TRACK_INSURANCE_START.includes(lodedPage)) {
      tarriffOptions.push(getTariffOptionsInsuranceStart(rootState));
    }
    if (PAGES_TO_TRACK_START_BONUS.includes(lodedPage)) {
      tarriffOptions.push(getTariffOptionsStartBonus(rootState));
    }
    if (PAGES_TO_TRACK_CONTRIBUTION_FIELDS.includes(lodedPage)) {
      tarriffOptions.push(getTariffOptionsSelectedVariant(rootState));
      tarriffOptions.push(getTariffOptionsOwnContribution(rootState));
      tarriffOptions.push(getTariffOptionsDurationOfContract(rootState));
      tarriffOptions.push(getTariffOptionsPaymentMethod(rootState));
      tarriffOptions.push(getTariffOptionsInsuranceSum(rootState));
      tarriffOptions.push(getTariffOptionsKeyLoss(rootState));
      tarriffOptions.push(getTariffOptionsUnpaidDebt(rootState));
      tarriffOptions.push(getTariffOptionsInitialPriceBased(rootState));
      tarriffOptions.push(getTariffOptionsInsuredOfficials(rootState));
      tarriffOptions.push(getTariffOptionsInsuredRelatives(rootState));
    }

    return tarriffOptions.filter((item) => (item || null)).join('|');
  }

  start(
    adobeAPI: AdobeAnalyticsAPI,
    aemTrackingProperties: AEMTrackingProperties,
    isBrokerMode: boolean,
    sysEnv: AdobeAnalyticsSysEnv,
    debugMode?: boolean,
    debugFunction?: (...args: any[]) => void,
  ) {
    this.debugFunction = debugFunction || this.debugFunction;
    this.debugMode = debugMode || this.debugMode;
    this.adobeAPI = adobeAPI;
    this.sysEnv = sysEnv;
    this.aemTrackingProperies = aemTrackingProperties;
    this.isMaklerMode = isBrokerMode;
  }

  // eslint-disable-next-line
  pageLoad(pageName: AdobeAnalyticsPages, rootState: RootState, previousNavigationAction: PreviousNavigationAction) {
    const pageLoadEvent = this.createPageLoadEvent(pageName, rootState, previousNavigationAction);
    this.track(pageLoadEvent);
  }

  generalClick({ clickedElement, clickType }: GeneralClickElementType, oeNumber: string | null) {
    const generalClickPayload: GeneralClickEvent = {
      event: AdobeEventTypes.GENERAL_CLICK,
      eventdetails: {
        clickedElement,
        clickType,
      },
      page: {
        attributes: {
          brokerModus: this.isMaklerMode ? 'On' : 'Off',
        },
      },
      miscellaneous: oeNumber ? { agentSwitch: { oeNumber } } : undefined,
    };
    this.track(generalClickPayload);
  }

  generalClickError({ errorType, errorUrl, errorMessages }: RequestErrorInfo, rootState: RootState) {
    const generalClickPayload: GeneralClickErrorEvent = {
      event: AdobeEventTypes.GENERAL_CLICK,
      eventdetails: {
        clickType: ClickType.OTHER,
      },
      product: [
        {
          attributes: {
            tariffOptions1: AdobeAnalytics.composeTariffOptions(rootState),
          },
        },
      ],
      miscellaneous: {
        errors: {
          errorFlag: true,
          errorType,
          errorUrl,
          errorMessages,
        },
      },
      page: {
        attributes: {
          brokerModus: this.isMaklerMode ? 'On' : 'Off',
        },
      },
    };
    this.track(generalClickPayload);
  }

  createRequestErrorInfo(actionPayload: any): RequestErrorInfo {
    const { error: { request: { url }, response: { statusCode, message } } } = actionPayload;
    return {
      errorType: statusCode,
      errorMessages: `${url} - ${message}`,
      errorUrl: window.location.href,
    };
  }

  private createPageLoadEvent(
    loadedPage: AdobeAnalyticsPages,
    rootState: RootState,
    previousNavigationAction: PreviousNavigationAction,
  ): PageLoadEvent {
    const insuranceStartDate = getInsuranceStartDate(rootState);

    const conversionType = AdobeAnalytics.getConversionTypeByPage(loadedPage);
    const clickedElement = AdobeAnalytics.getClickedElementByPreviouslyCLickedElement(previousNavigationAction);
    const yearlyNetFee = getYearlyNetFee(rootState);

    const analyticsDate: Moment = moment(buildDate?.match(/\d{4}-\d{2}-\d{2}/)?.[0]);
    const analyticsFormattedDate: string = analyticsDate.isValid() ? analyticsDate.format('DD.MM.YYYY') : '';

    return {
      event: AdobeEventTypes.PAGE_LOADED,
      page: {
        attributes: {
          displayedBrand: 'ERGO',
          brokerModus: this.isMaklerMode ? 'On' : 'Off',
        },
        pageInfo: {
          pageName: `${this.aemTrackingProperies.productName}:${this.aemTrackingProperies.pageNames[loadedPage]}`,
          sysEnv: this.sysEnv,
          issueDate: `OTRphv2020|${analyticsFormattedDate}`,
        },
        category: {
          pagePurpose: 'Sale',
          primaryCategory: 'phv2020',

        },
      },
      product: [
        {
          productInfo: {
            productName: this.aemTrackingProperies.productName,
          },
          category: {
            primaryCategory: this.aemTrackingProperies.productPrimaryCategory,
          },
          attributes: {
            tariffOptions1: AdobeAnalytics.composeTariffOptionsPerPage(rootState, loadedPage),
            ...(insuranceStartDate.value && { insuranceStart: moment(insuranceStartDate.value).format(PhvDateFormat.UI_DATE) }),
          },
        },
      ],
      transaction: getTransactionField(rootState, conversionType, yearlyNetFee, loadedPage),
      ...(clickedElement && {
        eventdetails: {
          clickedElement,
        },
      }),
      ...getUserField(rootState),
    };
  }

  private debug(payload: any) {
    if (this.debugMode) {
      this.debugFunction('[AdobeAnalytics]', payload);
    }
  }

  private track(payload: PageLoadEvent | GeneralClickEvent | GeneralClickErrorEvent) {
    this.debug(payload);
    this.adobeAPI?.push(payload);
  }
}
