import React, { useEffect } from 'react';
import { RoutePath } from '../app/app.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import './checkout.component.scss';
import { CheckoutProps } from './checkout.interface';
import { TariffAndContribution } from './tariff-and-contribution/tariff-and-contribution.container';
import { PersonalData } from './personal-data/personal-data.container';
import { InsuredOfficials } from './insured-officials/insured-officials.container';
import { InsuredRelatives } from './insured-relatives/insured-relatives.container';
import { BankDetails } from './bank-details/bank-details.container';

const CheckoutComponent = ({
  selectedVariantName,
  isInsuredOfficialsOptionSelected,
  isInsuredRelativesOptionSelected,
  setCanGoToNextStep,
  setPreviousStep,
  setButtonLabel,
  setNextStep,
  trackPageLoaded,
}: CheckoutProps) => {
  useEffect(() => {
    trackPageLoaded();
  }, []);

  useEffect(() => {
    setPreviousStep(RoutePath.PAYMENT_DATA);
  }, [setPreviousStep]);

  useEffect(() => {
    setNextStep(RoutePath.CONSULTATION);
  }, [setNextStep]);

  useEffect(() => {
    setCanGoToNextStep(true);
  }, [setButtonLabel, setCanGoToNextStep]);

  if (!selectedVariantName) {
    throw new Error('Variant has to be selected');
  }

  return (
    <div className="esc_container--l checkout__container">
      <h2 className="checkout__page-title">
        Sie haben es fast geschafft.
      </h2>
      <div className="checkout__page-subtitle">
        Bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.
      </div>

      <TariffAndContribution />
      <PersonalData />
      {isInsuredOfficialsOptionSelected && <InsuredOfficials />}
      {isInsuredRelativesOptionSelected && <InsuredRelatives />}
      <BankDetails />
    </div>
  );
};

export const CheckoutComponentWithNavigation = withNavigation(CheckoutComponent);
