import {
  Field, FieldPerVariant, FieldWithRange, OfferFee, VariantNameValuesList,
} from './offer.interface';

export const initFieldPerVariant = <T>(initValue: T = '' as unknown as T): FieldPerVariant<T> => VariantNameValuesList.reduce((
  fieldPerVariant,
  variantName,
) => ({
  ...fieldPerVariant,
  [variantName]: {
    value: (initValue ?? '') as T,
    defaultValue: '',
    mandatory: false,
    options: [],
  },
}), {} as FieldPerVariant<T>);

export const initField = <T>(initValue: T = '' as unknown as T): Field<T> => ({
  value: (initValue ?? '') as T,
  defaultValue: '',
  mandatory: false,
  options: [],
  label: '',
  saved: false,
});

export const initFieldWithRange = <T>(initValue: T = '' as unknown as T): FieldWithRange<T> => ({
  ...initField(initValue),
  min: initValue,
  max: initValue,
});

export const initStringField = (): Field<string> => ({
  ...initField(),
  value: '',
});

export const initFee = (): OfferFee => VariantNameValuesList.reduce((fees, variantName) => ({
  ...fees,
  [variantName]: {
    brutto: '',
    netto: '',
  },
}), {} as OfferFee);

export const initPaymentReducer = () => ({
  id: '',
  iban: '',
  bic: '',
  accountId: '',
  error: false,
});
