import Button from '@eg/elements/Button';
import Checkbox from '@eg/elements/Checkbox';
import { DownloadIcon, LoaderIcon } from '@eg/elements/components/Icons';
import Modal from '@eg/elements/Modal';
import Radio from '@eg/elements/Radio';
import { themeBrandColor } from '@eg/elements/styles/ergo-one/colors';
import classNames from 'classnames';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { PhvDateFormat } from '../../config';
import { loadExternalJs } from '../../entities/aem/aem-properties.helper';
import { OfferStateData, VariantsNames } from '../../entities/offer/offer.interface';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';
import { AgreementOptions } from '../agreement-options/agreement-options.container';
import { RoutePath } from '../app/app.interface';
import { PhvMaritalStatus } from '../marital-status/marital-status.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import { ContributionTooltips } from './contribution-tooltips';
import './contribution.component.scss';
import { ContributionProps } from './contribution.interface';

import { InsuredOfficialsComponent } from './insured-officials/insured-officials.component';
import { TooltipWrapper } from './tooltip-wrapper.component';
import { Logger } from '../../commons/logger';
import { noCacheHeaders } from '../../entities/app/app.epic';

const MARITAL_STATUS_DESCRIPTION: { [key in PhvMaritalStatus]: string } = {
  [PhvMaritalStatus.FAMILY]: 'sich und Ihre Familie',
  [PhvMaritalStatus.PAIR]: 'sich und Ihren Partner',
  [PhvMaritalStatus.SELF_PARENTING]: 'sich und Ihre Kinder',
  [PhvMaritalStatus.SINGLE]: 'sich',
};

const ContributionComponent = (props: ContributionProps) => {
  const {
    keyLoss,
    initialPriceBased,
    unpaidDebtInsurance,
    maritalStatus,
    insuredPersonBirthdate,
    insuranceStartDate,
    variants,
    selectedVariantName,
    insuredRelatives,
    insuredOfficials,
    insuranceOwner,
    insurancePartner,
    insuredPersonsIds,
    history,
    setCanGoToNextStep,
    setPreviousStep,
    setNextStep,
    trackPageLoaded,
    trackGeneralClick,
    productTableUrl,
    pdfUrl,
  } = props;

  const insuredRelativesIds = insuredRelatives[selectedVariantName].value as string[];
  const insuredOfficialsIds = insuredOfficials[selectedVariantName].value as string[];

  const [hasInsuredRelatives, setHasInsuredRelatives] = useState(!!insuredRelativesIds.length);
  const [showProductTablePopup, setShowProductTablePopup] = useState(false);
  const [productTableHtml, setProductTableHtml] = useState('');

  useEffect(() => {
    trackPageLoaded();
    setPreviousStep(RoutePath.BIRTHDATE);
    setCanGoToNextStep(false);
    setNextStep(RoutePath.PERSONAL_DATA);
  }, []);

  useEffect(() => {
    if (selectedVariantName) {
      setCanGoToNextStep(true);
    }
  }, [setCanGoToNextStep, selectedVariantName]);

  useEffect(() => {
    if (productTableHtml && showProductTablePopup) {
      window.parent.postMessage('scrolltotop', '*');
    }
  }, [productTableHtml, showProductTablePopup]);

  const onChangeVariantField = ({ target }: ChangeEvent) => (
    fieldName: keyof Pick<OfferStateData, 'keyLoss' | 'initialPriceBased' | 'unpaidDebtInsurance'>,
    variantName: VariantsNames,
  ) => {
    const { updateVariantField, sendVariantField } = props;
    const payload = {
      fieldName,
      value: (target as HTMLInputElement).checked,
      variantName,
    };

    updateVariantField(payload);
    sendVariantField(payload);
  };

  const assignPersonsToOffer = (
    fieldName: keyof Pick<OfferStateData, 'insuredRelatives' | 'insuredOfficials'>,
    variantName: VariantsNames,
    personIds: string[],
  ) => {
    const { updateVariantField, sendVariantField } = props;
    const payload = {
      fieldName,
      value: personIds,
      variantName,
    };

    updateVariantField(payload);
    sendVariantField(payload);
  };

  const updateSelectedVariant = ({ target: { checked, value: variantName } }: ChangeEvent<any>): void => {
    const { updateFieldSelectedVariant, sendFieldSelectedVariant } = props;
    updateFieldSelectedVariant({
      variantName,
      value: checked,
    });

    if (variantName === VariantsNames.PHV_SMART) {
      trackGeneralClick(GeneralClickedElement.RADIO_SMART);
    } else if (variantName === VariantsNames.PHV_BEST) {
      trackGeneralClick(GeneralClickedElement.RADIO_BEST);
    }

    setCanGoToNextStep(true);

    sendFieldSelectedVariant({
      variantName,
      value: checked,
    });
  };

  const moveToMainPage = () => {
    history.push({
      pathname: RoutePath.ROOT,
    });
  };

  const getInsuredPersonBirthdate = () => (insuredPersonBirthdate ? moment().diff(
    insuredPersonBirthdate as string,
    'years',
  ) : null);

  const clickOpenPopup = async () => {
    trackGeneralClick(GeneralClickedElement.LINK_LEISTUNGENANZEIGEN);
    setShowProductTablePopup(true);

    try {
      const { data }: AxiosResponse<string> = await axios.get(productTableUrl, { headers: noCacheHeaders });
      setProductTableHtml(loadExternalJs(data));
    } catch (e) {
      Logger.error('Unable to load Experience Fragment');
      Logger.error(e);
      setProductTableHtml('<div><strong>Fehler aufgetreten</strong></div>');
    }
  };

  return (
    <>
      <div className="contribution__container">
        <div className="contribution__page-title mobile-only">Wählen Sie Ihren Versicherungsschutz</div>

        <div className="contribution__insurance-summary-description">
          <p>
            Sie sind
            {' '}
            <strong>
              {getInsuredPersonBirthdate()}
              {' '}
              Jahre
            </strong>
            {' '}
            alt und möchten
            {' '}
            <strong
              className="contribution__container-strong"
            >
              {MARITAL_STATUS_DESCRIPTION[maritalStatus]}
            </strong>
            {' '}
            ab dem
            {' '}
            <strong className="contribution__container-strong">
              {moment(insuranceStartDate, PhvDateFormat.ISO_DATE).format(PhvDateFormat.UI_DATE)}
            </strong>
            {' '}
            absichern.
          </p>

          <Button
            data-testid="contribution-change-offer-button"
            variant="tertiary"
            size="auto"
            onClick={moveToMainPage}
          >
            Angaben ändern
          </Button>
        </div>

        <div className="contribution__page-title desktop-only">Wählen Sie Ihren Versicherungsschutz:</div>

        <div className="contribution__inputs">
          <div className="contribution__inputs-header">
            <div className="contribution__variant-select">
              <div className="contribution__variant">
                <Radio
                  data-testid={`contribution-variant-${VariantsNames.PHV_SMART}-radio`}
                  value={VariantsNames.PHV_SMART}
                  defaultChecked={(selectedVariantName === VariantsNames.PHV_SMART)}
                  name="selectedVariant"
                  label={(
                    <div className="contribution__header-info">
                      <strong className={classNames({
                        'contribution__selected-variant-strong': selectedVariantName === VariantsNames.PHV_SMART,
                      })}
                      >
                        {VariantsNames.PHV_SMART}
                      </strong>
                    </div>
                  )}
                  onChange={updateSelectedVariant}
                />
                <div className="contribution__selected-variant-description desktop-only">
                  {`${variants[VariantsNames.PHV_SMART]?.insuranceSum / 1000000} Mio. € Versicherungssumme`}
                </div>
              </div>

              <div className="contribution__variant">
                <Radio
                  data-testid={`contribution-variant-${VariantsNames.PHV_BEST}-radio`}
                  value={VariantsNames.PHV_BEST}
                  defaultChecked={(selectedVariantName === VariantsNames.PHV_BEST)}
                  name="selectedVariant"
                  label={(
                    <div className="contribution__header-info">
                      <strong
                        className={classNames({
                          'contribution__selected-variant-strong': selectedVariantName === VariantsNames.PHV_BEST,
                        })}
                      >
                        {VariantsNames.PHV_BEST}
                      </strong>

                    </div>
                  )}
                  onChange={updateSelectedVariant}
                />
                <div className="contribution__selected-variant-description desktop-only">
                  {`${variants[VariantsNames.PHV_BEST]?.insuranceSum / 1000000} Mio. € Versicherungssumme`}
                </div>
              </div>
            </div>

            {selectedVariantName && (
              <div className="mobile-only">
                {variants[selectedVariantName]?.insuranceSum / 1000000}
                {' '}
                Mio. € Versicherungssumme
              </div>
            )}
            <div className="contribution__alle">
              <button type="button" onClick={clickOpenPopup} className="contribution__no-style-button">
                <div className="contribution__red-link">Alle Leistungen anzeigen</div>
              </button>
            </div>
          </div>

          <div
            className="contribution__inputs-misc"
            data-testid="contribution__variant-container"
          >
            <p className="contribution__variant-title">Wählbare Leistungsbausteine</p>
            {keyLoss && keyLoss[selectedVariantName]
            && (
              <TooltipWrapper
                tooltip={ContributionTooltips.KEYLOSS}
                onOpen={() => trackGeneralClick(GeneralClickedElement.INFOICON_SCHLUESSELVERLUST)}
              >
                <div className="contribution__variant-option">
                  <Checkbox
                    data-testid="contribution-keyLoss-checkbox"
                    label="Schlüsselverlust"
                    checked={keyLoss[selectedVariantName].value}
                    onChange={(event: ChangeEvent) => onChangeVariantField(event)('keyLoss', selectedVariantName)}
                  />
                </div>
              </TooltipWrapper>
            )}
            {initialPriceBased && initialPriceBased[selectedVariantName]
            && (
              <TooltipWrapper
                tooltip={ContributionTooltips.INTIAL_PRICE_BASED}
                onOpen={() => trackGeneralClick(GeneralClickedElement.INFOICON_NEUWERTENTSCHAEDIGUNG)}
              >
                <div className="contribution__variant-option">
                  <Checkbox
                    data-testid="contribution-initialPriceBased-checkbox"
                    label="Neuwertentschädigung"
                    checked={initialPriceBased[selectedVariantName].value}
                    onChange={(event: ChangeEvent) => onChangeVariantField(event)(
                      'initialPriceBased',
                      selectedVariantName,
                    )}
                  />
                </div>
              </TooltipWrapper>
            )}
            {unpaidDebtInsurance && unpaidDebtInsurance[selectedVariantName]
            && (
              <TooltipWrapper
                tooltip={ContributionTooltips.UNPAID_DEBT}
                onOpen={() => trackGeneralClick(GeneralClickedElement.INFOICON_FORDERUNGSAUSFALL)}
              >
                <div className="contribution__variant-option">
                  <Checkbox
                    data-testid="contribution-unpaidDebtInsurance-checkbox"
                    label="Forderungsausfall"
                    checked={unpaidDebtInsurance[selectedVariantName].value}
                    onChange={(event: ChangeEvent) => onChangeVariantField(event)(
                      'unpaidDebtInsurance',
                      selectedVariantName,
                    )}
                  />
                </div>
              </TooltipWrapper>
            )}

            <TooltipWrapper
              tooltip={ContributionTooltips.INSURED_OFFICIALS}
              onOpen={() => trackGeneralClick(GeneralClickedElement.INFOICON_AMTSDIENSTHAFTPFLICHT)}
            >
              <InsuredOfficialsComponent
                maritalStatus={maritalStatus}
                insuranceOwner={insuranceOwner}
                insurancePartner={insurancePartner}
                insuredOfficialsIds={insuredOfficialsIds}
                selectedVariantName={selectedVariantName}
                assignPersonsToOffer={assignPersonsToOffer}
              />
            </TooltipWrapper>

            <TooltipWrapper
              tooltip={ContributionTooltips.INSURED_RELATIVES}
              onOpen={() => trackGeneralClick(GeneralClickedElement.INFOICON_ALLEINSTEHENDEANGEHOERIGE)}
            >
              <div className="contribution__variant-option">
                <Checkbox
                  data-testid="contribution-insuredRelatives-checkbox"
                  label="Alleinstehende Familienangehörige"
                  checked={hasInsuredRelatives}
                  onChange={(event: ChangeEvent) => {
                    const { checked } = event.target as HTMLInputElement;
                    setHasInsuredRelatives(checked);

                    if (!checked && insuredRelativesIds.length) {
                      assignPersonsToOffer('insuredRelatives', selectedVariantName, []);
                    }
                  }}
                />

                {hasInsuredRelatives && (
                  <div className="contribution__radio-options">
                    <Radio
                      data-testid="contribution-insuredRelatives-1-radio"
                      name="insuredRelatives"
                      value="1"
                      checked={insuredRelativesIds.length === 1}
                      label="Eine Person"
                      onChange={() => assignPersonsToOffer(
                        'insuredRelatives',
                        selectedVariantName,
                        insuredPersonsIds.slice(0, 1),
                      )}
                    />
                    <br />

                    <Radio
                      data-testid="contribution-insuredRelatives-2-radio"
                      name="insuredRelatives"
                      value="2"
                      checked={insuredRelativesIds.length === 2}
                      label="2 Personen"
                      onChange={() => assignPersonsToOffer(
                        'insuredRelatives',
                        selectedVariantName,
                        insuredPersonsIds.slice(0, 2),
                      )}
                    />
                    <br />

                    <Radio
                      data-testid="contribution-insuredRelatives-3-radio"
                      name="insuredRelatives"
                      value="3"
                      checked={insuredRelativesIds.length === 3}
                      label="3 Personen"
                      onChange={() => assignPersonsToOffer(
                        'insuredRelatives',
                        selectedVariantName,
                        insuredPersonsIds.slice(0, 3),
                      )}
                    />
                  </div>
                )}
              </div>
            </TooltipWrapper>
          </div>
        </div>

        <AgreementOptions />

        <div>
          <a
            data-testid="contribution-pdf-button"
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackGeneralClick(GeneralClickedElement.LEISTUNGSUBERSICHT_PDF)}
          >
            <div className="contribution__pdf-icon"><DownloadIcon style={{ fill: themeBrandColor }} /></div>
            <div className="contribution__pdf-title">Leistungsübersicht als PDF herunterladen</div>
          </a>
        </div>
      </div>

      <Modal
        open={showProductTablePopup}
        onDismiss={() => setShowProductTablePopup(false)}
        dismissible
        backdropDismissesModal
      >
        {!productTableHtml && <LoaderIcon />}
        <div dangerouslySetInnerHTML={{ __html: productTableHtml }} />
      </Modal>
    </>
  );
};

export const ContributionComponentWithNavigation = withNavigation(ContributionComponent);
