import { ChannelType } from '../../components/app/app.interface';
import { RootState } from '../../store/store.interface';
import { AppState, PreviousNavigationAction } from './app.interface';

export const getApp = (state: RootState): AppState => state.entities.app;

export const getAppError = (state: RootState): AppState['error'] => getApp(state).error;

export const isAppInitialized = (state: RootState): boolean => getApp(state).initialized;

export const isNewOfferCreated = (state: RootState): boolean => getApp(state).newOffer;

export const isAemIntegrated = (state: RootState): boolean => getApp(state).inAem;

export const isMaklerFlow = (state: RootState): boolean => getApp(state).channelType === ChannelType.MAKLER;

export const getPreviousNavigationAction = (state: RootState): PreviousNavigationAction | null => getApp(state).previousNavigationAction;
