import { ChangeEvent } from 'react';
import { OfferStateData, VariantsNames } from '../../entities/offer/offer.interface';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';

export type AgreementOptionsProps = {
  selectedVariantName: VariantsNames;
  ownContribution: OfferStateData['ownContribution'];
  durationOfContract: OfferStateData['durationOfContract'];
  paymentMethod: OfferStateData['paymentMethod'];
  onChangeField: (event: ChangeEvent) => (
    fieldName: keyof Pick<OfferStateData, 'ownContribution' | 'durationOfContract' | 'paymentMethod'>,
  ) => void;
  onChangeVariantField: (event: ChangeEvent) => (
    fieldName: keyof Pick<OfferStateData, 'ownContribution' | 'durationOfContract' | 'paymentMethod'>,
    variantName: VariantsNames
  ) => void;
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => void;
};

export const OWN_CONTRIBUTION_OPTIONS_MAP: OwnContributionOptionsMap = {
  SB_150: '150 € Selbstbeteiligung',
  SB_150_OHNE_SELBSTBETEILIGUNG: 'ohne Selbstbeteiligung',
};

export type OwnContributionOptionsMap = {
  [key: string]: string;
};
