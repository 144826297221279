import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { appActions } from '../../entities/app/app.actions';
import { getApp, isAppInitialized, isNewOfferCreated } from '../../entities/app/app.selectors';
import { getBusinessId } from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { AppInitComponent } from './app-init.component';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { aemPropertiesActions } from '../../entities/aem/aem-properties.actions';
import { AemAppConfig } from '../../aem/aem.types';

const mapStateToProps = (state: RootState) => ({
  isAppInitialized: isAppInitialized(state),
  isNewOffer: isNewOfferCreated(state),
  businessId: getBusinessId(state),
  channelType: getApp(state).channelType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initApp: () => dispatch(appActions.init()),
  loadFromBusinessId: (businessId: string) => dispatch(appActions.loadFromBusinessId(businessId)),
  trackingInitialization: () => dispatch(trackingActions.initialize()),
  loadAemProperties: (aemAppConfig: AemAppConfig) => dispatch(aemPropertiesActions.load(aemAppConfig)),
});

export const AppInit = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppInitComponent));
