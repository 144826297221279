export interface Person {
  firstName: string;
  lastName: string;
  personId: string;
  salutation: PersonSalutation;
  birthDate: string;
  roles: PersonRole[];
  address: PersonAddress;
  phoneAreaPrefix?: string;
  phoneNumber?: string;
}

export interface PersonAddress {
  streetName: string;
  buildingNumber: string;
  postCode: string;
  city: string;
  country: string;
}

export enum PersonRole {
  CONTRIBUTION_PAYER = 'CONTRIBUTION_PAYER',
  INSURANCE_OWNER = 'INSURANCE_OWNER',
  INSURED_PERSON = 'INSURED_PERSON',
}

export enum PersonSalutation {
  MISTRESS = 'Frau',
  MISTER = 'Herr',
  UNKNOWN = '',
}

export type PersonsState = Readonly<{
  loading: boolean;
  errors: PersonsStateErrors[];
  data: PersonsStateData;
}>;

export type PersonsStateData = Readonly<Person[]>;

export type PersonsStateErrors = Readonly<{
  errorMessage: string
}>;

export enum PersonValidationRules {
  BIRTHDATE_AVAILABLE = 'GEBURTSDATUM_VORHANDEN',
  BIRTHPLACE_AVAILABLE = 'GEBURTSORT_VORHANDEN',
  NAME_COMPLETE = 'NAME_VOLLSTAENDIG',
  NATIONALITY_EXISTING = 'STAATSANGEHOERIGKEIT_VORHANDEN',
  NATIONALITY_CORRECT = 'STAATSANGEHOERIGKEIT_KORREKT',
  ADDRESS_AVAILABLE = 'ANREDE_VORHANDEN',
  ADDRESS_AUTOCORRECT = 'ADRESSE_DEUTSCH_UND_KEIN_POSTFACH_MIT_AUTOKORREKTUR',
  TELEPHONE_NUMBER_AVAILABLE = 'TELEFONNUMMER_VORHANDEN',
}

export interface PersonValidationContext {
  insuranceStartDate: string;
  insuranceEndDate?: string;
  personAge?: number;
}
