import AnalysisSingleParentIcon from '@eg/elements/Icons/AnalysisSingleParentIcon';
import FamilyIcon from '@eg/elements/Icons/FamilyIcon';
import ManIcon from '@eg/elements/Icons/ManIcon';
import ParentsIcon from '@eg/elements/Icons/ParentsIcon';
import Radio from '@eg/elements/Radio';
import RadioTile from '@eg/elements/RadioTile';
import RadioTileGroup from '@eg/elements/RadioTileGroup';
import { breakpointL } from '@eg/elements/styles/breakpoints';
import { IconProps } from '@eg/elements/SvgIcon';
import classNames from 'classnames';
import React from 'react';
import Media from 'react-media';
import TooltipIcon, { onOpen } from '../tooltip-icon/tooltip-icon';
import { RoutePath } from '../app/app.interface';
import './marital-status.component.scss';
import {
  isMaritalStatus, MaritalStatusProps, MaritalStatusState, PhvMaritalStatus,
} from './marital-status.interface';
import { withNavigation } from '../navigation-buttons/navigation-buttons.hoc.container';
import { MARITAL_STATUS_TOOLTIP } from './marital-status.tooltip';
import { GeneralClickedElement } from '../../tracking/tracking-general-clicks.types';

const maritalStatuses: {
  value: string,
  label: string,
  icon: React.FunctionComponent<IconProps>,
}[] = [
  {
    value: PhvMaritalStatus.SINGLE,
    label: 'Single',
    icon: ManIcon,
  },
  {
    value: PhvMaritalStatus.SELF_PARENTING,
    label: 'Alleinerziehend',
    icon: AnalysisSingleParentIcon,
  },
  {
    value: PhvMaritalStatus.PAIR,
    label: 'Paar',
    icon: ParentsIcon,
  },
  {
    value: PhvMaritalStatus.FAMILY,
    label: 'Familie',
    icon: FamilyIcon,
  },
];

export class MaritalStatusComponent extends React.Component<MaritalStatusProps, MaritalStatusState> {
  constructor(props: MaritalStatusProps) {
    super(props);

    this.state = {
      isSubmitted: false,
    };

    const {
      selectedMaritalStatus, setPreviousStep, setNextStep, setBackLinkLabel, setNextStepCallback, productPageUrl,
    } = this.props;
    this.setCanContinue(selectedMaritalStatus.value);

    setBackLinkLabel('zurück zur Produktseite');
    setPreviousStep(productPageUrl, true);
    setNextStep(RoutePath.INSURANCE_START);

    setNextStepCallback(() => {
      this.setState({
        isSubmitted: true,
      }, this.saveMaritalStatus);
    });
  }

  componentDidMount() {
    const { trackPageLoaded } = this.props;
    trackPageLoaded();
  }

  componentDidUpdate(): void {
    const { isLoading, goToNextStep, selectedMaritalStatus: { saved } } = this.props;
    const { isSubmitted } = this.state;

    if (!isLoading && isSubmitted && saved) {
      goToNextStep(RoutePath.INSURANCE_START);
    }
  }

  private saveMaritalStatus = () => {
    const { goToNextStep, sendMaritalStatus, selectedMaritalStatus: { value, saved } } = this.props;

    if (!saved) {
      sendMaritalStatus(value);
    } else {
      goToNextStep(RoutePath.INSURANCE_START);
    }
  };

  setMaritalStatus = (newStatus: string) => {
    const { setStatus } = this.props;

    if (isMaritalStatus(newStatus)) {
      setStatus(newStatus);
      this.setCanContinue(newStatus);
    }
  };

  setCanContinue = (currentMaritalStatus: string) => {
    const { setCanGoToNextStep } = this.props;
    setCanGoToNextStep(!!currentMaritalStatus);
  };

  render() {
    const { selectedMaritalStatus, trackGeneralClick } = this.props;

    return (
      <div className="marital-status__container">
        <div className="marital-status__page-title">
          Wählen Sie Ihre Lebenssituation aus:
          <TooltipIcon
            onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.INFOICON_LEBENSSITUATION))}
          >{MARITAL_STATUS_TOOLTIP}
          </TooltipIcon>
        </div>

        <Media query={`(max-width: ${breakpointL})`}>
          {(matches) => (matches ? (
            <div className="marital-status__select-container-mobile">
              {maritalStatuses.map(({ value, label }) => (
                <div
                  className={
                    classNames({
                      'esc_border-radius': true,
                      'marital-status__single-radio-row': true,
                      selected: (selectedMaritalStatus.value === value),
                    })
                  }
                  key={value}
                >
                  <Radio
                    className="marital-status__option-radio"
                    value={value}
                    data-testid={`marital-status-select-mobile-${value}`}
                    name="marital-status-select-mobile"
                    defaultChecked={selectedMaritalStatus.value === value}
                    onChange={(e) => this.setMaritalStatus(e.target.value)}
                    label={label}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="marital-status__select-container">
              <RadioTileGroup
                data-testid="marital-status-select"
                name="marital-status-select"
                defaultValue={selectedMaritalStatus.value}
                onChange={(value) => this.setMaritalStatus(value)}
                label=""
              >
                {maritalStatuses.map((status) => (
                  <RadioTile
                    value={status.value}
                    key={status.value}
                    data-testid={`marital-status-ratio-tile-${status.value}`}
                  >
                    <div className="marital-status__icon-container">
                      <status.icon width="100%" height="100%" />
                    </div>
                    <div className="marital-status__icon-description">{status.label}</div>
                  </RadioTile>
                ))}
              </RadioTileGroup>
            </div>
          ))}
        </Media>

      </div>
    );
  }
}

export const MaritalStatusComponentWithNavigation = withNavigation(MaritalStatusComponent);
