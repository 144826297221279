export interface AppProps {
  isAppInitialized: boolean;
  stopAppWithError: string | null;
  inAem: boolean;
  isMaklerFlow: boolean;
}

export enum RoutePath {
  BIRTHDATE = '/birthdate',
  INSURANCE_START = '/insurance-start',
  CONTRIBUTION = '/contribution',
  CONSULTATION = '/consultation',
  INIT = '/init',
  MARITAL_STATUS = '/marital-status',
  PERSONAL_DATA = '/personal-data',
  ROOT = '/',
  CHECKOUT = '/checkout',
  TARIFF_DATA = '/tariff-data',
  THANK_YOU = '/thank-you',
  PAYMENT_DATA = '/payment-data',
}

export enum ChannelType {
  MAKLER = 'makler',
  BROKER = 'broker',
}
