import { AnyAction } from 'redux';
import { AppActionTypes } from '../app/app.actions';
import { PaymentDataActionTypes } from './payment-data.actions';
import { PaymentDataState } from './payment-data.interface';

const initialState: PaymentDataState = {
  loading: false,
  error: false,

  data: {
    accountId: '',
    id: '',
    iban: '',
    bic: '',
  },
};

const paymentData = (
  state: PaymentDataState,
  delta: Partial<PaymentDataState['data']>,
) => ({
  ...state.data,
  ...delta,
});

/* eslint-disable @typescript-eslint/default-param-last*/
export const paymentDataReducer = (
  state: PaymentDataState = initialState,
  action: AnyAction,
): PaymentDataState => {
  switch (action.type) {
    case AppActionTypes.INIT_WITH_BUSINESS_ID.success:
    case AppActionTypes.INIT.success:
      return {
        ...state,
        data: paymentData(state, action.payload.paymentData),
      };

    case PaymentDataActionTypes.UPDATE_PAYMENT_DATA_FIELD:
      return {
        ...state,
        data: paymentData(state, action.payload),
      };

    case PaymentDataActionTypes.UPDATE_PAYMENT_DATA.start:
      return {
        ...state,
        loading: true,
        data: paymentData(state, action.payload),
      };

    case PaymentDataActionTypes.UPDATE_PAYMENT_DATA.success:
      return {
        ...state,
        loading: false,
        error: false,
        data: paymentData(state, action.payload),
      };

    case PaymentDataActionTypes.UPDATE_PAYMENT_DATA.error:
      return {
        ...state,
        loading: false,
        error: true,
        data: paymentData(state, action.payload),
      };

    default:
      return state;
  }
};
