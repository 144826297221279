import moment from 'moment';
import { Logger } from '../../commons/logger';
import { MAX_PROFIT_AGE, PhvDateFormat } from '../../config';
import { RootState } from '../../store/store.interface';
import { Person } from '../persons/persons.interface';
import { getInsuranceOwner, getInsurancePartner, getPersonsByIds } from '../persons/persons.selectors';
import {
  InsuredOfficialsOptions,
  OfferState,
  OfferStateData,
  OfferSummary,
  VariantNameValuesList,
  VariantsNames,
} from './offer.interface';

export const getOffer = (state: RootState): OfferState => state.entities.offer;

export const getOfferData = (state: RootState): OfferStateData => getOffer(state).data;

export const getOfferSummary = (state: RootState): OfferSummary => getOffer(state).offerSummary;

export const getSelectedMaritalStatus = (state: RootState): OfferStateData['selectedMaritalStatus'] => getOfferData(
  state,
).selectedMaritalStatus;

export const getBirthdayDate = (state: RootState): OfferStateData['birthdayDate'] => getOfferData(state).birthdayDate;

export const getInsuranceStartDate = (state: RootState): OfferStateData['insuranceStartDate'] => getOfferData(state).insuranceStartDate;

export const getAgencyId = (state: RootState): OfferStateData['agencyId'] => getOfferData(state).agencyId;

export const getFee = (state: RootState): OfferStateData['fee'] => getOfferData(state).fee;

export const getBusinessId = (state: RootState): OfferState['businessId'] => getOffer(state).businessId;

export const getSelectedVariants = (state: RootState): OfferStateData['selectedVariant'] => getOfferData(state).selectedVariant;

export const getSelectedVariantName = (state: RootState): VariantsNames | undefined => {
  const variants = getSelectedVariants(state);

  return VariantNameValuesList.find((variantName) => variants[variantName].value);
};

export const getCurrentPriceBrutto = (state: RootState): number | null => {
  const fee = getFee(state);

  const selectedVariantName = getSelectedVariantName(state);
  let currentPriceBrutto = null;

  if (selectedVariantName) {
    currentPriceBrutto = fee?.[selectedVariantName]?.brutto ?? null;
  }

  return currentPriceBrutto;
};

export const getOwnContribution = (state: RootState): OfferStateData['ownContribution'] => getOfferData(state).ownContribution;

export const getKeyLoss = (state: RootState) => getOfferData(state).keyLoss;

export const getInitialPriceBased = (state: RootState) => getOfferData(state).initialPriceBased;

export const getUnpaidDebtInsurance = (state: RootState) => getOfferData(state).unpaidDebtInsurance;

export const getInsuredRelatives = (state: RootState) => getOfferData(state).insuredRelatives;

export const getInsuredRelativesForSelectedVariant = (state: RootState): Person['personId'][] => {
  const selectedVariantName = getSelectedVariantName(state);
  if (selectedVariantName) {
    return (getOfferData(state).insuredRelatives[selectedVariantName].value) as Person['personId'][];
  }

  return [];
};

export const getInsuredRelativesPersons = (state: RootState): Person[] => getPersonsByIds(
  state,
  getInsuredRelativesForSelectedVariant(state),
);

export const getInsuredRelativesCount = (state: RootState): number => getInsuredRelativesPersons(state).length;

export const getInsuredOfficials = (state: RootState) => getOfferData(state).insuredOfficials;

export const getPaymentMethod = (state: RootState): OfferStateData['paymentMethod'] => getOfferData(state).paymentMethod;

export const getDurationOfContract = (state: RootState): OfferStateData['durationOfContract'] => getOfferData(state).durationOfContract;

export const isLoading = (state: RootState): boolean => getOffer(state).loading;

export const getConsultancyProtocolId = (state: RootState): OfferStateData['consultancyProtocolId'] => getOfferData(
  state,
).consultancyProtocolId;

// TODO: selectedVariant is unselectable - so it should not be checked everytime
//   (and getSelectedVariantName should not return undefined) details:
//   https://git.local.ergodirekt.de/projects/TO/repos/ed_unfall_telephony_2020_v1/pull-requests/153/overview?commentId=29858

export const isKeyLossOptionSelected = (state: RootState): boolean => {
  const selectedVariantName = getSelectedVariantName(state);
  if (!selectedVariantName) {
    return false;
  }
  return !!getOfferData(state).keyLoss[selectedVariantName].value;
};

export const isInitialPriceBasedOptionSelected = (state: RootState): boolean => {
  const selectedVariantName = getSelectedVariantName(state);
  if (!selectedVariantName) {
    return false;
  }
  return !!getOfferData(state).initialPriceBased[selectedVariantName].value;
};

export const isInsuredOffcialsOptionSelected = (state: RootState): boolean => {
  const selectedVariantName = getSelectedVariantName(state);
  if (!selectedVariantName) {
    return false;
  }
  return (getOfferData(state).insuredOfficials[selectedVariantName].value as string[]).length > 0;
};

export const isUnpaidDebtOptionSelected = (state: RootState): boolean => {
  const selectedVariantName = getSelectedVariantName(state);
  if (!selectedVariantName) {
    return false;
  }
  return !!getOfferData(state).unpaidDebtInsurance[selectedVariantName].value;
};

export const isStartBonusIncluded = (state: RootState) => {
  try {
    const insuranceBeginDate = moment(getInsuranceStartDate(state)?.value as string, PhvDateFormat.ISO_DATE);
    const inputValueDate = moment(getBirthdayDate(state)?.value as string, PhvDateFormat.ISO_DATE);

    return insuranceBeginDate.diff(inputValueDate, 'years') <= MAX_PROFIT_AGE;
  } catch (error) {
    Logger.debug(error);
  }
  return false;
};

export const getActualGrossFee = (state: RootState): number | undefined => {
  const selectedVariantName = getSelectedVariantName(state);
  if (selectedVariantName) {
    return getOfferData(state).fee?.[selectedVariantName]?.brutto;
  }
  return undefined;
};

export const isInsuredRelativesOptionSelected = (state: RootState): boolean => {
  const selectedVariantName = getSelectedVariantName(state);
  if (!selectedVariantName) {
    return false;
  }
  return (getOfferData(state).insuredRelatives[selectedVariantName].value as string[]).length > 0;
};

export const getInsuredOfficialsSelectedOption = (state: RootState): InsuredOfficialsOptions | undefined => {
  const selectedVariantName = getSelectedVariantName(state);
  const insuredOffcialsOptionSelected = isInsuredOffcialsOptionSelected(state);
  if (!selectedVariantName || !insuredOffcialsOptionSelected) {
    return undefined;
  }

  const insuranceOwner = getInsuranceOwner(state);
  const insurancePartner = getInsurancePartner(state);

  const hasInsurancePartnerId = (getOfferData(state).insuredOfficials[selectedVariantName].value as string[])
    .includes(insurancePartner.personId);

  const hasInsuranceOwnerId = (getOfferData(state).insuredOfficials[selectedVariantName].value as string[])
    .includes(insuranceOwner.personId);

  if (hasInsurancePartnerId && hasInsuranceOwnerId) {
    return InsuredOfficialsOptions.ME_AND_MY_PARTNER;
  }

  if (hasInsuranceOwnerId) {
    return InsuredOfficialsOptions.ME;
  }

  return undefined;
};

const isInsurancePartnerAnInsuredOfficial = (state: RootState): boolean => {
  const insuredOfficialsOptionSelected = getInsuredOfficialsSelectedOption(state);
  if (!insuredOfficialsOptionSelected) {
    return false;
  }

  switch (insuredOfficialsOptionSelected) {
    case InsuredOfficialsOptions.ME:
      return false;
    case InsuredOfficialsOptions.ME_AND_MY_PARTNER:
      return true;
    default:
      return false;
  }
};

export const getInsurancePartnerPersonIfIsOfficialsOptionSelected = (state: RootState): Person | undefined => {
  if (isInsurancePartnerAnInsuredOfficial(state)) {
    return getInsurancePartner(state);
  }
  return undefined;
};
