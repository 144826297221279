import moment from 'moment';
import 'moment/locale/de';

moment.locale('en');
export const deMoment = () => moment().locale('de');

export enum PhvDateFormat {
  UI_DATE = 'DD.MM.YYYY',
  UI_DAY_NAME_WITH_DATE = 'dddd, DD.MM.YYYY',
  ISO_DATE = 'YYYY-MM-DD',
}

export const PHV_ERGO_ELEMENTS_THEME = 'ergo-one';

export const MAX_PROFIT_AGE = 35;

export const APP_ERROR_MESSAGE =
  'Die Seite ist momentan nicht verfügbar, bitte drücken Sie F5 und probieren noch einmal';

// @ts-ignore
export const backendUrl = ENV_BACKEND_URL || '';
// @ts-ignore
export const trackingSysEnv = ENV_TRACKING_SYSENV || '';
// @ts-ignore
export const targetEnv = ENV_TARGET_ENV || '';
// @ts-ignore
export const personalDataBundleUrl = ENV_PERSONAL_DATA_BUNDLE_URL || '';
// @ts-ignore
export const personalDataBackendUrl = ENV_PERSONAL_DATA_BACKEND_URL || '';

// format of this build timestamp: 2020-09-21_13:41:22_CEST
export const buildDate = process.env.REACT_APP_BUILD_TIMESTAMP;

export const isProductionBuild = targetEnv === 'production';
