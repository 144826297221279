import { RoutePath } from '../app/app.interface';
import { OfferStateData } from '../../entities/offer/offer.interface';

export interface InsuranceStartDateProps {
  isLoading: boolean;
  insuranceStartDate: OfferStateData['insuranceStartDate'];
  insuranceStartOptions: InsuranceStartDateOption[];

  trackPageLoaded: () => void;
  setInsuranceStartDate: (insuranceStart: string) => void;
  sendInsuranceStartDate: (insuranceStart: string) => void;
  setCanGoToNextStep: (yesNo: boolean) => void;
  setPreviousStep: (uri: RoutePath, isExternal?: boolean) => void;
  setNextStep: (uri: RoutePath) => void;
  goToNextStep: (uri: RoutePath) => void;
  setNextStepCallback: (goToNextStepCallback: () => void) => void;
}

export interface InsuranceStartDateOption {
  label?: string;
  shortDate: string;
  longDate?: string;
  value: string;
}

export enum InsuranceStartDateOptionLabel {
  TOMORROW = 'Morgen',
  NEXT_MONTH = 'Nächsten Monat',
  OTHER_DATE = 'Anderes Datum',
}
