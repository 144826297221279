import React, { FunctionComponent } from 'react';

import { Person } from '../../../entities/persons/persons.interface';
import { LabelValue } from '../label-value/label-value.component';

interface PersonDetailsProps {
  label: string;
  person: Person;
}

export const PersonDetails: FunctionComponent<PersonDetailsProps> = ({ label, person }: PersonDetailsProps) => (
  <div className="esc_grid checkout__person-section">
    <LabelValue label={label} />
    <div className="esc_grid__wrapper checkout__person-section_details">
      <div className="esc_col esc_col-12">
        <LabelValue label="Anrede">
          {person.salutation}
        </LabelValue>
      </div>
      <div className="esc_col esc_col-12 esc_col-m-6">
        <LabelValue label="Vorname">
          {person.firstName}
        </LabelValue>
      </div>
      <div className="esc_col esc_col-12 esc_col-m-6">
        <LabelValue label="Name">
          {person.lastName}
        </LabelValue>
      </div>
    </div>
  </div>
);
