import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions } from '../../entities/offer/offer.actions';
import { ConsultancyProtocolId } from '../../entities/offer/offer.interface';
import { getBusinessId, getConsultancyProtocolId, getOfferSummary } from '../../entities/offer/offer.selectors';
import { RootState } from '../../store/store.interface';
import { ConsultationComponentWithNavigation } from './consultation.component';
import { backendUrl } from '../../config';
import { trackingActions } from '../../entities/tracking/tracking.actions';
import { AdobeAnalyticsPages } from '../../tracking/tracking-types';
import { GeneralClickElementType } from '../../tracking/tracking-general-clicks.types';

const mapStateToProps = (state: RootState) => ({
  consultancyProtocolId: getConsultancyProtocolId(state),
  downloadDocumentLink: `${backendUrl}/api/phv/document/${getBusinessId(state)}`,
  summaryErrors: getOfferSummary(state).errors,
  summaryReceived: getOfferSummary(state).received,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validateOffer: () => dispatch(offerActions.validateOffer()),
  submitOffer: () => dispatch(offerActions.submitOffer()),
  sendConsultancyProtocolId: (value: ConsultancyProtocolId) => {
    dispatch(offerActions.sendField({
      fieldName: 'consultancyProtocolId',
      value,
    }));
  },
  trackPageLoaded: () => dispatch(trackingActions.trackPageLoaded(AdobeAnalyticsPages.CONSULTATION)),
  trackGeneralClick: (generalClickElementType: GeneralClickElementType) => dispatch(trackingActions.trackGeneralClick(generalClickElementType)),
});

export const Consultation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsultationComponentWithNavigation);
