import React from 'react';
import Details from '@eg/elements/Details';
import { InsuredRelativesProps } from './insured-relatives.interface';
import { PersonDetails } from '../person-details/person-details.component';
import { onOpen } from '../../tooltip-icon/tooltip-icon';
import { GeneralClickedElement } from '../../../tracking/tracking-general-clicks.types';

const InsuredRelativesComponent = ({ insuredPersons, trackGeneralClick }: InsuredRelativesProps) => {
  const renderInsuredPersons = () => insuredPersons.map((person, index) => {
    const headerLabel = `Angaben zur ${index + 1}. Person`;
    return (
      <PersonDetails
        label={headerLabel}
        key={person.personId}
        person={person}
      />
    );
  });

  return (
    <Details
      summary="Alleinstehende Familiengehörige"
      summaryClassName="checkout__section-header"
      onToggledOpen={onOpen(() => trackGeneralClick(GeneralClickedElement.ALLEINSTEHENDEFAMILIENANGEHOERIGE))}
    >
      { renderInsuredPersons() }
    </Details>
  );
};

export default InsuredRelativesComponent;
