import LoadingSpinner from '@eg/elements/LoadingSpinner';
import React, { FC, useEffect } from 'react';
import { InitComponentProps } from './app-init.interface';
import { RoutePath } from '../app/app.interface';
import { AdobeAnalyticsAPI } from '../../tracking/tracking-types';
import { AemWindow } from '../../aem/aem.types';
import { Logger } from '../../commons/logger';

declare global {
  interface Window {
    appEventData: AdobeAnalyticsAPI;
  }
}

export const AppInitComponent: FC<InitComponentProps> = ({
  businessId, history, initApp, loadFromBusinessId,
  isAppInitialized, isNewOffer, trackingInitialization, loadAemProperties,
}: InitComponentProps) => {
  const loadAemProps = () => {
    const aemWindow = (window as unknown) as AemWindow;

    if (aemWindow.phvAemAssets) {
      loadAemProperties(aemWindow.phvAemAssets);
    } else {
      Logger.log('❗️NO AEM PROPERTIES❗️');

      const tmpProps = {
        PDF_URL: 'ergo.de',
        Produktseite_URL: 'ergo.de',
        alleLeistungenContent: '/content/experience-fragments/ergo/produkttabelle/haftpflicht/master.html',
        datenschutzBeiErgoContent: '/content/experience-fragments/ergo/Dominik-Test/dominik-test-xf/master.html',
        trackingKuerzel: 'phv',
        trackingNameAbschlussFeedback: 'Abschluss Feedback',
        trackingNameBasisdaten: 'Basisdaten',
        trackingNameBasisdatenSituation: 'BasisdatenSituation',
        trackingNameBasisdatenVersBeginn: 'BasisdatenVersBeginn',
        trackingNameBerechnungsergebnis: 'Berechnungsergebnis',
        trackingNamePersonendaten: 'Personendaten',
        trackingNamePersonendatenBankdaten: 'PersonendatenBankdaten',
        trackingNamePruefenUndSenden: 'PruefenUndSenden',
        trackingNamePruefenUndSendenIDD: 'PruefenUndSendenIDD',
        trackingProduktgruppe: 'Haftpflicht',
        wilkommenBeiErgoContent: '/content/experience-fragments/ergo/kleingedrucktes/amazon_stern/amazon-lv-kv-reactjs.html',
      };

      loadAemProperties(tmpProps);
    }
  };

  useEffect(() => {
    if (isNewOffer) {
      history.push({
        pathname: RoutePath.MARITAL_STATUS,
      });
    }
  }, [isNewOffer]);

  useEffect(() => {
    if (businessId) {
      loadFromBusinessId(businessId);
    } else {
      initApp();
    }
  }, []);

  useEffect(() => {
    loadAemProps();
    trackingInitialization();
  }, []);

  return (
    <>
      <LoadingSpinner show={!isAppInitialized} />
    </>
  );
};
